import { API_HOST_THEMES_MARKET, API_HOST_V3 } from '../settings';

// const API_HOST = process.env.REACT_APP_API_HOST || window.ENV_CONFIG.hosts.api;

export async function getMarketMenuThemes(token, requestState) {
  const tokenMemo = await token;
  // search params included in url path for offset, nameFilter, idFilter, aspectRatioFilter

  const MENUS_ENDPOINT = `${API_HOST_THEMES_MARKET}/${requestState.selectedMarket[0]}?limit=50&offset=${requestState.offset}&id=${requestState.filters.id}&name=${requestState.filters.name}&aspectRatio=${requestState.filters.aspectRatio}`;
  try {
    const response = await fetch(MENUS_ENDPOINT, {
      headers: {
        Authorization: `Bearer ${tokenMemo}`,
        'X-CLIENT-TYPE': 'Bespoke',
        'X-APP-LOCALE': 'en-GB',
      },
    });

    const res = await response.json();

    const newThemes =
      requestState.offset === 0
        ? res.items
        : [...requestState.themes, ...res.items];

    const responseObj = {
      ...requestState,
      isLoading: false,
      themes: newThemes,
      totalNumberOfThemes: res.total,
    };

    return responseObj;
  } catch (e) {
    console.error(e);
  }
}

// export async function getMarketSubcategories(token, marketId) {
//   if (marketId) {
//     try {
//       const tokenMemo = await token;

//       const MENUS_ENDPOINT = `${API_HOST_MARKETS_V3}/${marketId}/theme-categories`;

//       const response = await fetch(MENUS_ENDPOINT, {
//         headers: {
//           Authorization: `Bearer ${tokenMemo}`,
//           'X-CLIENT-TYPE': 'Bespoke',
//           'X-APP-LOCALE': 'en-GB',
//         },
//       });

//       return await response.json();
//     } catch (e) {
//       console.error(e);
//     }
//   }
// }

export async function updateMenuTheme(token, data) {
  try {
    const tokenMemo = await token;
    const json = JSON.stringify(data);

    // /api/v3/themes
    const THEME_ENDPOINT = `${API_HOST_V3}/themes`;

    const response = await fetch(THEME_ENDPOINT, {
      method: 'PUT',
      body: json,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokenMemo}`,
      },
    });

    return response;
  } catch (e) {
    console.error(e);
  }
}

// const mockTheme = {
//   limit: 50,
//   offset: 0,
//   total: 3,
//   items: [
//     {
//       id: 9814,
//       name: 'Block - Plain Black',
//       imageUrl:
//         'https://blobcdn.mrmbrand-apis.net/bespokemenus-mrmsales-com/themes/c16e1083-1b6c-4d43-94b4-e0a836b2342b.jpg',
//       categoryIds: [7],
//       categories: [
//         // {
//         //   "id": 7,
//         //   "name": "Colours",
//         //   "categoryName": "Style"
//         // }
//       ],
//       aspectRatio: 100,
//       frontCoverLowResUrl:
//         'https://blobcdn.mrmbrand-apis.net/bespokemenus-mrmsales-com/themes/7aa35f89-c37e-40db-8c29-0ccdc3d231f4.jpg',
//       frontCoverHighResUrl:
//         'https://blobcdn.mrmbrand-apis.net/bespokemenus-mrmsales-com/theme/85341088-30c0-4434-8c75-72bb8a43d6f3.pdf',
//       backCoverLowResUrl:
//         'https://blobcdn.mrmbrand-apis.net/bespokemenus-mrmsales-com/theme/de0255d3-f8c6-47b0-bd0f-1f95a3034a39.jpg',
//       backCoverHighResUrl:
//         'https://blobcdn.mrmbrand-apis.net/bespokemenus-mrmsales-com/theme/0a660c23-bf02-49ef-9bff-c939666cc1bc.pdf',
//       primaryInsideLowResUrl:
//         'https://blobcdn.mrmbrand-apis.net/bespokemenus-mrmsales-com/theme/dce4483b-56bd-48c5-a6e1-ec78c3383e3a.jpg',
//       primaryInsideHighResUrl:
//         'https://blobcdn.mrmbrand-apis.net/bespokemenus-mrmsales-com/theme/690fcf42-ce24-4395-b350-8b4d073f7e08.pdf',
//       pwaStyles: {
//         heading: null,
//         subHeading: null,
//         bodyText: null,
//         legalText: null,
//         product: {
//           title: {
//             align: 'left',
//             color: '#212121',
//             fontFamily: 'Bitter',
//             fontStyle: 'normal',
//             fontWeight: 'bold',
//             lineHeight: 1.2,
//             textDecoration: 'normal',
//             fontSize: 12,
//           },
//           description: {
//             align: 'left',
//             color: '#212121',
//             fontFamily: 'Avenir',
//             fontStyle: 'normal',
//             fontWeight: 'normal',
//             lineHeight: 1.2,
//             textDecoration: 'normal',
//             fontSize: 10,
//           },
//           price: {
//             align: 'right',
//             color: '#212121',
//             fontFamily: 'Bitter',
//             fontStyle: 'normal',
//             fontWeight: 'bold',
//             lineHeight: 1.2,
//             textDecoration: 'normal',
//             fontSize: 12,
//           },
//         },
//       },
//     },
//   ],
// };
