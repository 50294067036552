// import { marketSubcategoriesEndpoint } from './endpoints';
import { IFetchState } from '../types/api';
import { IMarketSubcategory } from '../types/marketSubcategory';

import { useState, useEffect } from 'react';
import { API_HOST_MARKETS_V3 } from '../settings';
// import useAuthorisedApi from './useAuthorisedApi';


export const useGetMarketSubcategories = (
  token: string,
  marketId?: string | null
): IFetchState<IMarketSubcategory[]> => {
  const [data, setData] = useState<IMarketSubcategory[] | null>(null);
  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  

  const restructureSubcategoryObjects = allMarketSubcategoriesJson => {
    return allMarketSubcategoriesJson.flatMap(categoryObj =>
      categoryObj.subcategories.map(subCatObj => ({
        id: subCatObj.id,
        name: subCatObj.slug,
        categoryName: categoryObj.slug,
      }))
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      if (marketId) {
        setIsLoading(true);
        try {
          const tokenMemo = await token;

          const MENUS_ENDPOINT = `${API_HOST_MARKETS_V3}/${marketId}/theme-categories`;

          const response = await fetch(MENUS_ENDPOINT, {
            headers: {
              Authorization: `Bearer ${tokenMemo}`,
              'X-CLIENT-TYPE': 'Bespoke',
              'X-APP-LOCALE': 'en-GB',
            },
          });

          const responseData = await response.json();
          const convertedData = restructureSubcategoryObjects(responseData);
          setData(convertedData);
          setError(null);
        } catch (e) {
          setError(e);
        } finally {
          setIsLoading(false);
        }
      } else {
        console.error('Error fetching sub categories. Market ID invalid. MarketID:', marketId)
      }
    };

    fetchData();
  }, [token, marketId]);

  return { data, error, isLoading };
};


// export const useGetMarketSubcategories = (
//   token: string,
//   marketId: string | null
// ): IFetchState<IMarketSubcategory[]> => {

  
//   const restructureSubcategoryObjects = allMarketSubcategoriesJson => {
//     return allMarketSubcategoriesJson.flatMap(categoryObj =>
//       categoryObj.subcategories.map(subCatObj => ({
//         id: subCatObj.id,
//         name: subCatObj.slug,
//         categoryName: categoryObj.slug,
//       }))
//       );
//     };
    
//     const MENUS_ENDPOINT = `${API_HOST_MARKETS_V3}/${marketId}/theme-categories`;
//     const request = new Request(MENUS_ENDPOINT);

//     useEffect(() => {
//       if (marketId) {
//       execute();
//     }
//   }, [token, marketId]);

//   const { data: rawData, isLoading, error, execute } = useAuthorisedApi<IMarketSubcategory[]>(
//     request,
//     false,
//   );

//   const data: IMarketSubcategory[] = restructureSubcategoryObjects(rawData)

//   return { data, isLoading, error };
// };
