export function UploadFile() {
  return (
    <svg
      width="120"
      height="84"
      viewBox="0 0 120 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64.0703 63.7024L32.0363 65.2569C28.7436 65.4132 25.9457 62.7236 25.7835 59.2444L23.8453 18.1773C23.6831 14.6981 26.2134 11.7454 29.506 11.5809L82.7393 9.00648C86.0319 8.85021 88.8298 11.5398 88.992 15.0189L90.0301 36.9631L73.9806 25.662L57.55 45.1798L46.6665 37.1276L32.9446 50.8221"
        stroke="#21292D"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.9515 55.6136L85.4977 48.9039L92.6319 56.5841"
        stroke="#82E7F2"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.7381 67.4272L85.4541 48.817"
        stroke="#82E7F2"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.145 30.6305C42.6582 30.6305 44.6957 28.5663 44.6957 26.0199C44.6957 23.4735 42.6582 21.4092 40.145 21.4092C37.6317 21.4092 35.5942 23.4735 35.5942 26.0199C35.5942 28.5663 37.6317 30.6305 40.145 30.6305Z"
        stroke="#82E7F2"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.491 18.2096C114.491 18.4458 114.309 18.5875 114.076 18.6938C112.974 19.1898 111.872 19.6976 110.77 20.1818C110.562 20.2763 110.433 20.3944 110.329 20.5834C109.784 21.5873 109.227 22.5911 108.683 23.595C108.566 23.8076 108.436 23.9847 108.151 23.9847C107.853 23.9847 107.736 23.7958 107.619 23.595C107.062 22.5793 106.504 21.5518 105.934 20.548C105.856 20.4181 105.727 20.2881 105.584 20.2291C104.508 19.7213 103.419 19.237 102.343 18.741C102.096 18.6229 101.837 18.5284 101.824 18.2214C101.824 17.9143 102.084 17.808 102.33 17.6899C103.419 17.1939 104.508 16.6979 105.597 16.19C105.727 16.131 105.869 16.0129 105.934 15.883C106.504 14.8673 107.062 13.8516 107.619 12.8359C107.736 12.6234 107.879 12.458 108.164 12.458C108.449 12.458 108.592 12.6234 108.708 12.8359C109.253 13.8398 109.797 14.8437 110.368 15.8357C110.446 15.9774 110.601 16.1192 110.757 16.2018C111.859 16.7215 112.974 17.2175 114.076 17.7253C114.296 17.8198 114.491 17.9379 114.504 18.2096H114.491Z"
        stroke="#82E7F2"
        strokeWidth="1.75"
        strokeMiterlimit="10"
      />
      <path
        d="M9.60934 20.0887C9.03428 20.0887 8.58594 20.5606 8.58594 21.1211V22.7533C8.58594 23.3334 9.05378 23.7857 9.60934 23.7857C10.1649 23.7857 10.6327 23.3137 10.6327 22.7533V21.1211C10.6327 20.541 10.1649 20.0887 9.60934 20.0887Z"
        fill="#21292D"
      />
      <path
        d="M9.60934 18.2204C10.1844 18.2204 10.6327 17.7485 10.6327 17.188V15.5558C10.6327 14.9757 10.1649 14.5234 9.60934 14.5234C9.05378 14.5234 8.58594 14.9954 8.58594 15.5558V17.188C8.58594 17.7681 9.05378 18.2204 9.60934 18.2204Z"
        fill="#21292D"
      />
      <path
        d="M13.198 18.0445H11.5703C10.9952 18.0445 10.5469 18.5164 10.5469 19.0769C10.5469 19.6373 11.0147 20.1093 11.5703 20.1093H13.198C13.773 20.1093 14.2214 19.6373 14.2214 19.0769C14.2214 18.5164 13.7535 18.0445 13.198 18.0445Z"
        fill="#21292D"
      />
      <path
        d="M7.6511 18.0445H6.0234C5.44835 18.0445 5 18.5164 5 19.0769C5 19.6373 5.46784 20.1093 6.0234 20.1093H7.6511C8.22616 20.1093 8.6745 19.6373 8.6745 19.0769C8.6745 18.5164 8.20666 18.0445 7.6511 18.0445Z"
        fill="#21292D"
      />
      <path
        d="M109.502 43.6474C109.021 43.6474 108.646 44.0422 108.646 44.5111V45.8765C108.646 46.3618 109.037 46.7401 109.502 46.7401C109.967 46.7401 110.358 46.3453 110.358 45.8765V44.5111C110.358 44.0258 109.967 43.6474 109.502 43.6474Z"
        fill="#21292D"
      />
      <path
        d="M109.502 42.0844C109.983 42.0844 110.358 41.6896 110.358 41.2208V39.8554C110.358 39.3701 109.967 38.9917 109.502 38.9917C109.037 38.9917 108.646 39.3865 108.646 39.8554V41.2208C108.646 41.7061 109.037 42.0844 109.502 42.0844Z"
        fill="#21292D"
      />
      <path
        d="M112.495 41.9364H111.133C110.652 41.9364 110.277 42.3312 110.277 42.8001C110.277 43.2689 110.669 43.6637 111.133 43.6637H112.495C112.976 43.6637 113.351 43.2689 113.351 42.8001C113.351 42.3312 112.96 41.9364 112.495 41.9364Z"
        fill="#21292D"
      />
      <path
        d="M107.855 41.9364H106.493C106.012 41.9364 105.637 42.3312 105.637 42.8001C105.637 43.2689 106.029 43.6637 106.493 43.6637H107.855C108.336 43.6637 108.711 43.2689 108.711 42.8001C108.711 42.3312 108.32 41.9364 107.855 41.9364Z"
        fill="#21292D"
      />
      <path
        d="M13.8649 45.6557C13.3839 45.6557 13.0088 46.0505 13.0088 46.5194V47.8848C13.0088 48.3701 13.4002 48.7484 13.8649 48.7484C14.3297 48.7484 14.721 48.3536 14.721 47.8848V46.5194C14.721 46.0341 14.3297 45.6557 13.8649 45.6557Z"
        fill="#21292D"
      />
      <path
        d="M13.8649 44.0927C14.346 44.0927 14.721 43.6979 14.721 43.2291V41.8637C14.721 41.3784 14.3297 41 13.8649 41C13.4002 41 13.0088 41.3948 13.0088 41.8637V43.2291C13.0088 43.7144 13.4002 44.0927 13.8649 44.0927Z"
        fill="#21292D"
      />
      <path
        d="M16.8579 43.9447H15.4963C15.0152 43.9447 14.6401 44.3395 14.6401 44.8084C14.6401 45.2772 15.0315 45.672 15.4963 45.672H16.8579C17.339 45.672 17.714 45.2772 17.714 44.8084C17.714 44.3395 17.3227 43.9447 16.8579 43.9447Z"
        fill="#21292D"
      />
      <path
        d="M12.2178 43.9447H10.8561C10.3751 43.9447 10 44.3395 10 44.8084C10 45.2772 10.3914 45.672 10.8561 45.672H12.2178C12.6989 45.672 13.0739 45.2772 13.0739 44.8084C13.0739 44.3395 12.6825 43.9447 12.2178 43.9447Z"
        fill="#21292D"
      />
      <path
        d="M83.0687 73.4586C91.4795 74.31 99.0067 67.9977 99.8812 59.3596C100.756 50.7215 94.6463 43.0286 86.2355 42.1772C77.8247 41.3257 70.2975 47.6381 69.423 56.2762C68.5485 64.9143 74.6579 72.6071 83.0687 73.4586Z"
        stroke="#21292D"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
