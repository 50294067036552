import { Center, Spinner } from '@chakra-ui/react';

const Loader = ({ size = 'xl', dataTestId, pt = '10', pb = '10' }) => {
  return (
    <Center h={'50vh'} w={'100%'}>
      <Spinner
        thickness={size === 'xl' ? '4px' : '2px'}
        speed="0.65s"
        emptyColor="colors.darkestTealGrey"
        color="colors.medTeal"
        size={size}
        data-testid={dataTestId ? dataTestId : `loading-spinner`}
      />
    </Center>
  );
};

export default Loader;
