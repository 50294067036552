import { Th, Tr, Box, VStack } from '@chakra-ui/react';
import React from 'react';
import HeadingTextFilter from './HeadingTextFilter';

const headings = [
    { apiKey: 'edit', readableName: 'Edit', sortable: false, searchable: false },
    { apiKey: 'id', readableName: 'ID', sortable: false, searchable: true },
    {
        apiKey: 'frontCoverLowResUrl',
        readableName: 'Image',
        sortable: false,
        searchable: false,
    },
    { apiKey: 'name', readableName: 'Name', sortable: false, searchable: true },
    {
        apiKey: 'aspectRatio',
        readableName: 'Aspect Ratio',
        sortable: false,
        searchable: true,
    },
    {
        apiKey: 'subcategories',
        readableName: 'Subcategories',
        sortable: false,
        searchable: false,
    },
    {
        apiKey: 'templateType',
        readableName: 'Template Type',
        sortable: true,
        searchable: false,
    },
    {
        apiKey: 'formats',
        readableName: 'Formats',
        sortable: false,
        searchable: false,
    },
    {
      apiKey: 'enabled',
      readableName: 'Enabled',
      sortable: true,
      searchable: false,
  },
  {
    apiKey: 'brand',
    readableName: 'Brand',
    sortable: false,
    searchable: false,
  },
  {
    apiKey: 'market',
    readableName: 'Market',
    sortable: false,
    searchable: false,
  },
];

const HeadingsRow = ({ requestState, setRequestState }) => {
  return (
    <>
      <Tr index="0" bgColor="white">
        {headings.map(heading => (
          <Th
            fontSize="l"
            key={heading.apiKey}
            textTransform="none"
            cursor={heading.sortable ? 'pointer' : 'default'}
            borderColor="white"
            minW={
              (heading.apiKey === 'aspectRatio' && '10rem') ||
              (heading.apiKey === 'id' && '8rem') ||
              (heading.apiKey === 'name' && '14rem') ||
              (heading.apiKey === 'subcategories' && '14rem')
            }
          >
            <VStack spacing={1} align="stretch" mb="10px">
              <Box my="8px">{heading.readableName}</Box>
              <Box h="20px">
                {heading.searchable && (
                  <HeadingTextFilter
                    heading={heading}
                    requestState={requestState}
                    setRequestState={setRequestState}
                  />
                )}
              </Box>
            </VStack>
          </Th>
        ))}
      </Tr>
    </>
  );
};

export default HeadingsRow;
