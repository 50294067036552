import React from 'react';
import { Button, Box, Flex } from '@chakra-ui/react';
import { PrimaryButton } from '../../../_librabry/buttons/PrimaryButton';
import {
  usePayloadDispatch,
  usePayloadState,
} from '../../../AppPayloadContext';
import ACTIONS from '../../../actions/ReducerActions';

const EditMultipleThemesButton = ({
  canEditMultiple,
  setCanEditMultiple,
  setMultipleSelected,
  handleOpenModal,
}) => {
  const dispatch = usePayloadDispatch();

  const themesToEditLength = usePayloadState().themesToEdit.length;
  return (
    <Box ml={3}>
      {canEditMultiple ? (
        <Flex>
          <PrimaryButton
            text={`Edit Themes (${themesToEditLength})`}
            dataTestId="editMultipleButton"
            mr={6}
            onClick={handleOpenModal}
            isDisabled={canEditMultiple && themesToEditLength === 0}
          />

          <Button
            variant="ghost"
            onClick={() => {
              setCanEditMultiple(false);
              dispatch({ type: ACTIONS.CLEAR_THEMES_TO_EDIT });
              setMultipleSelected(false);
            }}
            colorScheme="red"
            data-testid="editMultipleCancel"
            mr={2}
          >
            Cancel
          </Button>
        </Flex>
      ) : (
        <PrimaryButton
          dataTestId="editThemeButton"
          text={'Edit Multiple'}
          onClick={() => {
            setCanEditMultiple(true);
            setMultipleSelected(true)
          }}
          textColor={'white'}
        />
      )}
    </Box>
  );
};

export default EditMultipleThemesButton;
