import { addArtworkGroupsToSortingState } from './addArtworkGroupsToSortingState';

const artworkGroupTags = [];
// detects the artwork group tag from the file name using regex
// file names must be in this format [themeName]-Size [artworkGroup tag]-Page [pageNumber]
// once the artworkGroup tag is detected it add them to the array artworkGroupTags

export const detectArtworkGroupTagsFromUploads = (
  filesContent,
  plainFiles,
  setSortingState,
  mockMenuOptions
) => {
  const regexTheme = /Size_[aA-zZ: 0-9: ".]*/g;
  const fileNames = filesContent.map(file => file.name);

  fileNames.forEach(file => {
    let artworkGroupTag = '';
    const artworkGroupWithSize = file.match(regexTheme);
    const artworkGroupToString = artworkGroupWithSize.toString();
    artworkGroupTag = artworkGroupToString.split('_').pop();
    if (!artworkGroupTags.includes(artworkGroupTag)) {
      artworkGroupTags.push(artworkGroupTag);
    }
  });
  addArtworkGroupsToSortingState(
    filesContent,
    plainFiles,
    setSortingState,
    mockMenuOptions,
    artworkGroupTags
  );

  return artworkGroupTags;
};
