export const FONTS = [
  { name: 'AG Buch Condensed BQ', pwaDisabled: true },
  { name: 'Adorn Condensed Sans' },
  { name: 'Airstream', pwaDisabled: true },
  { name: 'Alegreya Sans SC', hasItalic: true, hasBold: true },
  { name: 'Alégre Sans NC' },
  { name: 'Avenir', hasItalic: true, hasBold: true },
  {
    name: 'Barlow Condensed',
    hasItalic: true,
    hasBold: true,
    pwaDisabled: true,
  },
  { name: 'Baskerville BT' },
  { name: 'Bebas Neue' },
  { name: 'Belleza' },
  { name: 'Bentham', pwaDisabled: true },
  { name: 'Bitter' },
  { name: 'Bodoni 72', hasItalic: true },
  { name: 'Bodoni 72 Oldstyle', hasItalic: true, pwaDisabled: true },
  { name: 'Bodoni 72 Smallcaps' },
  {
    name: 'Brandon Grotesque',
    exclusiveBrandOrMarket: [
      { brandId: '4', marketId: '6' },
      { brandId: '4', marketId: '121' },
    ],
  }, //Molson - UK, Ireland
  { name: 'ByMyHand' },
  { name: 'Cantata One' },
  { name: 'Cardenio Modern' },
  {
    name: 'Carlsberg Sans',
    exclusiveBrandOrMarket: [{ brandId: '24', marketId: '88' }],
  }, // Carlsberg Global - Canada
  { name: 'Clarendon BT', pwaDisabled: true },
  { name: 'Clarendon Lt BT' },
  { name: 'Copperplate' },
  { name: 'Cutive', pwaDisabled: true },
  {
    name: 'Cyclone',
    exclusiveBrandOrMarket: [{ brandId: '24', marketId: '88' }],
  }, // Carlsberg Global - Canada
  { name: 'EB Garamond', hasItalic: true, hasBold: true },
  { name: 'EcuyerDAX' },
  { name: 'Euphoria Script' },
  { name: 'Fira Sans Extra Condensed', hasItalic: true, hasBold: true },
  { name: 'Fjalla One' },
  {
    name: 'Fritz-Kola',
    exclusiveBrandOrMarket: [{ brandId: '47' }, { brandId: '8' }],
  },
  { name: 'Futura', hasItalic: true },
  {
    name: 'FuturaBT',
    exclusiveBrandOrMarket: [{ brandId: '24', marketId: '88' }],
  }, // Carlsberg Global - Canada
  { name: 'Gill Sans' },
  { name: 'Gill Sans MT', hasItalic: true, hasBold: true },
  { name: 'Gotham' },
  { name: 'Goudy Old Style', pwaDisabled: true },
  { name: 'Great Vibes', pwaDisabled: true },
  { name: 'Handserif' },
  { name: 'HansHand', pwaDisabled: true },
  { name: 'Helvetica', hasItalic: true, hasBold: true },
  { name: 'HelveticaNeue-Light' },
  { name: 'HelveticaNeue-Medium' },
  { name: 'Inria Serif', hasItalic: true, hasBold: true },
  { name: 'Italiana', pwaDisabled: true },
  {
    name: 'Jacobsen Grotesk Caps',
    exclusiveBrandOrMarket: [{ brandId: '24', marketId: '88' }],
  }, // Carlsberg Global - Canada
  { name: 'Jasper Daniels', exclusiveBrandOrMarket: [{ brandId: '35' }] }, // Brown-Forman
  { name: 'Josefin Sans', hasItalic: true },
  { name: 'Josefin Slab', hasItalic: true },
  { name: 'Jura', hasBold: true },
  { name: 'Kameron', hasBold: true },
  {
    name: 'Kronenbourg 1664',
    exclusiveBrandOrMarket: [{ brandId: '24', marketId: '88' }],
  }, // Carlsberg Global - Canada
  { name: 'Kumbh Sans' },
  { name: 'Kurale' },
  { name: 'Lexend', hasBold: true },
  { name: 'Literata', hasItalic: true, hasBold: true },
  { name: 'Lora', hasItalic: true },
  { name: 'Lubalin Graph Demi', pwaDisabled: true },
  { name: 'Luna', pwaDisabled: true },
  { name: 'Marcellus' },
  { name: 'Minion Pro' },
  { name: 'Motlow Caps', exclusiveBrandOrMarket: [{ brandId: '35' }] }, // Brown-Forman
  { name: 'Neucha' },
  { name: 'Nunito Sans', hasItalic: true, hasBold: true },
  {
    name: 'Ny Carlsberg Display',
    exclusiveBrandOrMarket: [{ brandId: '24', marketId: '88' }],
  }, // Carlsberg Global - Canada
  { name: 'OfficinaSansStd-Book', hasItalic: true, hasBold: true },
  { name: 'OfficinaSerifStd-Book', hasItalic: true, hasBold: true },
  { name: 'Open Sans', hasItalic: true, hasBold: true },
  { name: 'Optima', hasItalic: true, hasBold: true },
  { name: 'Oranienbaum' },
  { name: 'Oswald' },
  { name: 'Pattaya' },
  { name: 'Play', hasBold: true },
  { name: 'Playfair Display', hasItalic: true },
  { name: 'Quattrocento', hasBold: true },
  { name: 'QuickSand' },
  { name: 'Roboto', hasItalic: true, hasBold: true },
  { name: 'Roboto Slab', hasBold: true },
  { name: 'Rokkitt' },
  { name: 'Rubik Mono One' },
  { name: 'Sacramento', pwaDisabled: true },
  { name: 'Sanchez', hasItalic: true },
  { name: 'Satisfy', pwaDisabled: true },
  { name: 'Sheila', pwaDisabled: true },
  { name: 'SmithyXT-Faded' },
  {
    name: 'Somersby Header 2019',
    exclusiveBrandOrMarket: [{ brandId: '24', marketId: '88' }],
  }, // Carlsberg Global - Canada
  { name: 'Stag' },
  { name: 'Steelworks paint', pwaDisabled: true },
  { name: 'Tenor Sans' },
  { name: 'Times New Roman', hasItalic: true, hasBold: true },
  { name: 'Varela Round' },
  { name: 'Veneer' },
  { name: 'Work Sans', pwaDisabled: true, hasItalic: true },
  { name: 'Zilla Slab', hasItalic: true, hasBold: true },
];

export const exclusiveFonts = FONTS.filter(font => font.exclusiveBrandOrMarket);
