import { React, useState, useEffect, useMemo } from 'react';
import ACTIONS from '../actions/ReducerActions';
import {
  Select,
  Heading,
  Input,
  Flex,
  Text,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import { usePayloadDispatch, usePayloadState } from '../AppPayloadContext';
import { FormControl, FormHelperText } from '@chakra-ui/react';

import { productBrandsService } from '../services/InitialServices';
import { PrimaryButton } from '../_librabry/buttons/PrimaryButton';
import { ChevronDownIcon } from '@chakra-ui/icons';

const BrandMarketSelector = ({
  hideBrandMarketSelector,
  markets,
  uploadType,
  token,
  brands,
}) => {
  const [brandIdState, setBrandIdState] = useState([]);
  const payloadState = usePayloadState();
  const dispatch = usePayloadDispatch();
  const mediaMatch = window.matchMedia('(min-width: 600px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  const [brandError, setBrandError] = useState(false);
  const [marketValue, setMarketValue] = useState('Select Market');

  // -------------------------------------------

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  useEffect(() => {
    productBrandsService(token).then(res => setProductBrands(res));
  }, [token]);

  const [productBrands, setProductBrands] = useState([]);

  const disableNav = useMemo(() => {
    if (uploadType === 'pos') {
      const marginConditions =
        isNaN(payloadState.posPayload.marginTop) ||
        payloadState.posPayload.marginTop === null ||
        payloadState.posPayload.marginTop === 0;
      return (
        payloadState.posPayload.name === '' ||
        payloadState.posPayload.marketId === null ||
        payloadState.posPayload.tags === '' ||
        marginConditions
      );
    } else if (uploadType === 'menu') {
      const tagConditions =
        payloadState.menuPayload.tags[0] === undefined ||
        payloadState.menuPayload.tags[0].length === 0;

      return (
        payloadState.menuPayload.name === '' ||
        tagConditions ||
        payloadState.menuPayload.marketId === ''
      );
    }
  }, [payloadState, uploadType]);

  //  returns true/false when comparing a market object's brandId to the brand Id in state
  const filterMarketsByBrandId = marketObject => {
    return marketObject.brandId === brandIdState;
  };

  const filterTestDeletedBrands = brandObject => {
    if (
      !(
        brandObject.name === 'System Test' ||
        brandObject.name === 'System Tests' ||
        brandObject.deleted === 1
      )
    ) {
      return brandObject;
    }
  };

  const sortAlphabetically = (a, b) => {
    const marketA = a.name.toUpperCase();
    const marketB = b.name.toUpperCase();

    let comparison = 0;
    if (marketA > marketB) {
      comparison = 1;
    } else if (marketA < marketB) {
      comparison = -1;
    }
    return comparison;
  };

  // sorts all brands alphabetically, then filters out test brands
  const filteredBrands = useMemo(() => {
    return brands
      .sort(sortAlphabetically)
      .filter(filterTestDeletedBrands)
      .map((brand, index) => {
        if (brand.deleted !== true) {
          return (
            <option key={index} value={[brand.id, brand.name]}>
              {brand.name}
            </option>
          );
        }
        return null;
      });
  }, [brands]);

  // sorts all markets alphabetically, then filters based on their brandId values
  const filteredMarkets = useMemo(() => {
    return markets
      .sort(sortAlphabetically)
      .filter(filterMarketsByBrandId)
      .map((market, index) => {
        if (market.deleted !== true && market.id !== 77 && market.id !== 20) {
          return (
            <option key={index} value={[market.id, market.name]}>
              {market.name}
            </option>
          );
        }
        return null;
      });
  }, [markets, brandIdState]);

  const filteredProductBrands = useMemo(() => {
    return productBrands
      .sort(sortAlphabetically)
      .filter(filterTestDeletedBrands)
      .map((product, index) => {
        if (product.deleted !== true) {
          return (
            <option key={index} value={[product.id, product.name]}>
              {product.displayName}
            </option>
          );
        }
        return null;
      });
  }, [productBrands]);

  const styles = {
    container: isRowBased => ({
      display: 'flex',
      flexDirection: isRowBased ? 'row' : 'column',
      justifyContent: 'space-around',
    }),
    flexInContainer: isRowBased => ({
      display: 'flex',
      flexDirection: 'column',
      width: '20em',
      align: 'stretch',
      marginTop: isRowBased ? '0vh' : '1vh',
      marginLeft: isRowBased ? '1.15vh' : '0vh',
    }),
    editable: isRowBased => (isRowBased ? '15px' : '3.4vw'),
    SelectProductBrand: isRowBased => (isRowBased ? '3vh' : '1vh'),
  };

  const themeName =
    uploadType === 'pos'
      ? payloadState.posPayload.name
      : payloadState.menuPayload.name;

  const handleNameDispatch = e => {
    if (uploadType === 'menu') {
      dispatch({ type: ACTIONS.ADD_THEME_NAME, payload: e.target.value });
    } else {
      dispatch({ type: ACTIONS.ADD_POS_THEME_NAME, payload: e.target.value });
    }
  };

  const handleTagDispatch = e => {
    if (uploadType === 'menu') {
      dispatch({ type: ACTIONS.ADD_TAG, payload: e.target.value });
    } else {
      dispatch({ type: ACTIONS.ADD_POS_TAG, payload: e.target.value });
    }
  };

  const handleSelectedBrand = event => {
    setBrandIdState(Number(event.target.value.split(',')[0]));
    // setMarketValue('Select Market')
    if (uploadType === 'pos') {
      dispatch({
        type: ACTIONS.ADD_POS_BRAND_ID,
        payload: Number(event.target.value.split(',')[0]),
      });
      dispatch({
        type: ACTIONS.ADD_POS_BRAND_NAME,
        payload: event.target.value.split(',')[1],
      });
      dispatch({ type: ACTIONS.ADD_POS_MARKET_ID, payload: null });
      setMarketValue('Select Market');
    }
  };

  const handleProductBrandDispatch = event => {
    if (uploadType === 'menu') {
      dispatch({
        type: ACTIONS.ADD_PRODUCT_BRAND_ID,
        payload: Number(event.target.value.split(',')[0]),
      });
    } else {
      dispatch({
        type: ACTIONS.ADD_POS_PRODUCT_BRAND_ID,
        payload: Number(event.target.value.split(',')[0]),
      });
    }
  };

  const handleMarketIdDispatch = event => {
    if (uploadType === 'menu') {
      setMarketValue(event.target.value);
      dispatch({
        type: ACTIONS.ADD_MARKET_ID,
        payload: Number(event.target.value.split(',')[0]),
      });
    } else {
      setMarketValue(event.target.value.split(',')[1]);
      dispatch({
        type: ACTIONS.ADD_POS_MARKET_ID,
        payload: Number(event.target.value.split(',')[0]),
      });
      dispatch({
        type: ACTIONS.ADD_POS_MARKET_NAME,
        payload: event.target.value.split(',')[1],
      });
    }
  };

  const handleBrandWarning = () => {
    if (brandIdState.length <= 0) {
      setBrandError(true);
    } else {
      setBrandError(false);
    }
  };

  return (
    <Flex direction="column" color={'white'} align={'flex-start'}>
      {uploadType === 'pos' ? (
        <Text
          align="flex-start"
          mt="1.5vh"
          color="colors.darkestTealGrey"
          fontWeight="normal"
          fontSize="1em"
          mb="4vh"
        >
          Select Brand and Market, add tag and check theme name. <br /> For POS
          designs, choose a margin value.{' '}
        </Text>
      ) : (
        <Heading
          align="center"
          mt="1.5vh"
          color="colors.darkestTealGrey"
          fontWeight="normal"
          fontSize="1em"
          mb="4vh"
        >
          Select Brand and Market, add tag and check theme name{' '}
        </Heading>
      )}
      <Flex style={styles.container(matches)}>
        <Stack w="27em" direction="column" align="stretch">
          <Input
            bg={'white'}
            color={'black'}
            whiteSpace="nowrap"
            overflow="hidden"
            placeholder="Enter Theme Name..."
            defaultValue={themeName}
            py="0.15vw"
            onChange={e => handleNameDispatch(e)}
          ></Input>
          <Input
            bg={'white'}
            mt="1vh"
            color={'black'}
            placeholder="Enter Tag"
            onChange={e => {
              handleTagDispatch(e);
            }}
          />
          {uploadType === 'pos' ? (
            <Tooltip
              px="1vw"
              py="1vh"
              hasArrow
              label="margins must be Numbers ONLY"
              bg="colors.darkestTealGrey"
            >
              <Input
                bg={'white'}
                color={'black'}
                mt="1vh"
                placeholder="Enter POS Margin"
                onChange={e =>
                  dispatch({
                    type: ACTIONS.ADD_POS_MARGIN,
                    payload: Number(e.target.value),
                  })
                }
              />
            </Tooltip>
          ) : null}
          <FormControl isInvalid={brandError}>
            <Select
              bg={'white'}
              onChange={e => handleSelectedBrand(e)}
              placeholder="Select Brand"
              textColor={'black'}
              icon={<ChevronDownIcon />}
            >
              {filteredBrands}
            </Select>
            {brandError ? (
              <FormHelperText>Brand required first</FormHelperText>
            ) : null}
          </FormControl>

          <Select
            onClick={handleBrandWarning}
            onChange={e => handleMarketIdDispatch(e)}
            icon={<ChevronDownIcon />}
            mt="1vh"
            value={marketValue}
            variant={'outline'}
            placeholder={marketValue}
            textColor={'black'}
          >
            {filteredMarkets}
          </Select>

          <Select
            onChange={e => handleProductBrandDispatch(e)}
            icon={<ChevronDownIcon />}
            mt={styles.SelectProductBrand(matches)}
            placeholder="Select Product Brand (Optional)"
            size="md"
            variant={'outline'}
            textColor={'black'}
          >
            {filteredProductBrands}
          </Select>
        </Stack>
      </Flex>
      <Flex mt={4} alignSelf={'flex-end'}>
        <PrimaryButton
          text={'Create Theme'}
          onClick={() => {
            hideBrandMarketSelector();
          }}
          isDisabled={disableNav}
        />
      </Flex>
    </Flex>
  );
};

export default BrandMarketSelector;
