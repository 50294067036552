import { React, useState, useEffect } from 'react';
import BrandMarketSelector from '../components/BrandMarketSelector';
import FileUpload from '../components/FileUpload';
import PreviewsPOS from './PreviewsPOS';
import { useFilePicker } from 'use-file-picker';
import { calculateAspectRatio } from '../actions/aspectRatioCalculator';
import { posSizeService } from '../services/InitialServices';

const CreateNewPOSTheme = ({
  theme,
  setShowFileUpload,
  showFileUpload,
  handleUploadAlert,
  handleToggleOffAlert,
  token,
  markets,
  brands,
}) => {
  //* STATES =================================================
  const [posSizes, setPosSizes] = useState([]);
  const [showBrandMarketSelector, setShowBrandMarketSelector] = useState(false);
  const [showPreviews, setShowPreviews] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  // * INITIAL FETCHES, useCONTEXT, AUTH0 TOKEN ==========================

  useEffect(() => {
    posSizeService(token).then(res => setPosSizes(res));
  }, [token]);

  //* File Picker - holds state filesContent ===========================

  const [openFileSelector, { plainFiles, filesContent, loading }] =
    useFilePicker({
      readAs: 'DataURL',
      accept: ['image/*', '.pdf'],
      multiple: true,
      limitFilesConfig: { max: 100 },
      // minFileSize: 1,
      maxFileSize: 500, // in megabytes
    });

  //* POS product Size sorting =======================================

  // Digital POS
  const facebookEventBannerAndDigitalScreen = [];
  const facebookInStream = [];
  const facebookAndInstagramSquare = [];
  const facebookTimelineNewsFeed = [];
  const instagramStoryAndDigitalScreen = [];
  // European POS
  const sizesInsert120 = [];
  const sizesTableTalker = [];
  const sizesInsert99 = [];
  const ASizeTentCardAnd2PageMenu = [];
  const sizesASize4PageMenu = [];
  const sizesCurvedHanger = [];
  const sizesPoster = [];
  const sizesStandardHanger = [];
  const sizesLongDecal = [];
  const sizesCircleDecal = [];
  const sizes4PageTallSlim = [];
  const sizesPosTrifold = [];
  // NAM POS
  const sizesLongDecalNam = [];
  const sizesCircleDecalNam = [];
  const sizesPosterNam11x17 = [];
  const sizesPosterNam24x36 = [];
  const sizes5x11InsertPage = [];
  const sizes5x15InsertPage = [];
  const sizes4x25InsertPage = [];
  const sizes4x6InsertPageAndTentCard = [];
  const sizes5x7InsertPageAndTentCard = [];

  posSizes.forEach(size => {
    const calculatedRatio = calculateAspectRatio(size);
    const sizeProdId = size.productId;
    const notNAMDecal = /NAM/g.test(size.displayName) === false;
    if (calculatedRatio === 40) {
      if (sizeProdId === 7) {
        sizesStandardHanger.push(size);
      } else if (sizeProdId === 45 && size.displayName === 'pos.sizes.5,5x15') {
        sizes5x15InsertPage.push(size);
      } else if (
        sizeProdId === 45 &&
        size.displayName === 'pos.sizes.4,25x11'
      ) {
        sizes4x25InsertPage.push(size);
      }
    } else if (calculatedRatio === 50) {
      if (sizeProdId === 8) {
        sizesCurvedHanger.push(size);
      } else if (sizeProdId === 27) {
        sizesInsert99.push(size);
      } else if (sizeProdId === 2) {
        sizesTableTalker.push(size);
      } else if (sizeProdId === 45) {
        sizes5x11InsertPage.push(size);
      }
    } else if (calculatedRatio === 60) {
      if (sizeProdId === 27) {
        sizesInsert120.push(size);
      } else if (sizeProdId === 37 || sizeProdId === 38) {
        instagramStoryAndDigitalScreen.push(size);
      } else if (sizeProdId === 19) {
        sizesPosterNam11x17.push(size);
      }
    } else if (
      calculatedRatio === 70 &&
      size.displayName !== 'pos.sizes.4x6' &&
      size.displayName !== 'pos.sizes.5x7' &&
      size.displayName !== 'pos.sizes.nam_24x36' &&
      size.displayName !== 'pos.sizes.nam_12x18'
    ) {
      if (sizeProdId === 27 || sizeProdId === 5) {
        ASizeTentCardAnd2PageMenu.push(size);
      } else if (sizeProdId === 28) {
        sizes4PageTallSlim.push(size);
      } else if (sizeProdId === 37) {
        facebookInStream.push(size);
      } else if (sizeProdId === 19) {
        sizesPoster.push(size);
      }
    } else if (calculatedRatio === 70) {
      if (sizeProdId === 19) {
        sizesPosterNam24x36.push(size);
      } else if (
        (sizeProdId === 5 && size.displayName === 'pos.sizes.5x7') ||
        (sizeProdId === 45 && size.displayName === 'pos.sizes.5x7')
      ) {
        sizes5x7InsertPageAndTentCard.push(size);
      } else if (
        (sizeProdId === 5 && size.displayName === 'pos.sizes.4x6') ||
        (sizeProdId === 45 && size.displayName === 'pos.sizes.4x6')
      ) {
        sizes4x6InsertPageAndTentCard.push(size);
      }
    } else if (calculatedRatio === 100) {
      if (sizeProdId === 37 || sizeProdId === 38) {
        facebookAndInstagramSquare.push(size);
      } else if (sizeProdId === 58 && notNAMDecal) {
        sizesCircleDecal.push(size);
      } else if (sizeProdId === 58) {
        sizesCircleDecalNam.push(size);
      }
    } else if (calculatedRatio === 140) {
      if (sizeProdId === 28) {
        sizesASize4PageMenu.push(size);
      } else if (sizeProdId === 31) {
        sizesPosTrifold.push(size);
      }
    } else if (calculatedRatio === 180) {
      if (sizeProdId === 37 || sizeProdId === 44) {
        facebookEventBannerAndDigitalScreen.push(size);
      }
    } else if (calculatedRatio === 190) {
      if (sizeProdId === 37) {
        facebookTimelineNewsFeed.push(size);
      }
    } else if (calculatedRatio === 410) {
      if (sizeProdId === 58) {
        sizesLongDecalNam.push(size);
      }
    } else if (calculatedRatio === 420) {
      if (sizeProdId === 58) {
        sizesLongDecal.push(size);
      }
    }
  });

  //* POS file sorting ======================================

  const insert120 = [];
  const tableTalker = [];
  const insert99 = [];
  const ASize2p = [];
  const ASize4p = [];
  const curvedHanger = [];
  const Poster = [];
  const standardHanger = [];
  const tableDecal = [];
  const tallSlim4P = [];
  const POSTrifold = [];
  const decalLandscape = [];
  const decalRoundNam = [];
  const decalRound = [];
  const Poster11x17 = [];
  const Poster24x36 = [];
  const insert55x11 = [];
  const insert55x15 = [];
  const POS425x11 = [];
  const POS4x6 = [];
  const POS5x7 = [];
  const POSThumbnail = [];

  const PFinsert120 = [];
  const PFtableTalker = [];
  const PFinsert99 = [];
  const PFASize2p = [];
  const PFASize4p = [];
  const PFcurvedHanger = [];
  const PFPoster = [];
  const PFstandardHanger = [];
  const PFtableDecal = [];
  const PFtallSlim4P = [];
  const PFPOSTrifold = [];
  const PFdecalLandscape = [];
  const PFdecalRoundNam = [];
  const PFdecalRound = [];
  const PFPoster11x17 = [];
  const PFPoster24x36 = [];
  const PFinsert55x11 = [];
  const PFinsert55x15 = [];
  const PFPOS425x11 = [];
  const PFPOS4x6 = [];
  const PFPOS5x7 = [];
  const PFPOSThumbnail = [];

  const POSRegexArray = [
    /120 Insert/g,
    /Table Talker/g,
    /99 Insert/g,
    /A Size 2P/g,
    /A Size 4P/g,
    /Curved Hanger/g,
    /EU Poster/g,
    /Standard Hanger/g,
    /EU Table Decal Landscape/g,
    /Tall Slim 4P/g,
    /Trifold/g,
    /POS Decal Landscape/g,
    /POS Decal Round/g,
    /EU Table Decal Round/g,
    /11x17 Poster/g,
    /24x36 Poster/g,
    /5.5x11 Insert/g,
    /5.5x15 Insert/g,
    /POS 4.25x11/g,
    /POS 4x6/g,
    /POS 5x7/g,
    /Thumbnail/g,
  ];
  const POSObject = {
    pdfs: [
      insert120,
      tableTalker,
      insert99,
      ASize2p,
      ASize4p,
      curvedHanger,
      Poster,
      standardHanger,
      tableDecal,
      tallSlim4P,
      POSTrifold,
      decalLandscape,
      decalRoundNam,
      decalRound,
      Poster11x17,
      Poster24x36,
      insert55x11,
      insert55x15,
      POS425x11,
      POS4x6,
      POS5x7,
      POSThumbnail,
    ],
    plainFiles: [
      PFinsert120,
      PFtableTalker,
      PFinsert99,
      PFASize2p,
      PFASize4p,
      PFcurvedHanger,
      PFPoster,
      PFstandardHanger,
      PFtableDecal,
      PFtallSlim4P,
      PFPOSTrifold,
      PFdecalLandscape,
      PFdecalRoundNam,
      PFdecalRound,
      PFPoster11x17,
      PFPoster24x36,
      PFinsert55x11,
      PFinsert55x15,
      PFPOS425x11,
      PFPOS4x6,
      PFPOS5x7,
      PFPOSThumbnail,
    ],
  };

  const POSObjectForMapping = [
    {
      pdfs: [
        insert120,
        tableTalker,
        insert99,
        ASize2p,
        ASize4p,
        curvedHanger,
        Poster,
        standardHanger,
        tableDecal,
        tallSlim4P,
        POSTrifold,
        decalLandscape,
        decalRoundNam,
        decalRound,
        Poster11x17,
        Poster24x36,
        insert55x11,
        insert55x15,
        POS425x11,
        POS4x6,
        POS5x7,
      ],
      plainFiles: [
        PFinsert120,
        PFtableTalker,
        PFinsert99,
        PFASize2p,
        PFASize4p,
        PFcurvedHanger,
        PFPoster,
        PFstandardHanger,
        PFtableDecal,
        PFtallSlim4P,
        PFPOSTrifold,
        PFdecalLandscape,
        PFdecalRoundNam,
        PFdecalRound,
        PFPoster11x17,
        PFPoster24x36,
        PFinsert55x11,
        PFinsert55x15,
        PFPOS425x11,
        PFPOS4x6,
        PFPOS5x7,
      ],
      productGroupSizes: [
        sizesInsert120,
        sizesTableTalker,
        sizesInsert99,
        ASizeTentCardAnd2PageMenu,
        sizesASize4PageMenu,
        sizesCurvedHanger,
        sizesPoster,
        sizesStandardHanger,
        sizesLongDecal,
        sizes4PageTallSlim,
        sizesPosTrifold,
        sizesLongDecalNam,
        sizesCircleDecalNam,
        sizesCircleDecal,
        sizesPosterNam11x17,
        sizesPosterNam24x36,
        sizes5x11InsertPage,
        sizes5x15InsertPage,
        sizes4x25InsertPage,
        sizes4x6InsertPageAndTentCard,
        sizes5x7InsertPageAndTentCard,
      ],
      productGroupName: [
        '120 Insert',
        'Table Talker',
        '99 Insert',
        'A Size 2P & Tent Card',
        'A Size 4P',
        'Curved Hanger',
        'Poster EU',
        'Standard Hanger',
        'Table Decal EU',
        'Tall Slim 4P',
        'Trifold',
        'Decal Landscape NAM',
        'Decal Round NAM',
        'Decal Round EU',
        '11x17 Poster',
        '24x36 Poster',
        '5.5x11_Insert',
        '5.5x15_Insert',
        'POS 4.25x11',
        'POS 4x6 & Tent Card',
        'POS 5x7 & Tent Card',
      ],
    },
  ];

  const currentAspects = [];

  POSRegexArray.forEach(regex => {
    filesContent.forEach(file => {
      const fileName = file.name;
      while (regex.test(fileName) === true) {
        const i = POSRegexArray.indexOf(regex);
        const array = POSObject.pdfs[i];
        array.push(file);
        if (array.length <= 1) {
          if (!fileName.includes('Thumbnail' || 'thumbnail')) {
            currentAspects.push(file);
          }
        }
      }
    });
    plainFiles.forEach(file => {
      const fileName = file.name;

      while (regex.test(fileName) === true) {
        const i = POSRegexArray.indexOf(regex);
        const array = POSObject.plainFiles[i];
        array.push(file);
      }
    });
  });

  //* Sorts Menu Sizes for Menu themes =====================================================

  // TODO: Refactor alert message to be its own component

  const hideFileUpload = () => {
    setShowFileUpload(false);
    setShowBrandMarketSelector(true);
  };

  const hideBrandMarketSelector = () => {
    setShowBrandMarketSelector(false);
    setShowPreviews(true);
  };

  return (
    <>
      {showFileUpload ? (
        <FileUpload
          hideFileUpload={hideFileUpload}
          openFileSelector={openFileSelector}
          filesContent={filesContent}
          uploadType={'pos'}
          loading={loading}
        />
      ) : null}
      {showBrandMarketSelector ? (
        <BrandMarketSelector
          theme={theme}
          hideBrandMarketSelector={hideBrandMarketSelector}
          markets={markets}
          token={token}
          brands={brands}
          uploadType={'pos'}
        />
      ) : null}
      {showPreviews && (
        <PreviewsPOS
          POSThumbnail={POSThumbnail}
          currentAspects={currentAspects}
          POSObjectForMapping={POSObjectForMapping}
          handleUploadAlert={handleUploadAlert}
          handleToggleOffAlert={handleToggleOffAlert}
          markets={markets}
          uploadType={'pos'}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
        />
      )}
    </>
  );
};

export default CreateNewPOSTheme;
