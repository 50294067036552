import { Flex, Spinner } from '@chakra-ui/react';
import ImagePreview from './ImagePreview';

const ImagePreviewsContainer = ({ jpegArray, jpegsReady }) => {
  return (
    <Flex
      w={'100%'}
      direction={'row'}
      overflow={'scroll'}
      p={10}
      borderRadius="15px"
      boxShadow={'inset 0 0 10px rgba(0, 0, 0, 0.25)'}
      gap={4}
    >
      {jpegsReady ? (
        jpegArray.map((file, index) => {
          let fileName = file.name;
          let pageNumber = '';
          let size = '';
          const pageRegex = /Page [0-9]*/g;
          const sizeRegex = /Size [aA-zZ :0-9:".]*/g;
          size = fileName.match(sizeRegex);
          pageNumber = fileName.match(pageRegex);
          return (
            <ImagePreview
              src={file.content}
              key={index}
              fileName={file.name}
              pageNumber={pageNumber}
              size={size}
            />
          );
        })
      ) : (
        <Flex padding="10vh" alignContent="center" justifyContent="center">
          <Spinner size="xl" color="brand.100" />
        </Flex>
      )}
    </Flex>
  );
};

export default ImagePreviewsContainer;
