import React, { useState, useEffect, useRef } from 'react';
import { Flex } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { usePayloadDispatch, usePayloadState } from '../../AppPayloadContext';

import { convertPdfToImages } from '../../actions/PDFtoImageConvertor';
import ACTIONS from '../../actions/ReducerActions';
import { uploadThemeFile } from '../../services/fileBlobUploadService';
import { uploadTheme } from '../../services/uploadThemeService';
import { PrimaryButton } from '../../_librabry/buttons/PrimaryButton';
import ImagePreviewsContainer from './ImagePreviewsContainer';
import CheckboxesContainer from './CheckBoxesContainer';

const ArtworkGroupTab = ({
  pdfs,
  plainPdfs,
  ratio,
  title,
  aspectRatio,
  tabIndex,
  handleDisableTab,
  handleUploadAlert,
  artworkGroupTabsRemaining,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const payloadState = usePayloadState();
  const dispatch = usePayloadDispatch();

  const [sizeIds, setSizeIds] = useState([]);

  const [clickToSubmit, setClickToSubmit] = useState(false);
  const [saving, setSaving] = useState(true);

  const [jpegArray, setJpegArray] = useState([]);
  const [convertedJpegFiles, setConvertedJpegFiles] = useState(null);

  useEffect(() => {
    convertPdfToImages(pdfs).then(result => {
      setJpegArray(result);
      convertJpegsToFiles(result);
    });
  }, [pdfs.length]);

  useEffect(() => {
    if (saving === false) {
      handlePostPayload();
    }
  }, [saving]);

  // ========= convert JPEGS to File Objects =================================================

  let jpegFilesRef = useRef(0);

  const convertJpegsToFiles = array => {
    const arr = [];
    array.forEach(jpeg => {
      jpegToFile(jpeg.content, jpeg.name).then(file => arr.push(file));
      jpegFilesRef.current = jpegFilesRef.current + 1;
    });
    setConvertedJpegFiles(arr);
  };

  const jpegToFile = (content, fileName) => {
    return fetch(content)
      .then(res => res.arrayBuffer())
      .then(buffer => new File([buffer], fileName, { type: 'image/jpeg' }));
  };

  const handleSetSizeIds = arr => {
    setSizeIds(arr);
  };

  // uploads files to the blog then dispatches the response to the payload
  const uploadFile = async (file, page, type) => {
    const filename = file.name;
    if (filename.includes(page)) {
      const res = await uploadThemeFile(
        getAccessTokenSilently,
        file,
        payloadState.menuPayload.name
      );
      await dispatch({
        type: type,
        payload: res,
      });
    }
  };

  // matches the files with the correct slot i.e page 1 and Front cover
  const uploadThemeArtworkFiles = async () => {
    const uploadPromises = [];

    for (const jpeg of convertedJpegFiles) {
      uploadPromises.push(
        uploadFile(jpeg, 'Page 1', ACTIONS.ADD_FRONT_COVER_LOW_RES_REF)
      );
      uploadPromises.push(
        uploadFile(jpeg, 'Page 2', ACTIONS.ADD_CONTENT_PAGE_LOW_RES_REF)
      );
      uploadPromises.push(
        uploadFile(jpeg, 'Page 3', ACTIONS.ADD_BACK_COVER_LOW_RES_REF)
      );
    }

    for (const pdf of plainPdfs) {
      uploadPromises.push(
        uploadFile(pdf, 'Page 1', ACTIONS.ADD_FRONT_COVER_HIGH_RES_REF)
      );
      uploadPromises.push(
        uploadFile(pdf, 'Page 2', ACTIONS.ADD_CONTENT_PAGE_HIGH_RES_REF)
      );
      uploadPromises.push(
        uploadFile(pdf, 'Page 3', ACTIONS.ADD_BACK_COVER_HIGH_RES_REF)
      );
    }

    await Promise.all(uploadPromises);

    return true;
  };

  const handleUpdatePayload = async () => {
    // updates payload with aspect ratio and selected menu sizes
    if (sizeIds !== null) {
      dispatch({ type: ACTIONS.ADD_MENU_SIZE_IDS, payload: sizeIds });
      dispatch({
        type: ACTIONS.ADD_ASPECT_RATIO,
        payload: Number(aspectRatio),
      });
    }

    // once all the file names are successfully uploaded and dispatched the payload saving is set to false

    const filesReady = await uploadThemeArtworkFiles();

    if (filesReady) {
      setSaving(false);
    }
  };

  const handlePostPayload = async () => {
    await uploadTheme(
      getAccessTokenSilently,
      payloadState.menuPayload,
      handleUploadAlert,
      title
    )
      .then(res => {
        console.log('successful theme payload', res);
        return res; // Return the value of res
      })
      .then(res =>
        dispatch({
          type: ACTIONS.ADD_THEME_TO_EDIT_MULTIPLE,
          payload: res,
        })
      )
      .then(() => dispatch({ type: ACTIONS.CLEAR_MENU_REFERENCE_OBJECTS }));
  };

  const jpegsReady = jpegFilesRef.current > 0;

  return (
    <>
      <Flex justify={'space-between'} height="auto">
        <Flex w={'75%'}>
          <ImagePreviewsContainer
            jpegArray={jpegArray}
            jpegsReady={jpegsReady}
          />
        </Flex>
        <Flex
          w={'25%'}
          height="50vh"
          p={8}
          direction="column"
          justify={'space-between'}
        >
          <CheckboxesContainer
            pdfsLength={pdfs.length}
            title={title}
            clickToSubmit={clickToSubmit}
            aspectRatio={aspectRatio}
            ratio={ratio}
            dispatch={dispatch}
            handleSetSizeIds={handleSetSizeIds}
          />
          <Flex mt={6} justify={'flex-end'} alignSelf={'flex-end'}>
            {!clickToSubmit && (
              <PrimaryButton
                text="Submit"
                onClick={() => {
                  setClickToSubmit(true);
                  handleUpdatePayload();
                }}
                isDisabled={jpegFilesRef.current < 1}
              />
            )}

            {clickToSubmit ? (
              artworkGroupTabsRemaining === tabIndex ? (
                <PrimaryButton
                  text="Finish"
                  onClick={handleDisableTab}
                  loadingText="Submitting"
                  isLoading={saving}
                />
              ) : (
                <PrimaryButton
                  text="Next"
                  onClick={handleDisableTab}
                  loadingText="Submitting"
                  isLoading={saving}
                />
              )
            ) : null}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default ArtworkGroupTab;
