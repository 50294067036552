import { API_HOST_POS_DESIGNS } from '../settings';
import { AUTH0_AUDIENCE } from '../settings';

export async function submitPosSide(getAccessTokenSilently, sidePayload) {
  try {
    const designId = sidePayload.designId;

    const SIDE_UPLOAD = `${API_HOST_POS_DESIGNS}/${designId}/sides`;

    const token = await getAccessTokenSilently({
      audience: AUTH0_AUDIENCE,
    });

    const bodyObj = JSON.stringify(sidePayload);

    const postedSide = await fetch(SIDE_UPLOAD, {
      method: 'POST',
      body: bodyObj,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const response = await postedSide.json();
    return response;
  } catch (e) {
    console.error(e);
  }
}
