import toastAlert from '../../../alerts/ToastAlert';

export const showSuccessMessage = (themesData, toast) => {
  const themeName = themesData[0]?.name || '';
  const themeCount = themesData.length;
  let successMessage;

  if (themeCount > 1) {
    successMessage = `"${themeName}" and ${
      themeCount - 1
    } other theme(s) updated`;
  } else {
    successMessage = `Theme "${themeName}" has been updated`;
  }

  toastAlert(toast, 'success', successMessage);
};
