import { Flex, Text, Icon, Heading } from '@chakra-ui/react';
export const ButtonCard = ({ heading, text, icon, onClick }) => {
  return (
    <Flex
      bg={'white'}
      borderRadius="xl"
      p={5}
      textAlign="center"
      align={'center'}
      w={'100%'}
      color={'colors.darkestTealGrey'}
      onClick={onClick}
      boxShadow="0px 4px 20px 0px rgba(0, 0, 0, 0.25)"
      _hover={{ boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.50)' }}
    >
      <Icon as={icon} />
      <Flex direction={'column'} align={'flex-start'} gap={1} ml={5}>
        <Heading align={'flex-start'} fontSize={'28px'}>
          {heading}
        </Heading>
        <Text textStyle="p">{text}</Text>
      </Flex>
    </Flex>
  );
};
