import React, { useState, useEffect, useRef } from 'react';
import { Flex } from '@chakra-ui/react';

import { convertPdfToImages } from '../../actions/PDFtoImageConvertor';
import PosCheckboxesContainer from './PosCheckboxesContainer';
import ACTIONS from '../../actions/ReducerActions';
import { ArrowForwardIcon } from '@chakra-ui/icons';

import { useAuth0 } from '@auth0/auth0-react';
import { usePayloadDispatch, usePayloadState } from '../../AppPayloadContext';

import { uploadFileObjectToAWS } from '../../services/fileAWSUploadService';
import {
  submitSkeletonPosDesign,
  updatePosDesign,
} from '../../services/uploadPOSDesignService';
import { submitPosSide } from '../../services/uploadPOSSideService';
import { PrimaryButton } from '../../_librabry/buttons/PrimaryButton';
import ImagePreviewsContainer from './ImagePreviewsContainer';
import Loader from '../Loader';

const ProductGroupTab = ({
  pdfs,
  plainPdfs,
  productGroupSizes,
  productGroupName,
  currentAspects,
  tabIndex,
  handleDisableTab,
  handleUploadAlert,
  handleSubmittedDesignsCount,
}) => {
  // * STATES / REF VALUES ==============================================

  const payloadState = usePayloadState();
  const dispatch = usePayloadDispatch();

  const [clickToSubmit, setClickToSubmit] = useState(false);

  const [jpegArray, setJpegArray] = useState([]);
  const [sidesReady, setSidesReady] = useState(false);
  const [finalPayloadSent, setFinalPayloadSent] = useState(null);
  const [disableNextButton, setDisableNextButton] = useState(false);

  const handleDisableNextButton = () => {
    setDisableNextButton(!disableNextButton);
  };

  let jpegFilesRef = useRef(0);
  let pdfFilesRef = useRef(0);
  const { getAccessTokenSilently } = useAuth0();

  //* Convert PDFs > JPEGS > File Objects =================================================

  useEffect(() => {
    convertPdfToImages(pdfs) // Converts each pdf to a jpeg, then executes the remaining artwork functions
      .then(result => {
        setJpegArray(result); // dataURL jpegs are rendered to give visual feedback
        uploadJpegFiles(result);
        uploadPdfFiles(plainPdfs);
      });
  }, [pdfs.length]);

  async function uploadJpegFiles(array) {
    // Converts each Jpeg to correct format (File object), then uploads it to AWS. Condition with useRef value protects against duplicates caused by 'strict mode' second re-render
    array.forEach(jpeg => {
      convertToFile(jpeg.content, jpeg.name).then(file => {
        if (jpegFilesRef.current < plainPdfs.length) {
          addJpegToAwsThenPayload(file);
        }
      });
    });
  }

  async function uploadPdfFiles(array) {
    // Uploads pdf file objects to AWS passed in as props. useRef value protects against duplicates caused by 'strict mode' second re-render
    array.forEach(file => {
      if (pdfFilesRef.current < plainPdfs.length) {
        addPdfToAwsThenPayload(file);
      }
    });
  }

  const convertToFile = (content, fileName) => {
    // DataUrl Jpegs => File object Jpegs
    return fetch(content)
      .then(res => res.arrayBuffer())
      .then(buffer => new File([buffer], fileName, { type: 'image/jpeg' }));
  };

  function addJpegToAwsThenPayload(file) {
    // Uploads JPEGs to AWS then puts response url in correct payload - based on file name
    if (file.name.includes('Page 1')) {
      uploadFileObjectToAWS(getAccessTokenSilently, file, handleUploadAlert)
        .then(res =>
          dispatch({
            type: ACTIONS.ADD_POS_SIDE_1_PREVIEW_URL,
            payload: res.url,
          })
        )
        .then((jpegFilesRef.current = jpegFilesRef.current + 1));
    }
    if (file.name.includes('Page 2')) {
      uploadFileObjectToAWS(getAccessTokenSilently, file, handleUploadAlert)
        .then(res =>
          dispatch({
            type: ACTIONS.ADD_POS_SIDE_2_PREVIEW_URL,
            payload: res.url,
          })
        )
        .then((jpegFilesRef.current = jpegFilesRef.current + 1));
    }
    if (file.name.includes('Page 3')) {
      uploadFileObjectToAWS(getAccessTokenSilently, file, handleUploadAlert)
        .then(res =>
          dispatch({
            type: ACTIONS.ADD_POS_SIDE_3_PREVIEW_URL,
            payload: res.url,
          })
        )
        .then((jpegFilesRef.current = jpegFilesRef.current + 1));
    }
  }

  function addPdfToAwsThenPayload(file) {
    // Uploads PDFs to AWS then puts response url in correct payload - based on file name
    if (file.name.includes('Page 1')) {
      uploadFileObjectToAWS(getAccessTokenSilently, file, handleUploadAlert)
        .then(res =>
          dispatch({ type: ACTIONS.ADD_POS_SIDE_1_PRINT_URL, payload: res.url })
        )
        .then((pdfFilesRef.current = pdfFilesRef.current + 1));
    }
    if (file.name.includes('Page 2')) {
      uploadFileObjectToAWS(getAccessTokenSilently, file, handleUploadAlert)
        .then(res =>
          dispatch({ type: ACTIONS.ADD_POS_SIDE_2_PRINT_URL, payload: res.url })
        )
        .then((pdfFilesRef.current = pdfFilesRef.current + 1));
    }
    if (file.name.includes('Page 3')) {
      uploadFileObjectToAWS(getAccessTokenSilently, file, handleUploadAlert)
        .then(res =>
          dispatch({ type: ACTIONS.ADD_POS_SIDE_3_PRINT_URL, payload: res.url })
        )
        .then((pdfFilesRef.current = pdfFilesRef.current + 1));
    }
  }
  //* POST Skeleton / POST Sides / PUT finalPayload  =================================================

  const handlePostSkeleton = async event => {
    //  Takes selected sizeId/sizeName from user input adds to relevant payloads. POSTs skeleton, then adds designId from res obj to relevant payloads.
    const productName = event.target.getAttribute('productName');
    const sizeId = event.target.value.split(',')[0];
    dispatch({ type: ACTIONS.ADD_POS_SIZE_ID, payload: sizeId });
    dispatch({
      type: ACTIONS.ADD_POS_SIZE_NAME,
      payload: event.target.value.split(',')[1],
    });
    dispatch({ type: ACTIONS.ADD_POS_PRODUCT_NAME, payload: productName });

    await submitSkeletonPosDesign(
      getAccessTokenSilently,
      payloadState.posSkeleton,
      sizeId
    ).then(res => {
      dispatch({ type: ACTIONS.ADD_POS_THEME_ID, payload: res.id });
    });

    setSidesReady(true);
    handleSubmittedDesignsCount(); // Value only used for feedback on summary page
  };

  const postSides = async () => {
    // Checks which side payloads are in use based on urls, then POSTs them and adds response object to final payload
    if (
      payloadState.posSide1Payload.previewUrl &&
      payloadState.posSide1Payload.printUrl != null
    ) {
      await submitPosSide(
        getAccessTokenSilently,
        payloadState.posSide1Payload
      ).then(res =>
        dispatch({ type: ACTIONS.ADD_POS_SIDE_TO_FINAL_PAYLOAD, payload: res })
      );
    }
    if (
      payloadState.posSide2Payload.previewUrl &&
      payloadState.posSide2Payload.printUrl != null
    ) {
      await submitPosSide(
        getAccessTokenSilently,
        payloadState.posSide2Payload
      ).then(res =>
        dispatch({ type: ACTIONS.ADD_POS_SIDE_TO_FINAL_PAYLOAD, payload: res })
      );
    }
    if (
      payloadState.posSide3Payload.previewUrl &&
      payloadState.posSide3Payload.printUrl != null
    ) {
      await submitPosSide(
        getAccessTokenSilently,
        payloadState.posSide3Payload
      ).then(res =>
        dispatch({ type: ACTIONS.ADD_POS_SIDE_TO_FINAL_PAYLOAD, payload: res })
      );
    }
  };

  const postFinal = async () => {
    // Updates design in database with Side reference objects and additional information

    await updatePosDesign(
      getAccessTokenSilently,
      payloadState.posPayload,
      handleUploadAlert
    )
      .then(res => setFinalPayloadSent(res))
      .then(dispatch({ type: ACTIONS.CLEAR_POS_VALUES }));
  };

  useEffect(() => {
    // Had issues with the payloadState not updating in time for POST/PUT. useEffect checks state/payloadState to make sure data i corrects present first.
    if (sidesReady) {
      postSides();
      setSidesReady(false);
    }
    if (payloadState.posPayload.sides.length === plainPdfs.length) {
      postFinal();
      return;
    }
  }, [sidesReady, payloadState.posPayload, plainPdfs]);

  const handleNextButton = () => {
    dispatch({
      type: ACTIONS.CLEAR_POS_VALUES_AND_ARTWORK,
    });
    setClickToSubmit(true);
    handleDisableTab();
  };

  const lastTab = currentAspects.length === tabIndex;

  const jpegsReady =
    jpegFilesRef.current && pdfFilesRef.current === plainPdfs.length;

  return (
    <Flex>
      {jpegsReady ? (
        <>
          <Flex w={'75%'}>
            <ImagePreviewsContainer
              jpegArray={jpegArray}
              jpegsReady={jpegsReady}
            />
          </Flex>
          <Flex w={'25%'} direction={'column'}>
            <PosCheckboxesContainer
              jpegsReady={jpegsReady}
              pdfsLength={pdfs.length}
              productGroupName={productGroupName}
              handleDisableNextButton={handleDisableNextButton}
              finalPayloadSent={finalPayloadSent}
              handlePostSkeleton={handlePostSkeleton}
              handleSubmittedDesignsCount={handleSubmittedDesignsCount}
              clickToSubmit={clickToSubmit}
              productGroupSizes={productGroupSizes}
            />
            {lastTab ? (
              <Flex justify={'flex-end'}>
                <PrimaryButton
                  text={'Finish'}
                  disabled={disableNextButton}
                  icon={ArrowForwardIcon}
                  onClick={() => {
                    handleNextButton();
                  }}
                />
              </Flex>
            ) : (
              <Flex justify={'flex-end'}>
                <PrimaryButton
                  text={'Next'}
                  disabled={disableNextButton}
                  icon={ArrowForwardIcon}
                  onClick={() => {
                    handleNextButton();
                  }}
                />
              </Flex>
            )}
          </Flex>
        </>
      ) : (
        <Loader />
      )}
    </Flex>
  );
};

export default ProductGroupTab;
