import { React } from 'react';
import {

  Heading,

  Flex,
  Spinner,
} from '@chakra-ui/react';

import { detectThemeName } from './fileAndSizeSorting/detectThemeName';

import { usePayloadDispatch } from '../AppPayloadContext';
import ACTIONS from '../actions/ReducerActions';
import { UploadFile } from '../assets/UploadFile';
import { ButtonCard } from '../_librabry/buttons/ButtonCard';
import { PrimaryButton } from '../_librabry/buttons/PrimaryButton';

const FileUpload = ({
  hideFileUpload,
  openFileSelector,
  filesContent,
  loading,
  uploadType,
  setInvalidFiles,
  filesChecked,
  setHasThumbnail,
}) => {
  const dispatch = usePayloadDispatch();

  const numberOfFilesUploaded = filesContent.length;

  const themeName = detectThemeName(filesContent);

  const dispatchThemeName = () => {
    if (uploadType === 'pos') {
      dispatch({ type: ACTIONS.ADD_POS_THEME_NAME, payload: themeName });
    } else dispatch({ type: ACTIONS.ADD_THEME_NAME, payload: themeName });
  };

  const handleFileUpload = () => {
    if (uploadType === 'menu') {
      setInvalidFiles([]);
      setHasThumbnail(undefined);
    }
    openFileSelector();
  };

  return (
    <Flex direction={'column'} gap={8}>
      <ButtonCard
        heading={'Upload files'}
        text={'Upload all the PDF designs and the thumbnail'}
        bgColor={'colors.darkestTealGrey'}
        fontColor={'white'}
        icon={UploadFile}
        onClick={handleFileUpload}
      />
      <Flex justify={'space-between'}>
        {loading ? (
          <Spinner color="brand.100" />
        ) : (
          <Heading fontSize="md" color="colors.darkestTealGrey">
            {filesChecked || uploadType === 'pos' ? numberOfFilesUploaded : '0'}{' '}
            files uploaded{' '}
          </Heading>
        )}
        <PrimaryButton
          text={'Next'}
          onClick={() => {
            hideFileUpload();
            dispatchThemeName();
          }}
          isDisabled={numberOfFilesUploaded < 1}
        />
      </Flex>
    </Flex>
  );
};

export default FileUpload;
