// sorts all uploadedFiles by artworkGroupTag

export const sortUploadedFiles = (
  artworkGroupTag,
  filesContent,
  plainFiles,
  setSortingState
) => {
  const artworkGroup = artworkGroupTag;
  const alternativeDesigns = [];
  const alternativeDesignsPlainFiles = [];
  
  filesContent.forEach(file => {
    const fileName = file.name;
    if (fileName.includes('ALT')) {
      alternativeDesigns.push(file);
    }
    if (fileName.includes(artworkGroup) && !fileName.includes('ALT')) {
      setSortingState(prevState => {
        if (
          prevState.pdfs[artworkGroup].some(item => item.name === file.name)
        ) {
          return prevState;
        }
        return {
          ...prevState,
          pdfs: {
            ...prevState.pdfs,
            [artworkGroup]: [...prevState.pdfs[artworkGroup], file],
          },
        };
      });
    }
  });
  plainFiles.forEach(file => {
    const fileName = file.name;
    if (fileName.includes('ALT')) {
      alternativeDesignsPlainFiles.push(file);
    }
    if (fileName.includes(artworkGroup)) {
      setSortingState(prevState => {
        if (
          prevState.plainFiles[artworkGroup].some(
            item => item.name === file.name
          )
        ) {
          return prevState;
        }
        return {
          ...prevState,
          plainFiles: {
            ...prevState.plainFiles,
            [artworkGroup]: [...prevState.plainFiles[artworkGroup], file],
          },
        };
      });
    }
  });
  alternativeDesigns.forEach(file => {
    const fileName = file.name;
    if (fileName.includes('ALT')) {
      alternativeDesigns.push(file);
    }
    if (
      fileName.includes(artworkGroup) &&
      fileName.includes('ALT') &&
      artworkGroup.includes('ALT')
    ) {
      setSortingState(prevState => {
        if (
          prevState.pdfs[artworkGroup].some(item => item.name === file.name)
        ) {
          return prevState;
        }
        return {
          ...prevState,
          pdfs: {
            ...prevState.pdfs,
            [artworkGroup]: [...prevState.pdfs[artworkGroup], file],
          },
        };
      });
    }
  });
  alternativeDesignsPlainFiles.forEach(file => {
    const fileName = file.name;
    if (fileName.includes(artworkGroup)) {
      setSortingState(prevState => {
        if (
          prevState.plainFiles[artworkGroup].some(
            item => item.name === file.name
          )
        ) {
          return prevState;
        }
        return {
          ...prevState,
          plainFiles: {
            ...prevState.plainFiles,
            [artworkGroup]: [...prevState.plainFiles[artworkGroup], file],
          },
        };
      });
    }
  });
};
