import { React, useState, useEffect, useMemo } from 'react';
import { useFilePicker } from 'use-file-picker';
import { useDisclosure } from '@chakra-ui/react';

import BrandMarketSelector from '../components/BrandMarketSelector';
import FileUpload from '../components/FileUpload';
import Previews from './Previews';
import { menuSizeService } from '../services/InitialServices';
import { groupFilesWithMenuOptions } from '../components/fileAndSizeSorting/groupFilesWithMenuOptions';
import { detectArtworkGroupTagsFromUploads } from '../components/fileAndSizeSorting/detectArtworkGroup';
import { fileNameChecker } from '../components/fileAndSizeSorting/fileNameChecker';
import InvalidFilesModal from '../components/InvalidFilesModal';

const CreateNewMenuTheme = ({
  theme,
  setShowFileUpload,
  showFileUpload,
  handleUploadAlert,
  handleToggleOffAlert,
  token,
  markets,
  brands,
  subCategories,
  isOpen,
  onOpen,
  onClose,
  setSubcategories,
  setRequestState,
}) => {
  //* STATES =================================================
  const [menuOptions, setMenuOptions] = useState([]);

  // --- Rendering Components
  const [showBrandMarketSelector, setShowBrandMarketSelector] = useState(false);
  const [showPreviews, setShowPreviews] = useState(false);
  // const [groupedFilesAndSizes, setGroupedFilesAndSizes] = useState([]);
  const [invalidFiles, setInvalidFiles] = useState([]);
  const [filesChecked, setFilesChecked] = useState(false);
  const [hasThumbnail, setHasThumbnail] = useState(undefined);

  const {
    isOpen: openedInvalidFileModel,
    onOpen: openInvalidFileModal,
    onClose: closeInvalidFileModal,
  } = useDisclosure();

  const [sortingState, setSortingState] = useState({
    pdfs: {},
    plainFiles: {},
    menuOptions: {},
    groupNames: [],
  });

  const [openFileSelector, { plainFiles, filesContent, loading }] =
    useFilePicker({
      readAs: 'DataURL',
      accept: ['image/*', '.pdf'],
      multiple: true,
      limitFilesConfig: { max: 100 },
      maxFileSize: 500, // in megabytes
    });

  useEffect(() => {
    fileNameChecker(
      filesContent,
      setInvalidFiles,
      openInvalidFileModal,
      setFilesChecked,
      setHasThumbnail
    );
  }, [filesContent, plainFiles]);

  useEffect(() => {
    if (filesChecked && invalidFiles.length === 0) {
      detectArtworkGroupTagsFromUploads(
        filesContent,
        plainFiles,
        setSortingState,
        menuOptionsByGroup
      );
    }
  }, [invalidFiles, filesChecked]);

  const handleReuploadFiles = () => {
    setFilesChecked(false);
    closeInvalidFileModal();
  };

  const isSortingStateEmpty =
    Object.keys(sortingState.pdfs).length === 0 &&
    Object.keys(sortingState.plainFiles).length === 0 &&
    Object.keys(sortingState.menuOptions).length === 0;

  const groupedFilesAndSizes = useMemo(() => {
    if (isSortingStateEmpty) return;
    const groupedAspectRatios = groupFilesWithMenuOptions(sortingState);
    return groupedAspectRatios;
  }, [
    sortingState.groupNames,
    sortingState.pdfs,
    sortingState.menuOptions,
    isSortingStateEmpty,
  ]);

  //   TODO change mockMenuOption to menuOptionsByGroup when BE changes have been made

  useEffect(() => {
    menuSizeService(token).then(res => setMenuOptions(res));
  }, [token]);

  const filterMenuOptionsByGroup = obj => {
    if (
      (obj.group === 'Menu Size' && obj.isAvailableInMenuMaster) ||
      (obj.group === 'Menu Size' && obj.paperSize.includes('pos'))
    ) {
      return obj;
    }
  };
  const menuOptionsByGroup = menuOptions.filter(filterMenuOptionsByGroup);

  // ---------- Hides and shows components

  const hideFileUpload = () => {
    setShowFileUpload(false);
    setShowBrandMarketSelector(true);
  };

  const hideBrandMarketSelector = () => {
    setShowBrandMarketSelector(false);
    setShowPreviews(true);
  };

  //   ---- Calculate how tabs

  const artworkGroups = () => {
    let count = 0;
    Object.values(sortingState.pdfs).forEach(array => {
      if (array.length > 0) {
        count++;
      }
    });
    return count;
  };
  const artworkGroupTabsRemaining = artworkGroups();

  return (
    <>
      {showFileUpload ? (
        <FileUpload
          setInvalidFiles={setInvalidFiles}
          hideFileUpload={hideFileUpload}
          openFileSelector={openFileSelector}
          filesContent={filesContent}
          uploadType={'menu'}
          loading={loading}
          filesChecked={filesChecked}
          setHasThumbnail={setHasThumbnail}
        />
      ) : null}
      <InvalidFilesModal
        invalidFiles={invalidFiles}
        isOpen={openedInvalidFileModel}
        handleReuploadFiles={handleReuploadFiles}
        onClose={closeInvalidFileModal}
        hasThumbnail={hasThumbnail}
      />
      {showBrandMarketSelector ? (
        <BrandMarketSelector
          theme={theme}
          hideBrandMarketSelector={hideBrandMarketSelector}
          markets={markets}
          token={token}
          brands={brands}
          uploadType={'menu'}
        />
      ) : null}
      {showPreviews ? (
        <Previews
          handleUploadAlert={handleUploadAlert}
          handleToggleOffAlert={handleToggleOffAlert}
          artworkGroupTabsRemaining={artworkGroupTabsRemaining}
          markets={markets}
          uploadType={'menu'}
          subCategories={subCategories}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          token={token}
          setSubcategories={setSubcategories}
          groupedFilesAndSizes={groupedFilesAndSizes}
          sortingState={sortingState}
          setRequestState={setRequestState}
        />
      ) : null}
    </>
  );
};

export default CreateNewMenuTheme;
