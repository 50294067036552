import React from 'react';
import { Flex, Link, Icon } from '@chakra-ui/react';
import { Heading, Text } from '@chakra-ui/react';
import { usePayloadState } from '../AppPayloadContext';
import { ADMIN_SITE_LINK } from '../settings';
import { ExternalLinkIcon, CheckCircleIcon } from '@chakra-ui/icons';

const SummaryPos = ({ submittedDesignsCountRef }) => {
  const payloadState = usePayloadState();

  return (
    <Flex direction="column" align="flex-start" mb="20px" maxW="50rem">
      <Heading fontSize="xl" mb={4}>
        <CheckCircleIcon color="brand.100" mr="10px" />
        Success!
      </Heading>
      <Text fontSize="l" mb={2}>
        {' '}
        <b>{submittedDesignsCountRef.current}</b> different product sizes have
        been uploaded for this POS Design:
      </Text>
      <Text>
        <b>{payloadState.posPayload.name}</b>
      </Text>
      <Text>
        for{' '}
        <b>
          {payloadState.posPayload.brandName},{' '}
          {payloadState.posPayload.marketName}
        </b>
      </Text>
      <Link
        isExternal
        href={ADMIN_SITE_LINK}
        textDecor={'underline'}
        textUnderlineOffset={1}
        mb={5}
        mt={2}
      >
        Admin Site
        <Icon ml={1} as={ExternalLinkIcon} />
      </Link>
    </Flex>
  );
};

export default SummaryPos;
