import React from 'react';
import {
  OrderedList,
  Flex,
  ListItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Link,
  Heading,
} from '@chakra-ui/react';
import { WarningTwoIcon } from '@chakra-ui/icons';

const InvalidFilesModal = ({
  invalidFiles,
  isOpen,
  handleReuploadFiles,
  onClose,
  hasThumbnail,
}) => {
  const invalidFileNodes = invalidFiles.map(file => {
    return (
      <ListItem fontSize="sm" color="black">
        {file.name}
      </ListItem>
    );
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {invalidFiles.length > 0 && !hasThumbnail && (
          <ModalHeader>
            Invalid and missing files{' '}
            <WarningTwoIcon w={5} h={5} color="red.500" />
          </ModalHeader>
        )}
        {invalidFiles.length > 0 && hasThumbnail && (
          <ModalHeader>
            Invalid files <WarningTwoIcon w={5} h={5} color="red.500" />
          </ModalHeader>
        )}
        {!hasThumbnail && invalidFiles.length < 1 && (
          <ModalHeader>
            Missing files <WarningTwoIcon w={5} h={5} color="red.500" />
          </ModalHeader>
        )}

        <ModalCloseButton onClick={handleReuploadFiles} />
        <ModalBody>
          {!hasThumbnail && (
            <>
              <Heading size="sm" pb="1rem">
                Missing Thumbnail
              </Heading>
              <Text>Please upload a thumbnail file for this theme</Text>
            </>
          )}

          {invalidFiles.length > 0 && (
            <>
              <Heading size="sm" pt="1rem" pb="0.5rem">
                Invalid file names
              </Heading>
              <Flex mb="1rem">
                <OrderedList>{invalidFileNodes}</OrderedList>
              </Flex>
              <Flex
                direction="column"
                border="1px"
                borderColor="brand.700"
                borderRadius="6px"
                p="1rem"
              >
                <Text mb="0.5rem" fontWeight="bold">
                  Expected file format:
                </Text>
                <Text fontSize="sm">
                  [theme name]-Size_[artworkGroup]-Page [page No.]
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  e.g Artist Pink & Purple-Size_Tall Slim-Page 1
                </Text>
              </Flex>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="white"
            bgColor="colors.darkestTealGrey"
            mr={3}
            onClick={handleReuploadFiles}
          >
            Reupload
          </Button>
          <Link
            href="https://app.nuclino.com/MRM/Product-Team/Add-themes-for-all-formats-hyble-72ee2997-673c-4c1d-beb3-2e5cb8a4dcb5"
            isExternal
          >
            <Button variant="ghost">More info</Button>
          </Link>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InvalidFilesModal;
