// Initial POST of skeleton POS payload, return ID should be added to final POS Payload
import { API_HOST_POS_SIZES } from '../settings';
import { API_HOST_POS_DESIGNS } from '../settings';
import { AUTH0_AUDIENCE } from '../settings';

export async function submitSkeletonPosDesign(
  getAccessTokenSilently,
  payload,
  id
) {
  const SKELETON_ENDPOINT = `${API_HOST_POS_SIZES}/${id}/designs`;

  try {
    const token = await getAccessTokenSilently({
      audience: AUTH0_AUDIENCE,
    });

    const bodyObj = JSON.stringify(payload);

    const postedDesign = await fetch(SKELETON_ENDPOINT, {
      pathParams: {
        id: payload.sizeId,
      },
      method: 'POST',
      body: bodyObj,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const json = await postedDesign.json();
    return json;
  } catch (e) {
    console.error(e);
  }
}
// PUTs the updated design back in the database with side references
export async function updatePosDesign(
  getAccessTokenSilently,
  payload,
  handleUploadAlert
) {
  try {
    const designId = payload.id;

    const token = await getAccessTokenSilently({
      audience: AUTH0_AUDIENCE,
    });

    const bodyObj = JSON.stringify(payload);

    const UPDATE_ENDPOINT = `${API_HOST_POS_DESIGNS}/${designId}`;

    const postedDesign = await fetch(UPDATE_ENDPOINT, {
      pathParams: {
        id: designId,
      },
      method: 'PUT',
      body: bodyObj,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const json = await postedDesign.json();

    if (handleUploadAlert) {
      if (postedDesign.ok) {
        handleUploadAlert(
          'success',
          `Success! ${payload.name} (${payload.sizeName}) has been uploaded.`
        );
      } else {
        handleUploadAlert(
          'error',
          `An error occurred when uploading ${payload.name} (${payload.sizeName}).`
        );
      }
    }
    return json;
  } catch (e) {
    console.error(e);
  }
}
