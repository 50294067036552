import { sortMenuOptions } from './sortMenuOptions';

// creates arrays in the pdfs and plainFiles objects for each artworkGroup tag
// Adds the tag as a groupName unless its thumbnail

export const addArtworkGroupsToSortingState = (
  filesContent,
  plainFiles,
  setSortingState,
  menuOptions,
  artworkGroupTags
) => {
  artworkGroupTags.forEach(artworkGroupTag => {
    if (
      !artworkGroupTag.includes('Thumbnail') &&
      !artworkGroupTag.includes('thumbnail')
    ) {
      setSortingState(prevState => {
        if (prevState.groupNames.some(item => item === artworkGroupTag)) {
          return prevState;
        }
        return {
          ...prevState,
          groupNames: [...prevState.groupNames, artworkGroupTag],
        };
      });
    }
    if (artworkGroupTag.includes('alt')) {
      setSortingState(prevState => {
        return {
          ...prevState,
          groupNames: [...prevState.groupNames, artworkGroupTag],
        };
      });
    }
    setSortingState(prevState => {
      return {
        ...prevState,
        pdfs: {
          ...prevState.pdfs,
          [artworkGroupTag]: [],
        },
        plainFiles: {
          ...prevState.plainFiles,
          [artworkGroupTag]: [],
        },
        menuOptions: {
          ...prevState.menuOptions,
          [artworkGroupTag]: [],
        },
      };
    });
    sortMenuOptions(
      artworkGroupTag,
      filesContent,
      plainFiles,
      setSortingState,
      menuOptions
    );
  });
};
