import { VStack, Box, Text, Icon, Heading } from '@chakra-ui/react';
export const EmptyStateCard = ({ heading, text, icon }) => {
  return (
    <VStack
      bg={'white'}
      borderRadius="xl"
      p={8}
      textAlign="center"
      align={'center'}
      w={'100%'}
      color={'colors.darkestTealGrey'}
      boxShadow="0px 4px 20px 0px rgba(0, 0, 0, 0.25)"
    >
      <Heading as="h3" textStyle="h3" mb="30px" fontWeight="bold">
        {heading}
      </Heading>
      <Icon as={icon} />
      <Box h="10px"></Box>
      <Text textStyle="p">{text}</Text>
    </VStack>
  );
};
