import React from 'react';
import { DEFAULT_THEME_DETAILS } from './EditThemeModal';
import { usePayloadState } from '../../../AppPayloadContext';

import {
  Flex,
  Text,
  Box,
  Checkbox,
  Image,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react';

const SingleSelectedTheme = ({
  themeDetails,
  selectedMarket,
  selectedBrand,
  handleEnablePWA,
  handleTemplateType,
}) => {
  const themesToEdit = usePayloadState().themesToEdit;

  return (
    <>
      <Flex direction="column" mr="1rem">
        <Flex align="center">
          <Text w="7rem" fontSize="xs" fontWeight="bold">
            Name
          </Text>
          <Box {...textStyleProps}>{themesToEdit[0]?.name}</Box>
        </Flex>
        <Flex align="center" mt="0.5rem">
          <Text w="7rem" fontSize="xs" fontWeight="bold">
            Aspect Ratio
          </Text>
          <Text {...textStyleProps}>{themesToEdit[0]?.aspectRatio}</Text>
        </Flex>
        <Flex align="center" mt="0.5rem">
          <Text w="7rem" fontSize="xs" fontWeight="bold">
            Brand
          </Text>
          <Box {...textStyleProps}>{selectedBrand && selectedBrand[1]}</Box>
        </Flex>
        <Flex align="center" mt="0.5rem" mb={3}>
          <Text w="7rem" fontSize="xs" fontWeight="bold">
            Market
          </Text>
          <Box {...textStyleProps}>{selectedMarket && selectedMarket[1]}</Box>
        </Flex>

        <Checkbox
          mt="0.6rem"
          colorScheme="teal"
          mb={1}
          defaultChecked={themeDetails?.pwaEnabled}
          isChecked={
            themeDetails
              ? themeDetails?.pwaEnabled
              : DEFAULT_THEME_DETAILS.pwaEnabled
          }
          onChange={handleEnablePWA}
        >
          Show on hyble
        </Checkbox>

        <RadioGroup
          mt="0.6rem"
          colorScheme="teal"
          defaultValue="1"
          value={
            themeDetails?.templateTypeId || DEFAULT_THEME_DETAILS.templateTypeId
          }
          onChange={handleTemplateType}
          defaultChecked
        >
          <Stack spacing={3} direction="row">
            <Radio value={1}>Create Your Own</Radio>
            <Radio value={3}>Customisable</Radio>
            <Radio value={2}>Ready To Order</Radio>
          </Stack>
        </RadioGroup>
      </Flex>

      <Image
        borderRadius="2px"
        boxSize="11.5rem"
        objectFit="contain"
        bgColor="grey"
        p="2px"
        src={themesToEdit[0]?.frontCoverLowResUrl}
      />
    </>
  );
};

export default SingleSelectedTheme;

const textStyleProps = {
  w: '25rem',
  borderRadius: '4px',
  border: '1px',
  borderColor: 'brand.700',
  align: 'flex-start',
  py: '0.3rem',
  px: '0.8rem',
  disabled: true,
  textColor: 'gray.500',
  cursor: 'not-allowed',
};
