import {
  Table,
  Tbody,
  Thead,
  Flex,
  Spacer,
  Tr,
  TableContainer,
  Td,
  Wrap,
} from '@chakra-ui/react';
import React from 'react';
import HeadingsRow from './HeadingsRow';
import ThemeRows from './ThemeRows';
import { PrimaryButton } from '../../../_librabry/buttons/PrimaryButton';
import Loader from '../../../components/table/themes/Loader';
import { EmptyStateCard } from '../../../_librabry/buttons/EmptyStateCard';
import { NoThemesFound } from '../../../assets/NoThemesFound';

const ThemeView = ({
  requestState,
  setRequestState,
  handleEditSingleTheme,
  selectedBrand,
  selectedMarket,
  handleLoadMoreMenus,
  canEditMultiple,
  existingThemes,
}) => {
  const loadedValue = () => {
    if (requestState.totalNumberOfThemes < 50) {
      return requestState.totalNumberOfThemes;
    }
    return Math.min(requestState.totalNumberOfThemes, requestState.offset + 50);
  };

  return (
    <>
      <TableContainer
        direction={'column'}
        w="85vw"
        border="1px solid lightgray"
        borderRadius={10}
        maxW={'90vw'}
        h={'50vh'}
        overflowX={'scroll'}
        overflowY="scroll"
      >
        <Table
          border-collapse="collapse"
          table-layout="fixed"
          data-testid="theme-table"
          whiteSpace="wrap"
        >
          <>
            <Thead position={'sticky'} top="0">
              <HeadingsRow
                requestState={requestState}
                setRequestState={setRequestState}
              />
            </Thead>
            <Tbody>
              {requestState.isLoading && (
                <Tr>
                  <Td colSpan={11}>
                    <Loader />
                  </Td>
                </Tr>
              )}
              {!requestState.isLoading && !existingThemes && (
                <Tr>
                  <Td colSpan={11}>
                    <EmptyStateCard
                      heading={'No themes found'}
                      text={'Please select another brand or market'}
                      bgColor={'colors.darkestTealGrey'}
                      icon={NoThemesFound}
                    />
                  </Td>
                </Tr>
              )}
              {existingThemes && (
                <ThemeRows
                  canEditMultiple={canEditMultiple}
                  handleEditSingleTheme={handleEditSingleTheme}
                  requestState={requestState}
                  setRequestState={setRequestState}
                  selectedBrand={selectedBrand}
                  selectedMarket={selectedMarket}
                />
              )}
            </Tbody>
          </>
        </Table>
      </TableContainer>

      <Flex align="center" justify="center" p={1} mt={2}>
        <>
          <Flex color="gray.500" data-testid="numberOfThemes">
            {loadedValue()} themes loaded out of{' '}
            {requestState.totalNumberOfThemes}
          </Flex>
          <Spacer />

          {requestState.totalNumberOfThemes > 50 && (
            <PrimaryButton
              text={'Load more'}
              isDisabled={requestState.totalNumberOfThemes < 50}
              onClick={handleLoadMoreMenus}
              dataTestId="loadMoreButton"
            />
          )}

          <Spacer />
        </>
      </Flex>
    </>
  );
};

export default ThemeView;
