import { Input } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

const HeadingTextFilter = ({ heading, requestState, setRequestState }) => {
  const [searchValue, setSearchValue] = useState('');
  const [touched, setTouched] = useState(false);
  const filterKey = heading.apiKey;

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      const newQuery = {};
      if (filterKey) {
        newQuery[filterKey] = searchValue;

        if (searchValue !== '' || touched) {
          setTouched(true);
          setRequestState({
            ...requestState,
            filters: {
              ...requestState.filters,
              ...newQuery,
            },
            isLoading: true,
            offset: 0,
            timeStamp: new Date().getSeconds(),
          });
        }
      }
    }, 500);

    return () => clearTimeout(timeOutId);
  }, [searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  if (heading.searchable) {
    return (
      <Input
        disabled={!requestState.selectedMarket}
        size="xs"
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        borderColor="gray.400"
        placeholder="search..."
      />
    );
  }
};

export default HeadingTextFilter;
