import React from 'react';
import { Flex, Spacer, Button, Text, Box, Icon, Link } from '@chakra-ui/react';

import { useAuth0 } from '@auth0/auth0-react';
import { MRM_TOOLKIT_URL } from '../../settings';
import { ChevronLeftIcon } from '@chakra-ui/icons';

import HybleLogo from '../../assets/HybleLogo';
import Logout from '../../assets/Logout';
import QuestionMark from '../../assets/QuestionMark';

const HeaderBar = () => {
  const { logout } = useAuth0();
  const restartProcess = () => window.location.reload(true);

  return (
    <Flex
      h="60px"
      bg="colors.darkestTeal"
      alignItems="center"
      zIndex="10"
      px="24px"
      position={'sticky'}
      top={0}
      boxShadow="0px 4px 20px 0px rgba(0, 0, 0, 0.25)"
    >
      <Button
        onClick={restartProcess}
        background="none"
        padding="0"
        _hover={{ bgColor: 'none' }}
        _active={{ bgColor: 'none' }}
      >
        <HybleLogo />
      </Button>

      <Button
        variant="ghost"
        sx={buttonStyles}
        pb="2px"
        _hover={{ bg: 'none' }}
        _focus={{ bg: 'none' }}
        _active={{ bg: 'none' }}
      >
        <ChevronLeftIcon color="white" mr="4px" />
        <Text
          as="a"
          color="white"
          fontSize="12px"
          fontWeight="700"
          href={MRM_TOOLKIT_URL}
        >
          Back to Toolkit
        </Text>
      </Button>

      <Spacer />

      <Flex
        align={'center'}
        gap={4}
        style={{ cursor: 'pointer' }}
        color={'colors.medDarkTeal'}
      >
        <Box _hover={{ color: 'colors.mrmTeal' }}>
          <Link
            href={'https://support.hyble.tech/'}
            isExternal
            _hover={{ color: 'colors.mrmTeal' }}
            data-heap="header_supportButton"
            data-testid="support-button"
            rel="noreferrer"
          >
            <Icon w="24px" h="24px" as={QuestionMark} />
          </Link>
        </Box>

        <Box
          onClick={() => logout({ returnTo: window.location.origin })}
          _hover={{ color: 'colors.mrmTeal' }}
        >
          <Icon data-testid="header-logout" as={Logout} w="24px" h="24px" />
        </Box>
      </Flex>
    </Flex>
  );
};

export default HeaderBar;

const buttonStyles = {
  color: 'white',
  fontSize: '14px',
  fontWeight: '700',
  _hover: { opacity: 0.7 },
};
