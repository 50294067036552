import { sortUploadedFiles } from './sortUploadedFiles';

// sorts all menuOptions by artworkGroupTag

export const sortMenuOptions = (
  artworkGroupTag,
  filesContent,
  plainFiles,
  setSortingState,
  menuOptions
) => {
  const artworkGroup = artworkGroupTag;
  const artworkGroupWithoutAlt = artworkGroupTag.replace(/\s+ALT\b/g, '');
  menuOptions.forEach(size => {
    if (artworkGroupWithoutAlt === size.artworkGroup) {
      setSortingState(prevState => {
        return {
          ...prevState,
          menuOptions: {
            ...prevState.menuOptions,
            [artworkGroup]: [...prevState.menuOptions[artworkGroup], size],
          },
        };
      });
    }
    sortUploadedFiles(
      artworkGroupTag,
      filesContent,
      plainFiles,
      setSortingState
    );
  });
};
