import React, { useState, useRef } from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import ThumbnailTab from '../components/previews/ThumbnailTab';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import SummaryPOS from '../components/SummaryPOS';
import ProductGroupTab from '../components/previews/ProductGroupTab';

const PreviewsPOS = ({
  handleUploadAlert,
  handleToggleOffAlert,
  POSThumbnail,
  currentAspects,
  POSObjectForMapping,
  uploadType,
}) => {
  const [disableThumbnail, setDisableThumbnail] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  let submittedDesignsCountRef = useRef(0);

  const handleDisableTab = () => {
    handleToggleOffAlert();
    setTabIndex(tabIndex + 1);
  };

  const handleDisableThumbnail = () => {
    handleToggleOffAlert();
    setTabIndex(tabIndex + 1);
    setDisableThumbnail(true);
  };

  const handleSubmittedDesignsCount = () => {
    submittedDesignsCountRef.current = submittedDesignsCountRef.current + 1;
  };

  const completedTick = () => {
    return <CheckCircleIcon ml="0.5em" w={4} h={4} color="brand.100" />;
  };

  const productGroup = function (
    pdfs,
    plainFiles,
    productGroupSizes,
    productGroupName
  ) {
    this.pdfs = pdfs;
    this.plainFiles = plainFiles;
    this.productGroupSizes = productGroupSizes;
    this.productGroupName = productGroupName;
    // this.aspectRatio = aspectRatio;
  };

  const productGroupObjects = [];

  POSObjectForMapping.forEach(POSTabs => {
    const pdfArrays = POSTabs.pdfs;
    pdfArrays.forEach(pdfs => {
      if (pdfs.length >= 1) {
        let i = pdfArrays.indexOf(pdfs);
        POSObjectForMapping.forEach(POSTabs => {
          let productGroupName = POSTabs.productGroupName[i];
          let productGroupNameToString = JSON.stringify(productGroupName);
          productGroupNameToString = new productGroup(
            POSTabs.pdfs[i],
            POSTabs.plainFiles[i],
            POSTabs.productGroupSizes[i],
            POSTabs.productGroupName[i]
          );
          productGroupObjects.push(productGroupNameToString);
        });
      }
    });
  });

  const showSummary = currentAspects.length + 1 === tabIndex;

  return (
    <Flex
      justifyContent="flex-start"
      direction="column"
      borderRadius="5px"
      pt="1vh"
      position="relative"
    >
      {!showSummary && (
        <>
          <Heading
            align="felx-start"
            color="colors.darkestTealGrey"
            fontWeight="normal"
            fontSize="1em"
            mb="5vh"
          >
            Confirm artwork has mapped to the correct sides and select the
            required product sizes. A POS design will be created for each size
            you select.
          </Heading>

          <Tabs
            isFitted
            bg={'white'}
            isLazy
            variant="enclosed-colored"
            colorscheme="blue"
            index={tabIndex}
            onChange={index => {
              setTabIndex(index);
            }}
          >
            <TabList>
              <Tab
                id="0"
                fontWeight="bold"
                fontSize="sm"
                isDisabled={disableThumbnail}
              >
                Thumbnail {disableThumbnail ? completedTick() : null}
              </Tab>
              {productGroupObjects.map((productGroup, index) => {
                return (
                  <Tab isDisabled>
                    {productGroup.productGroupName}{' '}
                    {index < tabIndex - 1 ? completedTick() : null}
                  </Tab>
                );
              })}
            </TabList>
            <TabPanels>
              <TabPanel id="0">
                <ThumbnailTab
                  handleDisableThumbnail={handleDisableThumbnail}
                  pdf={POSThumbnail}
                  title="Thumbnail"
                  handleUploadAlert={handleUploadAlert}
                  uploadType={uploadType}
                />
              </TabPanel>
              {productGroupObjects.map((productGroup, index) => {
                return (
                  <TabPanel key={index}>
                    <ProductGroupTab
                      handleDisableTab={handleDisableTab}
                      handleSubmittedDesignsCount={handleSubmittedDesignsCount}
                      pdfs={productGroup.pdfs}
                      plainPdfs={productGroup.plainFiles}
                      productGroupSizes={productGroup.productGroupSizes}
                      productGroupName={productGroup.productGroupName}
                      currentAspects={currentAspects}
                      tabIndex={tabIndex}
                      handleUploadAlert={handleUploadAlert}
                    />
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        </>
      )}

      {showSummary && (
        <SummaryPOS submittedDesignsCountRef={submittedDesignsCountRef} />
      )}
    </Flex>
  );
};

export default PreviewsPOS;
