import React, { useState, useEffect } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { convertThumbnailPdfToImage } from '../../actions/PDFtoImageConvertor';
import ACTIONS from '../../actions/ReducerActions';
import { uploadThemeFile } from '../../services/fileBlobUploadService';
import { useAuth0 } from '@auth0/auth0-react';
import { usePayloadDispatch, usePayloadState } from '../../AppPayloadContext';
import { uploadFileObjectToAWS } from '../../services/fileAWSUploadService';
import { PrimaryButton } from '../../_librabry/buttons/PrimaryButton';
import ImagePreviewsContainer from './ImagePreviewsContainer';

const ThumbnailTab = ({
  pdf,
  handleUploadAlert,
  handleDisableThumbnail,
  uploadType,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const payloadState = usePayloadState();
  const dispatch = usePayloadDispatch();

  const [jpegThumbnail, setJpegThumbnail] = useState([]);
  const [thumbnailLoading, setThumbnailLoading] = useState(false);
  const [convertedFile, setConvertedFile] = useState(null);

  useEffect(() => {
    convertThumbnailPdfToImage(pdf).then(result => setJpegThumbnail(result));
  }, [pdf]);

  const addThumbnailToPayload = () => {
    setThumbnailLoading(true);
    if (uploadType === 'menu') {
      uploadThemeFile(
        getAccessTokenSilently,
        convertedFile,
        payloadState.menuPayload.name,
        handleUploadAlert
      )
        .then(res =>
          dispatch({ type: ACTIONS.ADD_THUMBNAIL_REF, payload: res })
        )
        .then(() => setThumbnailLoading(false));
    } else {
      uploadFileObjectToAWS(
        getAccessTokenSilently,
        convertedFile,
        handleUploadAlert
      )
        .then(res =>
          dispatch({ type: ACTIONS.ADD_POS_PREVIEW_URL, payload: res.url })
        )
        .then(() => setThumbnailLoading(false));
    }
  };

  const handleThumbnail = () => {
    addThumbnailToPayload();
  };

  useEffect(() => {
    if (jpegThumbnail !== null) {
      jpegThumbnail.forEach(jpeg => {
        srcToFile(jpeg.content, jpeg.name, 'image/jpeg').then(function (file) {
          setConvertedFile(file);
        });
      });
    }
  }, [jpegThumbnail]);

  async function srcToFile(src, fileName, mimeType) {
    const res = await fetch(src);
    const buf = await res.arrayBuffer();
    return new File([buf], fileName, { type: mimeType });
  }

  const showNextButton =
    (payloadState.menuPayload.thumbnailFileReference && !thumbnailLoading) ||
    (payloadState.posPayload.previewUrl && !thumbnailLoading);

  return (
    <>
      <Flex justify="space-between">
        <Flex w={'75%'}>
          <ImagePreviewsContainer
            jpegArray={jpegThumbnail}
            jpegsReady={jpegThumbnail}
          />
        </Flex>
        <Flex w={'25%'} direction="column" justify={'space-between'}>
          <Flex direction="column" align={'flex-start'} p={8}>
            {jpegThumbnail.length > 0 ? (
              <>
                <Text fontWeight="bold" mb={4} fontSize={'18px'}>
                  Submit your thumbnail
                </Text>
                <Text fontSize={'16px'}>
                  This thumbnail will be applied to all aspect ratios for this
                  theme
                </Text>
              </>
            ) : (
              <>
                <Text fontWeight="bold" fontSize="l" mt="1vh">
                  Please wait!{' '}
                </Text>
                <Text fontSize="l" mt="1vh" mb="1vh">
                  Creating JPEG thumbnail...
                </Text>
              </>
            )}
          </Flex>

          {!showNextButton && (
            <Flex justify={'flex-end'}>
              <PrimaryButton
                text={'Submit'}
                onClick={() => {
                  handleThumbnail();
                }}
                isLoading={thumbnailLoading}
                loadingText={'Submitting'}
              />
            </Flex>
          )}
          {pdf.length < 1 && !thumbnailLoading && (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle>No thumbnail uploaded!</AlertTitle>
              <AlertDescription>
                Please upload a thumbnail to proceed
              </AlertDescription>
            </Alert>
          )}

          {showNextButton && (
            <Flex alignSelf={'flex-end'}>
              <PrimaryButton text={'Next'} onClick={handleDisableThumbnail} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default ThumbnailTab;
