import {
  FormControl,
  Select,
  Box,
  Text,
  HStack,
  Spinner,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import { React } from 'react';

const sortAlphabetically = (a, b) => {
  const brandmarketA = a.name.toUpperCase();
  const brandmarketB = b.name.toUpperCase();

  let comparison = 0;
  if (brandmarketA > brandmarketB) {
    comparison = 1;
  } else if (brandmarketA < brandmarketB) {
    comparison = -1;
  }
  return comparison;
};

const SelectThemesBrandMarket = ({
  brands,
  markets,
  selectedBrand,
  handleBrandChange,
  handleMarketChange,
}) => {
  const filterMarketsByBrandId = marketObject => {
    return marketObject.brandId == selectedBrand[0];
  };

  const filterTestDeletedBrands = brandObject => {
    if (
      !(
        brandObject.name == 'System Test' ||
        brandObject.name == 'System Tests' ||
        brandObject.deleted == 1
      )
    ) {
      return brandObject;
    }
  };

  // sorts all brands alphabetically, then filters out test brands
  const filterBrands = () => {
    if (brands) {
      return brands
        .sort(sortAlphabetically)
        .filter(filterTestDeletedBrands)
        .map((brand, index) => {
          if (brand.deleted !== true) {
            return (
              <option key={index} value={[brand.id, brand.name]}>
                {brand.name}
              </option>
            );
          }
        });
    }
  };

  const filterMarkets = () => {
    if (markets) {
      return markets
        .sort(sortAlphabetically)
        .filter(filterMarketsByBrandId)
        .map((market, index) => {
          if (market.deleted !== true && market.id !== 77 && market.id !== 20) {
            return (
              <option key={index} value={[market.id, market.name]}>
                {market.name}
              </option>
            );
          }
        });
    }
  };

  return (
    <Box>
      <HStack>
        <Text fontWeight="bold">Brand:</Text>
        <FormControl>
          <Select
            minW="18vw"
            onChange={e => handleBrandChange(e)}
            placeholder={brands?.length > 0 ? 'Select Brand' : 'Loading...'}
            br="30"
            icon={brands.length === 0 ? <Spinner /> : <ChevronDownIcon />}
          >
            {brands && filterBrands()}
          </Select>
        </FormControl>

        <Text fontWeight="bold" pl="30">
          Market:
        </Text>
        <FormControl>
          <Select
            minW="18vw"
            onChange={e => handleMarketChange(e)}
            disabled={selectedBrand?.length === 0}
            placeholder={
              selectedBrand?.length === 0
                ? 'Select Brand First'
                : markets?.length > 0
                ? 'Select Market'
                : 'Loading...'
            }
          >
            {markets ? filterMarkets() : null}
          </Select>
        </FormControl>
      </HStack>
    </Box>
  );
};

export default SelectThemesBrandMarket;
