export function calculateAspectRatio(size) {
  let adjustedWidth = size.width;

  if (size.value === 2) {
    adjustedWidth = size.width * 3;
  }

  const aspectRatio = 10 * Math.round((10 * adjustedWidth) / size.height);
  return aspectRatio;
}
