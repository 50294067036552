import { API_HOST_THEMES_UPLOAD } from '../settings';
import { AUTH0_AUDIENCE } from '../settings';

export function uploadThemeFile(
  getAccessTokenSilently,
  file,
  themeName,
  handleUploadAlert
) {
  const providerUrl = API_HOST_THEMES_UPLOAD;
  const fileUploadParams = themeName;

  // Returns a promise
  return uploadFile(
    getAccessTokenSilently,
    file,
    providerUrl,
    fileUploadParams,
    handleUploadAlert
  );
}

async function uploadFile(
  getAccessTokenSilently,
  file,
  providerUrl,
  fileUploadParams,
  handleUploadAlert
) {
  try {
    // 1. Get Shared Access Signature (SAS)
    const { uri, container, path } = await getSASUrl(
      getAccessTokenSilently,
      providerUrl,
      file.name,
      fileUploadParams
    );

    // 2. Upload file using SAS
    await uploadFileToCloud(uri, file, handleUploadAlert);

    let contentType = 'image/jpeg';
    if (getFileExtension(file.name) === '.pdf') {
      contentType = 'application/pdf';
    }
    // 3.  uploadDetails to be added payload
    const uploadDetails = {
      container,
      contentType: contentType,
      originalFilename: file.name,
      path,
    };

    return uploadDetails;
  } catch (e) {
    console.error(e);
  }
}

async function getSASUrl(
  getAccessTokenSilently,
  providerUrl,
  filename,
  fileUploadParams
) {
  try {
    const token = await getAccessTokenSilently({
      audience: AUTH0_AUDIENCE,
      scope: 'read:markets',
    });

    const fileExtension = getFileExtension(filename);

    const response = await fetch(providerUrl, {
      method: 'POST',
      body: JSON.stringify({
        fileExtension,
        ...fileUploadParams,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();

    return json;
  } catch (e) {
    console.error(e);
  }
}

async function uploadFileToCloud(sasUrl, file, handleUploadAlert) {
  try {
    const contentType = file.type;

    const response = await fetch(sasUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': contentType,
        'X-MS-Blob-Type': 'BlockBlob',
      },
    });

    if (handleUploadAlert) {
      if (response.ok) {
        handleUploadAlert(
          'success',
          `Success! The thumbnail file has been uploaded.`
        );
      } else {
        handleUploadAlert(
          'error',
          `An error occurred. The thumbnail file was not uploaded.`
        );
      }
    }
  } catch (e) {
    console.error(e);
  }
}

function getFileExtension(filename) {
  const extWithoutPeriod = filename.split('.').pop();
  const ext = `.${extWithoutPeriod}`;

  return ext;
}
