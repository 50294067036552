import { AUTH0_AUDIENCE } from '../settings';
import { API_HOST_POS_IMAGES } from '../settings';

const EndpointForSAS = API_HOST_POS_IMAGES;
// https://mrm-orders-service-dev.azurewebsites.net/api/v1-0/images
// const EndpointForSAS = 'https://dev.mrmbrand-apis.net/api/v1-0/images';
// ADD DISPATCH ACTIONS

// Upload file to AWS storage - response obj from MRM Shared Access Signature gives PUT location in bucket, plus client URL to access file, to add to payload
export async function uploadFileObjectToAWS(
  getAccessTokenSilently,
  artwork,
  handleUploadAlert
) {
  try {
    const token = await getAccessTokenSilently({
      audience: AUTH0_AUDIENCE,
    });

    const body = JSON.stringify({
      mimeType: artwork.type,
      path: 'pointofsale',
    });

    const S3Data = await fetch(EndpointForSAS, {
      method: 'POST',
      body,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const json = await S3Data.json();

    S3ArtworkPut(json.s3PutUrl, artwork, handleUploadAlert);

    return json;
  } catch (e) {
    console.error(e);
  }
}

// function for to PUT file in AWS bucket

async function S3ArtworkPut(url, artwork, handleUploadAlert) {
  try {
    const config = {
      method: 'PUT',
      body: artwork,
      headers: { 'Content-Type': artwork.type },
    };

    const response = await fetch(url, config);
    const fileName = artwork.name;

    if (handleUploadAlert && fileName.includes('Thumbnail')) {
      if (response.ok) {
        handleUploadAlert('success', `Success! ${fileName} has been uploaded.`);
      } else {
        handleUploadAlert(
          'error',
          `An error occurred when uploading ${fileName}.`
        );
      }
    }
  } catch (e) {
    console.error(e);
  }
}
