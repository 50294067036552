const ACTIONS = {
  // Menus
  ADD_MARKET_ID: 'add-market-id',
  ADD_THEME_NAME: 'add-theme-name',
  ADD_MENU_SIZE_IDS: 'add-menu-size-ids',
  ADD_ASPECT_RATIO: 'add-aspect-ratio',
  ADD_TAG: 'add-tag',
  ADD_PRODUCT_BRAND_ID: 'add-product-brand-id',
  ADD_THUMBNAIL_REF: 'add-thumbnail-ref',
  ADD_FRONT_COVER_HIGH_RES_REF: 'add-front-cover-high-res-ref',
  ADD_FRONT_COVER_LOW_RES_REF: 'add-front-cover-low-res-ref',
  ADD_BACK_COVER_HIGH_RES_REF: 'add-back-cover-high-res-ref',
  ADD_BACK_COVER_LOW_RES_REF: 'add-back-cover-low-res-ref',
  ADD_CONTENT_PAGE_HIGH_RES_REF: 'add-content-page-high-res-ref',
  ADD_CONTENT_PAGE_LOW_RES_REF: 'add-content-page-low-res-ref',
  CLEAR_MENU_REFERENCE_OBJECTS: 'clear--menu-reference-objects',

  // POS
  ADD_POS_THEME_ID: 'add-pos-theme-id',
  ADD_POS_THEME_NAME: 'add-pos-theme-name',
  ADD_POS_SIZE_ID: 'add-pos-size-id',
  ADD_POS_PREVIEW_URL: 'add-pos-preview-url',
  ADD_POS_SIDE_TO_FINAL_PAYLOAD: 'add-pos-sides',
  ADD_POS_SIZE_NAME: 'add-pos-size-name',
  ADD_POS_PRODUCT_NAME: 'add-pos-product-name',
  ADD_POS_MARKET_ID: 'add-pos-market-id',
  ADD_POS_MARKET_NAME: 'add-pos-market-name',
  ADD_POS_BRAND_ID: 'add-pos-brand-id',
  ADD_POS_BRAND_NAME: 'add-pos-brand-name',
  ADD_POS_PRODUCT_BRAND_ID: 'add-pos-product-brand-id',
  ADD_POS_TAG: 'add-pos-tag',
  ADD_POS_MARGIN: 'add-pos-margin',

  ADD_POS_SIDE_1_PREVIEW_URL: 'add-pos-side-1-preview-url',
  ADD_POS_SIDE_1_PRINT_URL: 'add-pos-side-1-print-url',
  ADD_POS_SIDE_2_PREVIEW_URL: 'add-pos-side-2-preview-url',
  ADD_POS_SIDE_2_PRINT_URL: 'add-pos-side-2-print-url',
  ADD_POS_SIDE_3_PREVIEW_URL: 'add-pos-side-3-preview-url',
  ADD_POS_SIDE_3_PRINT_URL: 'add-pos-side-3-print-url',

  CLEAR_POS_VALUES: 'clear-pos-values',
  CLEAR_POS_VALUES_AND_ARTWORK: 'clear-pos-values-and-artwork',

  // THEMES TO EDIT
  ADD_THEME_TO_EDIT_MULTIPLE: 'add-theme-to-edit_multiple',
  ADD_THEME_TO_EDIT_SINGLE: 'add-theme-to-edit_single',
  REMOVE_THEME_TO_EDIT: 'remove-theme-to-edit',
  CLEAR_THEMES_TO_EDIT: 'clear-themes-to-edit',
};

export default ACTIONS;
