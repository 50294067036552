import React from 'react';
import { Select, Text, Flex } from '@chakra-ui/react';

const UploadType = ({ hideUploadType, setUploadType }) => {
  const handleSelectDesignType = e => {
    if (e.target.value === 'menu') {
      setUploadType('menu');
      hideUploadType();
    } else {
      setUploadType('pos');
      hideUploadType();
    }
  };

  return (
    <Flex direction={'column'} gap={4}>
      <Text color={'colors.darkestTeal'}>
        What type of theme are you creating?
      </Text>
      <Select
        onChange={e => handleSelectDesignType(e)}
        variant="outline"
        placeholder="Select Design Type"
        size="md"
        textColor={'black'}
        bg={'white'}
        w={'400px'}
      >
        <option value="menu">Menu Theme</option>
        <option value="pos">POS Design</option>
      </Select>
    </Flex>
  );
};

export default UploadType;
