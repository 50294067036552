import { React } from 'react';
import { Flex } from '@chakra-ui/react';
import FontBySection from './FontBySection';

const FontStyles = ({ themeDetails, setThemeDetails, selectedBrand }) => {

  const handleUpdateFontSize = (name, fontSize) => {
    setThemeDetails(themeDetails => ({
      ...themeDetails,
      pwaStyles: {
        ...themeDetails.pwaStyles,
        [name]: {
          ...themeDetails.pwaStyles[name],
          fontSize: fontSize,
        },
      },
    }));
  };

  const handleUpdateFontFamily = (name, fontName) => {
    setThemeDetails(themeDetails => ({
      ...themeDetails,
      pwaStyles: {
        ...themeDetails.pwaStyles,
        [name]: {
          ...themeDetails.pwaStyles[name],
          fontFamily: fontName,
        },
      },
    }));
  };

  const handleUpdateFontColor = (name, fontColorHex) => {
    setThemeDetails(themeDetails => ({
      ...themeDetails,
      pwaStyles: {
        ...themeDetails.pwaStyles,
        [name]: {
          ...themeDetails.pwaStyles[name],
          color: fontColorHex,
        },
      },
    }));
  };

  return (
    <Flex direction="column" mt="1rem" data-testid="font-styles-flex">
      <FontBySection
        textCategory={'heading'}
        setThemeDetails={setThemeDetails}
        themeDetails={themeDetails}
        handleUpdateFontSize={handleUpdateFontSize}
        fontSize={themeDetails.pwaStyles?.heading?.fontSize}
        fontFamily={themeDetails.pwaStyles?.heading?.fontFamily}
        textColor={themeDetails.pwaStyles?.heading?.color}
        align={themeDetails.pwaStyles?.heading?.align}
        selectedBrand={selectedBrand}
        handleUpdateFontFamily={handleUpdateFontFamily}
        handleUpdateFontColor={handleUpdateFontColor}
      />
      <FontBySection
        textCategory={'subHeading'}
        setThemeDetails={setThemeDetails}
        themeDetails={themeDetails}
        fontFamily={themeDetails.pwaStyles?.subHeading?.fontFamily}
        textColor={themeDetails.pwaStyles?.subHeading?.color}
        align={themeDetails.pwaStyles?.subHeading?.align}
        fontSize={themeDetails.pwaStyles?.subHeading?.fontSize}
        selectedBrand={selectedBrand}
        handleUpdateFontSize={handleUpdateFontSize}
        handleUpdateFontFamily={handleUpdateFontFamily}
        handleUpdateFontColor={handleUpdateFontColor}
      />
      <FontBySection
        textCategory={'bodyText'}
        setThemeDetails={setThemeDetails}
        themeDetails={themeDetails}
        fontFamily={themeDetails.pwaStyles?.bodyText?.fontFamily}
        textColor={themeDetails.pwaStyles?.bodyText?.color}
        align={themeDetails.pwaStyles?.bodyText?.align}
        fontSize={themeDetails.pwaStyles?.bodyText?.fontSize}
        selectedBrand={selectedBrand}
        handleUpdateFontSize={handleUpdateFontSize}
        handleUpdateFontFamily={handleUpdateFontFamily}
        handleUpdateFontColor={handleUpdateFontColor}
      />
      <FontBySection
        textCategory={'legalText'}
        setThemeDetails={setThemeDetails}
        themeDetails={themeDetails}
        fontFamily={themeDetails.pwaStyles?.legalText?.fontFamily}
        textColor={themeDetails.pwaStyles?.legalText?.color}
        align={themeDetails.pwaStyles?.legalText?.align}
        fontSize={themeDetails.pwaStyles?.legalText?.fontSize}
        selectedBrand={selectedBrand}
        handleUpdateFontSize={handleUpdateFontSize}
        handleUpdateFontFamily={handleUpdateFontFamily}
        handleUpdateFontColor={handleUpdateFontColor}
      />
      <FontBySection
        textCategory={'productTitle'}
        setThemeDetails={setThemeDetails}
        themeDetails={themeDetails}
        fontFamily={themeDetails.pwaStyles?.productTitle?.fontFamily}
        textColor={themeDetails.pwaStyles?.productTitle?.color}
        align={themeDetails.pwaStyles?.productTitle?.align}
        fontSize={themeDetails.pwaStyles?.productTitle?.fontSize}
        selectedBrand={selectedBrand}
        handleUpdateFontSize={handleUpdateFontSize}
        handleUpdateFontFamily={handleUpdateFontFamily}
        handleUpdateFontColor={handleUpdateFontColor}
      />
      <FontBySection
        textCategory={'productDescription'}
        setThemeDetails={setThemeDetails}
        themeDetails={themeDetails}
        fontFamily={themeDetails.pwaStyles?.productDescription?.fontFamily}
        textColor={themeDetails.pwaStyles?.productDescription?.color}
        align={themeDetails.pwaStyles?.productDescription?.align}
        fontSize={themeDetails.pwaStyles?.productDescription?.fontSize}
        selectedBrand={selectedBrand}
        handleUpdateFontSize={handleUpdateFontSize}
        handleUpdateFontFamily={handleUpdateFontFamily}
        handleUpdateFontColor={handleUpdateFontColor}
      />
      <FontBySection
        textCategory={'productPrice'}
        setThemeDetails={setThemeDetails}
        themeDetails={themeDetails}
        fontFamily={themeDetails.pwaStyles?.productPrice?.fontFamily}
        textColor={themeDetails.pwaStyles?.productPrice?.color}
        align={themeDetails.pwaStyles?.productPrice?.align}
        fontSize={themeDetails.pwaStyles?.productPrice?.fontSize}
        selectedBrand={selectedBrand}
        handleUpdateFontSize={handleUpdateFontSize}
        handleUpdateFontFamily={handleUpdateFontFamily}
        handleUpdateFontColor={handleUpdateFontColor}
      />
    </Flex>
  );
};

export default FontStyles;
