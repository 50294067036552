import React, { useState, useEffect } from 'react';
import { Text, Flex, Box, List, ListItem, ListIcon } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { usePayloadState } from '../../AppPayloadContext';
import { CheckIcon } from '@chakra-ui/icons';

export const Checkboxes = ({ ratio, handleSetSizeIds, clickToSubmit }) => {
  const [list, setList] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(true);
  const [isCheck, setIsCheck] = useState([]);

  useEffect(() => {
    setList(ratio);
  }, [ratio]);

  useEffect(() => {
    if (list.length >= 1) {
      setIsCheck(list.map(li => li.id));
      handleSetSizeIds(list.map(li => li.id));
    }
  }, [list]);

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    if (isCheckAll === true) {
      setIsCheck([]);
    }
    if (isCheckAll !== true) {
      setIsCheck(list.map(li => li.id));
    }
  };

  const handleClick = e => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, parseInt(id)]);
    handleSetSizeIds([...isCheck, parseInt(id)]);

    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== parseInt(id)));
      handleSetSizeIds(isCheck.filter(item => item !== parseInt(id)));
    }
  };

  const tickedBox = size => {
    if (isCheck.includes(size.id)) {
      return true;
    } else return false;
  };

  const sortBySize = (a, b) => {
    let comparison = 0;
    if (a.paperSize > b.paperSize && a.pages > b.pages) {
      comparison = 1;
    } else if (a.paperSize < b.paperSize && a.pages > b.pages) {
      comparison = -1;
    }
    return comparison;
  };

  const checkboxNodes = list
    .sort(
      (a, b) =>
        // a.paperSize.localeCompare(b.paperSize)
        a.displayName.includes('Clipboard') -
          b.displayName.includes('Clipboard') || a.pages - b.pages
    )
    .map((size, id, index) => {
      return (
        <>
          <Flex key={index} mb={3}>
            <Input
              key={id}
              type="checkbox"
              id={size.id}
              value={size.id}
              onChange={handleClick}
              checked={tickedBox(size)}
            />
            <Text fontSize="md">{size.displayName}</Text>
          </Flex>
        </>
      );
    });

  const payloadState = usePayloadState();

  const confirmedSizesNodes = list
    .sort(sortBySize)
    .filter(
      list =>
        payloadState.menuPayload.enabledMenuSizeIds.includes(list.id) === true
    )
    .map((el, index) => {
      return (
        <>
          <Flex key={index} mb="0.5vh">
            <ListItem fontSize="sm">
              <ListIcon as={CheckIcon} color="green.500" />
              {el.displayName}
            </ListItem>
          </Flex>
        </>
      );
    });


  return (
    <Box>
      {checkboxNodes.length > 0 ? (
        <>
          <Scroll>
            <List>{clickToSubmit ? confirmedSizesNodes : checkboxNodes}</List>
          </Scroll>
          <Flex pt={4}>
            {clickToSubmit ? (
              <Text fontSize="sm" fontWeight="bold">
                Selected Sizes
              </Text>
            ) : (
              <Flex>
                <InputSelectAll
                  type="checkbox"
                  name="selectAll"
                  id="selectAll"
                  onChange={handleSelectAll}
                  checked={isCheckAll}
                />
                <Text fontSize="sm" fontWeight="bold">
                  Select/Deselect All
                </Text>
              </Flex>
            )}
          </Flex>
        </>
      ) : (
        <Text pl={2}>No available sizes </Text>
      )}
    </Box>
  );
};

const Input = styled.input`
  margin-right: 0.5vw;
  accent-color: #0b252b;
  transform: scale(1.25);
`;

const InputSelectAll = styled.input`
  margin-right: 0.5vw;
  accent-color: #0b252b;
  transform: scale(1.5);
`;
const Scroll = styled.div`
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddeef0;
  height: 25vh;
  min-width: 10em;
  overflow-y: scroll;
`;
