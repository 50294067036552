const toastAlert = (toast, status, description) => {
  // toast status options = ['success', 'error', 'warning', 'info']
  const options = {
    success: { status: 'success', title: 'Success!' },
    error: { status: 'error', title: 'Error' },
  };

  return toast({
    title: options[status].title,
    description: description,
    status: options[status].status,
    position: 'top-right',
    containerStyle: { marginTop: '10vh', marginRight: '2vh' },
    duration: 4000,
    isClosable: true,
  });
};

export default toastAlert;
