import React, { useState } from 'react';
import {
  Flex,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

import ArtworkGroupTab from '../components/previews/ArtworkGroupTab';
import ThumbnailTab from '../components/previews/ThumbnailTab';
import Summary from '../components/Summary';

const Previews = ({
  handleUploadAlert,
  handleToggleOffAlert,
  markets,
  groupedFilesAndSizes,
  uploadType,
  isOpen,
  onClose,
  onOpen,
  token,
  artworkGroupTabsRemaining,
  sortingState,
  setRequestState,
}) => {
  const [disableThumbnail, setDisableThumbnail] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [multipleSelected, setMultipleSelected] = useState(false);

  const handleDisableTab = () => {
    handleToggleOffAlert();
    setTabIndex(tabIndex + 1);
  };

  const handleDisableThumbnail = () => {
    handleToggleOffAlert();
    setTabIndex(tabIndex + 1);
    setDisableThumbnail(true);
  };

  const completedTick = () => {
    return <CheckCircleIcon ml="0.5em" w={4} h={4} color="brand.100" />;
  };

  return (
    <Flex justifyContent="flex-start" direction="column" position="relative">
      {artworkGroupTabsRemaining !== tabIndex && (
        <>
          <Heading
            align="flex-start"
            color="colors.darkestTealGrey"
            fontWeight="normal"
            fontSize="1em"
          >
            Confirm artwork has mapped to the correct sides and select the
            required sizes. A Menu Theme will be created for each size you
            select.
          </Heading>
          <Tabs
            isFitted
            isLazy
            w={'90vw'}
            variant="enclosed-colored"
            colorscheme="blue"
            index={tabIndex}
            onChange={index => {
              setTabIndex(index);
            }}
          >
            <TabList mt="4vh">
              <Tab
                id="0"
                fontWeight="bold"
                fontSize="sm"
                isDisabled={disableThumbnail}
              >
                Thumbnail {disableThumbnail ? completedTick() : null}
              </Tab>
              {groupedFilesAndSizes.map((group, index) => {
                return (
                  <Tab isDisabled>
                    {group.groupNames}{' '}
                    {index < tabIndex - 1 ? completedTick() : null}
                  </Tab>
                );
              })}
            </TabList>
            <TabPanels>
              <TabPanel id="0">
                <ThumbnailTab
                  handleDisableThumbnail={handleDisableThumbnail}
                  pdf={sortingState.pdfs.Thumbnail}
                  title="Thumbnail"
                  handleUploadAlert={handleUploadAlert}
                  uploadType={uploadType}
                />
              </TabPanel>
              {groupedFilesAndSizes.map((group, index) => {
                return (
                  <TabPanel>
                    <ArtworkGroupTab
                      pdfs={group.pdfs}
                      plainPdfs={group.plainFiles}
                      ratio={group.menuOptions}
                      title={group.groupNames}
                      aspectRatio={group.aspectRatio}
                      tabIndex={tabIndex}
                      handleDisableTab={handleDisableTab}
                      handleUploadAlert={handleUploadAlert}
                      artworkGroupTabsRemaining={artworkGroupTabsRemaining}
                    />
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        </>
      )}
      {artworkGroupTabsRemaining === tabIndex ? (
        <Summary
          totalRatios={artworkGroupTabsRemaining - 1}
          markets={markets}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          token={token}
          setRequestState={setRequestState}
          multipleSelected={multipleSelected}
          setMultipleSelected={setMultipleSelected}
        />
      ) : null}
    </Flex>
  );
};

export default Previews;
