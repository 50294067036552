import { React, useEffect, useState, useRef } from 'react';
import {
  Flex,
  Box,
  FormLabel,
  HStack,
  Button,
  Text,
  List,
  ListItem,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

import { SketchPicker } from 'react-color';

import {
  FaAlignCenter,
  FaAlignLeft,
  FaAlignRight,
  FaAlignJustify,
} from 'react-icons/fa';
import {
  CloseIcon,
  AddIcon,
  MinusIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  WarningIcon,
} from '@chakra-ui/icons';

import { exclusiveFonts } from '../../../fonts';
import { FONTS } from '../../../fonts';

const FontBySection = ({
  setThemeDetails,
  textCategory,
  textColor,
  fontFamily,
  fontSize,
  align,
  selectedBrand,
  handleUpdateFontSize,
  handleUpdateFontFamily,
  handleUpdateFontColor,
}) => {
  const [brandedFonts, setBrandedFonts] = useState([]);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  const name = textCategory;
  const presetFontSizes = [
    6, 8, 10, 12, 14, 16, 18, 24, 30, 36, 42, 48, 56, 64, 72, 84, 96, 120, 144,
    172, 196,
  ];

  const emptyStateAlignment = {
    center: 'grey',
    justify: 'grey',
    left: 'grey',
    right: 'grey',
  };

  const [alignment, setAlignment] = useState(emptyStateAlignment);

  useEffect(() => {
    switch (align) {
      case 'center':
        setAlignment({
          ...emptyStateAlignment,
          center: 'teal',
        });
        break;
      case 'right':
        setAlignment({
          ...emptyStateAlignment,
          right: 'teal',
        });
        break;
      case 'left':
        setAlignment({
          ...emptyStateAlignment,
          left: 'teal',
        });
        break;
      case 'justify':
        setAlignment({
          ...emptyStateAlignment,
          justify: 'teal',
        });
        break;
      default:
        break;
    }
  }, [align]);

  useEffect(() => {
    exclusiveBrandFonts();
  }, []);

  const handleChangeFontColor = (color, isOpen) => {
    setIsColorPickerOpen(!isOpen);
    handleUpdateFontColor(name, color.hex);
  };

  const updateFontSize = fontSize => {
    handleUpdateFontSize(name, fontSize);
  };

  const handleSetAlignment = alignNew => {
    setAlignment({
      center: alignNew === 'center' ? 'teal' : 'grey',
      left: alignNew === 'left' ? 'teal' : 'grey',
      right: alignNew === 'right' ? 'teal' : 'grey',
      justify: alignNew === 'justify' ? 'teal' : 'grey',
    });
    setThemeDetails(themeDetails => ({
      ...themeDetails,
      pwaStyles: {
        ...themeDetails.pwaStyles,
        [name]: {
          ...themeDetails.pwaStyles[name],
          align: alignNew,
        },
      },
    }));
  };

  const handleFontSelect = font => {
    onClose();
    handleUpdateFontFamily(name, font);
  };

  const capitalizeFirstLetter = str => {
    const formattedStr = str.replace(/([A-Z])/g, ' $1');
    return formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);
  };

  const exclusiveBrandFonts = () => {
    const addedFonts = [];
    exclusiveFonts.forEach(font => {
      font.exclusiveBrandOrMarket.forEach(nestedItem => {
        if (nestedItem.brandId === selectedBrand[0]) {
          if (!brandedFonts.find(brand => brand.name === font.name)) {
            addedFonts.push(font.name);
          }
          setBrandedFonts(addedFonts);
        }
      });
    });
  };
  const fontFamilyNodes = FONTS.filter(
    font => font.exclusiveBrandOrMarket === undefined
  ).map((font, index) => {
    return (
      <ListItem
        key={index}
        onClick={() => {
          handleFontSelect(font.name);
        }}
      >
        <Text fontFamily={font.name}>
          {font.name}{' '}
          {font.pwaDisabled && <WarningIcon w={4} h={4} color="red.500" />}
        </Text>
      </ListItem>
    );
  });

  const exclusiveFontsNodes = brandedFonts.map(font => {
    return (
      <ListItem
        key={fontFamily}
        onClick={() => {
          handleFontSelect(font);
        }}
      >
        <Text fontFamily={font}>{font} </Text>
      </ListItem>
    );
  });

  return (
    <HStack mb="1rem">
      <FormLabel w="8rem" fontSize="xs" fontWeight="bold" mb={1}>
        {capitalizeFirstLetter(textCategory)}
      </FormLabel>
      <Button
        w="15rem"
        border="1px"
        borderColor="brand.700"
        ref={btnRef}
        onClick={onOpen}
        color={isOpen ? 'white' : 'colors.darkestTealGrey'}
        bgColor={isOpen ? 'brand.100' : 'white'}
        data-testid="font-family-dropdown"
        fontFamily={fontFamily}
        justifyContent="space-between"
        overflow="auto"
      >
        {fontFamily || 'Select font'}
        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        pr="1rem"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Fonts</DrawerHeader>

          <DrawerBody>
            <Text pb={3} fontWeight="bold">
              <WarningIcon w={4} h={4} color="red.500" /> Not available on Hyble
            </Text>

            <List w="20rem" defaultValue={fontFamily}>
              {brandedFonts.length >= 1 ? (
                <Flex direction="column">
                  <Text color="brand.100" fontWeight="bold">
                    Exclusive Brand Fonts:
                  </Text>
                  <Box h="1px" bgColor="colors.darkestTealGrey" mb="0.5rem" />
                  {exclusiveFontsNodes}
                  <Text mt="1rem" color="brand.100" fontWeight="bold">
                    Fonts:
                  </Text>
                  <Box h="1px" bgColor="colors.darkestTealGrey" mb="0.5rem" />
                  {fontFamilyNodes}
                </Flex>
              ) : (
                <Flex direction="column">{fontFamilyNodes}</Flex>
              )}
            </List>
          </DrawerBody>

          <DrawerFooter
            bgColor="colors.darkestTealGrey"
            justifyContent="flex-start"
          >
            <Flex alignItems="center">
              <Text fontSize="xs" color="white" mr="0.5rem">
                current:
              </Text>
              <Text fontWeight="bold" color="white">
                {fontFamily}
              </Text>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Flex ml="100px" align="center">
        <Button
          isDisabled={presetFontSizes.indexOf(fontSize) === 0 && true}
          data-testid="decrease-font-size"
          ml="0.5rem"
          bgColor="colors.darkestTealGrey"
          px="0.5rem"
          borderTopRightRadius="0px"
          borderBottomRightRadius="0px"
          onClick={() =>
            updateFontSize(
              presetFontSizes[
                Math.max(presetFontSizes.indexOf(fontSize) - 1, 0)
              ]
            )
          }
        >
          <MinusIcon color="white" w={3} />
        </Button>
        <Box
          w="3rem"
          h="40px"
          border="1px"
          borderColor="brand.700"
          py="0.5rem"
          align="center"
          m="0px"
          value={fontSize}
          data-testid="font-size-box"
        >
          <Text>{fontSize ? fontSize : 0}</Text>
        </Box>
        <Button
          isDisabled={presetFontSizes.indexOf(fontSize) === 20 && true}
          data-testid="increase-font-size"
          mr="0.5rem"
          bgColor="colors.darkestTealGrey"
          px="0.5rem"
          borderTopLeftRadius="0px"
          borderBottomLeftRadius="0px"
          onClick={() =>
            updateFontSize(
              presetFontSizes[
                Math.min(
                  presetFontSizes.indexOf(fontSize) + 1,
                  presetFontSizes.length - 1
                )
              ]
            )
          }
        >
          <AddIcon color="white" w={3} />
        </Button>
      </Flex>
      <Button
        w="2.5rem"
        h="2.5rem"
        bgColor={textColor ? textColor : 'white'}
        bgImage={
          !textColor &&
          'linear-gradient(to bottom right, transparent calc(50% - 0.5px), red, transparent calc(50% + 0.5px))'
        }
        _hover={{}}
        borderRadius="6px"
        border="1px solid"
        borderColor="black"
        onClick={() => setIsColorPickerOpen(true)}
        data-testid="text-color-button"
      />
      {isColorPickerOpen && (
        <Flex
          position="absolute"
          zIndex={100}
          bottom="5%"
          right="5%"
          p="1px"
          bgColor="colors.darkestTealGrey"
          borderRadius="6px"
        >
          <Box
            data-testid="test-find"
            top="0%"
            right="0%"
            position="absolute"
            display="inline-block"
            borderRadius="5px"
            bgColor="black"
            px="4px"
            zIndex={150}
            cursor="pointer"
          >
            <CloseIcon
              color="white"
              cursor="pointer"
              onClick={() => setIsColorPickerOpen(false)}
            />
          </Box>
          <SketchPicker
            disableAlpha={true}
            color={textColor}
            onChange={color => handleChangeFontColor(color)}
          />
        </Flex>
      )}
      <Box w="1rem" />
      <FaAlignLeft
        style={{ cursor: 'pointer' }}
        data-testid="align-left-icon"
        size="2rem"
        value="left"
        color={alignment.left}
        onClick={e => handleSetAlignment('left')}
      />
      <FaAlignCenter
        style={{ cursor: 'pointer' }}
        data-testid="align-center-icon"
        size="2rem"
        value="center"
        color={alignment.center}
        onClick={e => handleSetAlignment('center')}
      />
      <FaAlignRight
        style={{ cursor: 'pointer' }}
        data-testid="align-right-icon"
        size="2rem"
        value="right"
        color={alignment.right}
        onClick={e => handleSetAlignment('right')}
      />
      <FaAlignJustify
        style={{ cursor: 'pointer' }}
        data-testid="align-justify-icon"
        size="2rem"
        value="justify"
        color={alignment.justify}
        onClick={e => handleSetAlignment('justify')}
      />
    </HStack>
  );
};

export default FontBySection;

ListItem.defaultProps = {
  cursor: 'pointer',
  _hover: { backgroundColor: 'brand.100', color: 'white' },
  paddingY: '2px',
  paddingX: '8px',
  marginY: '4px',
  marginX: '-8px',
  borderRadius: '4px',
};
