import { API_HOST_THEME_STYLES } from "../../../../settings"
import { DEFAULT_THEME_DETAILS } from "../EditThemeModal";

export const getThemeDetails = async (token,
  themesToEdit,
  multipleSelected
) => {
  if (!multipleSelected && themesToEdit.length === 1) {
    const themeId = themesToEdit[0].id;
    const themeStyle = await GetThemeStyles(token, themeId);
    // Single theme edit (New & Existing)
    const { heading, subHeading, bodyText, legalText, product  } =
    themeStyle ?? {};

    return ({
      categories: themesToEdit[0]?.categories || [],
      pwaEnabled: themesToEdit[0]?.pwaEnabled || DEFAULT_THEME_DETAILS.pwaEnabled,
      templateTypeId: themesToEdit[0]?.templateTypeId || DEFAULT_THEME_DETAILS.templateTypeId,

      pwaStyles: {
        heading: heading,
        subHeading: subHeading,
        bodyText: bodyText,
        legalText: legalText,
        productDescription: product?.description,
        productPrice: product?.price,
        productTitle: product?.title,
      },
      stylesLoaded: true
    });
  } else {
    // Multiple theme edit (New & Existing)
    return DEFAULT_THEME_DETAILS;
  }
};

export async function GetThemeStyles(token, themeId) {
  const tokenMemo = await token;
  const STYLES_ENDPOINT = `${ API_HOST_THEME_STYLES }/${themeId}`;

  try{
    const styles = fetch(STYLES_ENDPOINT, {
      headers: {
        Authorization: `Bearer ${tokenMemo}`,
        'X-CLIENT-TYPE': 'Bespoke',
        'X-APP-LOCALE': 'en-GB',
      }
    }).then((response) => response.json())
    .then(({pwaStyles}) =>{
      
      return pwaStyles;
    });

    return styles;
  }catch(e){
    console.error(e);
  }
};