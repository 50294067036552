import React from 'react';
import { Flex, Link, Icon } from '@chakra-ui/react';
import { Heading, Text } from '@chakra-ui/react';
import { CheckCircleIcon, EditIcon, ExternalLinkIcon } from '@chakra-ui/icons';

import { usePayloadState } from '../AppPayloadContext';
import { PDS_SITE_LINK } from '../settings';
import EditThemeModal from './table/editTheme/EditThemeModal';
import { PrimaryButton } from '../_librabry/buttons/PrimaryButton';

const Summary = ({
  token,
  totalRatios,
  markets,
  isOpen,
  onClose,
  onOpen,
  setRequestState,
  multipleSelected,
  setMultipleSelected
}) => {
  const payloadState = usePayloadState();

  const filterMarketsByBrandId = marketObject => {
    return marketObject.id === payloadState.menuPayload.marketId;
  };

  const filteredMarket = markets.filter(filterMarketsByBrandId);

  return (
    <>
      <Flex direction="column" align="flex-start" mb="20px" maxW="50rem">
        <Heading fontSize="xl" mb={4}>
          <CheckCircleIcon color="brand.100" mr="10px" />
          Success!
        </Heading>
        <Text fontSize="l" mb={2}>
          {totalRatios} aspect ratios have been uploaded with theme name:
        </Text>
        <Text>
          <b>{payloadState.menuPayload.name}</b>
        </Text>
        <Text>
          for{' '}
          <b>
            {filteredMarket[0].brand.name}, {filteredMarket[0].name}
          </b>
        </Text>
        <Link
          isExternal
          href={PDS_SITE_LINK}
          textDecor={'underline'}
          textUnderlineOffset={1}
          mb={6}
          mt={2}
        >
          PDS Site
          <Icon ml={1} as={ExternalLinkIcon} />
        </Link>
        <PrimaryButton
          text={'Edit font styles and categories'}
          onClick={() => onOpen()}
          icon={EditIcon}
        />

        <EditThemeModal
          isOpen={isOpen}
          onClose={onClose}
          selectedBrand={[
            filteredMarket[0].brand.id,
            filteredMarket[0].brand.name,
          ]}
          selectedMarket={[filteredMarket[0].id, filteredMarket[0].name]}
          newTheme={true}
          token={token}
          setRequestState={setRequestState}
          multipleSelected={multipleSelected}
          setMultipleSelected={setMultipleSelected}
        />
      </Flex>
    </>
  );
};

export default Summary;
