export const fileNameChecker = (
  filesContent,
  setInvalidFiles,
  openInvalidFileModal,
  setFilesChecked,
  setHasThumbnail
) => {
  const sizeFormat = /Size_/g;
  const pageFormat = /Page /g;
  const thumbnail = /Thumbnail/g;

  const hasThumbnail = filesContent.some(file => file.name.match(thumbnail));

  setHasThumbnail(hasThumbnail);

  filesContent.forEach(file => {
    if (!hasThumbnail) {
      openInvalidFileModal();
    }
    if (!file.name.match(sizeFormat) || !file.name.match(pageFormat)) {
      setInvalidFiles(prevState => [...prevState, file]);
      openInvalidFileModal();
    }
    setFilesChecked(true);
  });
};
