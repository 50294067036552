import { Flex, Text } from '@chakra-ui/react';
import { Checkboxes } from './Checkboxes';

const CheckboxesContainer = ({
  pdfsLength,
  title,
  clickToSubmit,
  aspectRatio,
  ratio,
  dispatch,
  handleSetSizeIds,
}) => {
  return (
    <Flex direction={'column'} gap={4}>
      <Flex
        minW={300}
        direction={'column'}
        // boxShadow="0px 5px 4px -3px rgba(50, 50, 50, 0.25)"
        mb={2}
      >
        <Text fontWeight="bold" fontSize={'18px'}>
          {pdfsLength * 2} Artwork Files for {title}
        </Text>
        <Text fontSize={'14px'} mb={1}>
          (aspect ratio: {aspectRatio})
        </Text>
        <Text fontSize={'16px'}>
          {' '}
          {pdfsLength} PDFs + {pdfsLength} JPEGs
        </Text>
      </Flex>
      <Checkboxes
        clickToSubmit={clickToSubmit}
        ratio={ratio}
        dispatch={dispatch}
        handleSetSizeIds={handleSetSizeIds}
      />
    </Flex>
  );
};
export default CheckboxesContainer;
