import { Flex, useToast } from '@chakra-ui/react';

import ThemeView from '../components/table/themes/ThemeView';
import { React, useState, useEffect } from 'react';

import EditThemeModal from '../components/table/editTheme/EditThemeModal';
import SelectThemesBrandMarket from '../components/table/themes/SelectThemesBrandMarket';
import EditMultipleThemesButton from '../components/table/themes/EditMultiple';
import { getMarketMenuThemes } from '../services/menuThemesService';
import toastAlert from '../../src/components/alerts/ToastAlert';
import { usePayloadDispatch, usePayloadState } from '../AppPayloadContext';
import ACTIONS from '../actions/ReducerActions';

const DisplayThemes = ({
  brands,
  markets,
  token,
  isOpen,
  onClose,
  onOpen,
  requestState,
  setRequestState,
}) => {
  const [editThemeModal, setEditThemeModal] = useState(false);
  const [canEditMultiple, setCanEditMultiple] = useState(false);
  const [multipleSelected, setMultipleSelected] = useState(false);
  const toast = useToast();

  const dispatch = usePayloadDispatch();

  const themesToEdit = usePayloadState().themesToEdit;

  // TODO refactor in custom hooks style, with desctructured returns. Add useEffects into hooks - useGetMarketMenuThemes(token, requestState, setRequestState) // WIP
  useEffect(() => {
    if (requestState.selectedMarket && requestState.selectedMarket.length > 0) {
      setRequestState({
        ...requestState,
        isLoading: true,
      });
      getMarketMenuThemes(token, requestState)
        .then(res => {
          setRequestState(res);
        })
        .catch(error => {
          console.error('Error updating theme:', error);
          toastAlert(toast, 'error', `Error updating theme: ${error}`);
          setRequestState({
            ...requestState,
            isLoading: false,
          });
        });
    }
  }, [
    requestState.selectedMarket,
    token,
    requestState.timeStamp,
    requestState.offset,
  ]);

  // handlers ===========================================

  const handleOpenModal = () => {
    onOpen();
    setEditThemeModal(true);
    setCanEditMultiple(false);
  };

  const handleEditSingleTheme = theme => {
    dispatch({ type: ACTIONS.ADD_THEME_TO_EDIT_SINGLE, payload: theme });
    handleOpenModal();
  };

  const handleBrandChange = event => {
    setRequestState({
      ...requestState,
      themes: [],
      selectedBrand: event.target.value.split(','),
      selectedMarket: null,
    });
  };

  const handleMarketChange = event => {
    if (event.target.value !== '') {
      setRequestState({
        ...requestState,
        themes: [],
        isLoading: true,
        offset: 0,
        filters: {
          id: 0,
          name: '',
          aspectRatio: 0,
        },
        selectedMarket: event.target.value.split(','),
      });
    }
    dispatch({ type: 'CLEAR_THEMES_TO_EDIT' });
    // setThemesToEdit([]);
    setCanEditMultiple(false);
  };

  const handleLoadMoreMenus = () => {
    setRequestState(requestState => ({
      ...requestState,
      isLoading: true,
      offset: requestState.offset + 50,
    }));
  };

  const existingThemes =
    requestState.selectedMarket &&
    requestState.themes.length > 0 &&
    !requestState.isLoading;

  const showEditThemeModal = () => {
    return (
      editThemeModal &&
      requestState.selectedMarket &&
      requestState.selectedBrand &&
      themesToEdit.length > 0
    );
  };

  const RenderThemes = () => {
    if (!requestState.selectedMarket) {
      return null;
    }

    return (
      <ThemeView
        handleEditSingleTheme={handleEditSingleTheme}
        requestState={requestState}
        setRequestState={setRequestState}
        selectedBrand={requestState.selectedBrand}
        selectedMarket={requestState.selectedMarket}
        handleLoadMoreMenus={handleLoadMoreMenus}
        canEditMultiple={canEditMultiple}
        existingThemes={existingThemes}
        multipleSelected={multipleSelected}
        setMultipleSelected={setMultipleSelected}
      />
    );
  };

  return (
    <Flex
      bg="white"
      direction="column"
      width="100%"
      maxH="auto"
      borderRadius={10}
      p={4}
    >
      {showEditThemeModal() && (
        <EditThemeModal
          token={token}
          isOpen={isOpen}
          onClose={onClose}
          selectedBrand={requestState.selectedBrand}
          selectedMarket={requestState.selectedMarket}
          selectedFilters={requestState.filters}
          setRequestState={setRequestState}
          newTheme={false}
          multipleSelected={multipleSelected}
          setMultipleSelected={setMultipleSelected}
        />
      )}

      <Flex py={6} px={2} align={'flex-start'}>
        <SelectThemesBrandMarket
          brands={brands}
          markets={markets}
          selectedBrand={requestState.selectedBrand}
          selectedMarket={requestState.selectedMarket}
          handleBrandChange={handleBrandChange}
          handleMarketChange={handleMarketChange}
        />

        {requestState.themes.length > 0 && (
          <EditMultipleThemesButton
            canEditMultiple={canEditMultiple}
            setCanEditMultiple={setCanEditMultiple}
            handleOpenModal={handleOpenModal}
            multipleSelected={multipleSelected}
            setMultipleSelected={setMultipleSelected}
          />
        )}
      </Flex>

      {RenderThemes()}
    </Flex>
  );
};

export default DisplayThemes;
