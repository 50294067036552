import { useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
} from '@chakra-ui/react';

const SubCategories = ({
  availableSubcategories,
  themeDetails,
  setThemeDetails,
}) => {
  const [searchAvailable, setSearchAvailable] = useState('');
  const [searchSelected, setSearchSelected] = useState('');

  const sortSubcategoriesAlphabetically = (a, b) =>
    (a.categoryName + a.name).localeCompare(b.categoryName + b.name);

  const handleSelectChange = e => {
    if (e.target?.value) {
      const catObj = JSON.parse(e.target.value);
      if (!themeDetails.categories.find(option => option.id === catObj.id)) {
        setThemeDetails(themeDetails => ({
          ...themeDetails,
          categories: [...themeDetails.categories, catObj],
        }));
      }
    }
  };

  const handleDeselectChange = e => {
    if (e.target?.value) {
      const catObj = JSON.parse(e.target.value);
      const subcategoriesMinusDeselected = themeDetails.categories.filter(
        option => option.id !== catObj.id
      );
      setThemeDetails({
        ...themeDetails,
        categories: subcategoriesMinusDeselected,
      });
    }
  };

  const handleSelectAll = () => {
    const allUnselectedOptions = availableSubcategories.filter(
      availableOption =>
        !themeDetails.categories.find(
          selectedOpt => selectedOpt.id === availableOption.id
        ) &&
        (availableOption.categoryName.toUpperCase().includes(searchAvailable) ||
          availableOption.name.toUpperCase().includes(searchAvailable))
    );
    setThemeDetails({
      ...themeDetails,
      categories: [...themeDetails.categories, ...allUnselectedOptions],
    });
    setSearchAvailable('');
  };

  const handleDeselectAll = () => {
    const optionsToDeselectIds = themeDetails.categories
      .filter(
        option =>
          option.categoryName.toUpperCase().includes(searchSelected) ||
          option.name.toUpperCase().includes(searchSelected)
      )
      .map(option => option.id);

    setThemeDetails({
      ...themeDetails,
      categories: themeDetails.categories.filter(
        option => !optionsToDeselectIds.includes(option.id)
      ),
    });
    setSearchSelected('');
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Flex w="100%">
          <FormControl>
            <FormLabel color="gray">Available </FormLabel>
            <Input
              borderRadius={5}
              mb={3}
              size="sm"
              placeholder="Search"
              onChange={e => setSearchAvailable(e.target.value.toUpperCase())}
              data-testid={'available-searchbar'}
            />
            <Select icon={false} multiple minH="15vh">
              {availableSubcategories &&
                availableSubcategories
                  .sort(sortSubcategoriesAlphabetically)
                  .filter(
                    option =>
                      option.name.toUpperCase().includes(searchAvailable) ||
                      option.categoryName
                        .toUpperCase()
                        .includes(searchAvailable)
                  )
                  .map(option => (
                    <option
                      style={{
                        cursor: 'pointer',
                        marginTop: '0.25rem',
                        maxWidth: 'fit-content',
                      }}
                      key={option.id + option.name}
                      value={JSON.stringify(option)}
                      data-testid={`${option.id}`}
                      onClick={handleSelectChange}
                    >
                      {option.categoryName} - {option.name}
                    </option>
                  ))}
            </Select>
          </FormControl>
        </Flex>

        <ButtonGroup p={6}>
          <VStack>
            {searchAvailable === '' ? (
              <Button
                size="sm"
                onClick={handleSelectAll}
                data-testid={'select-all-button'}
              >
                Select All
              </Button>
            ) : (
              <Button py={6} size="sm" onClick={handleSelectAll}>
                Select All <br /> Results
              </Button>
            )}
            {searchSelected === '' ? (
              <Button p={2} size="sm" onClick={handleDeselectAll}>
                Deselect All
              </Button>
            ) : (
              <Button py={6} size="sm" onClick={handleDeselectAll}>
                Deselect All <br /> Results
              </Button>
            )}
          </VStack>
        </ButtonGroup>

        <Flex w="100%">
          <FormControl>
            <FormLabel color="gray">Selected</FormLabel>
            <Input
              borderRadius={5}
              mb={3}
              size="sm"
              placeholder="Search"
              onChange={e => setSearchSelected(e.target.value.toUpperCase())}
            />
            <Select
              icon={false}
              multiple
              minH="15vh"
              onClick={handleDeselectChange}
            >
              {themeDetails?.categories?.length > 0 &&
                themeDetails?.categories
                  .sort(sortSubcategoriesAlphabetically)
                  .filter(
                    option =>
                      option.name.toUpperCase().includes(searchSelected) ||
                      option.categoryName.toUpperCase().includes(searchSelected)
                  )
                  .map(option => (
                    <option
                      style={{
                        cursor: 'pointer',
                        marginTop: '0.25rem',
                        maxWidth: 'fit-content',
                      }}
                      key={option.id + option.name}
                      value={JSON.stringify(option)}
                      data-testid={`${option.id}`}
                    >
                      {option.categoryName} - {option.name}
                    </option>
                  ))}
            </Select>
          </FormControl>
        </Flex>
      </Box>
    </>
  );
};

export default SubCategories;
