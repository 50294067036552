import { API_HOST_MARKETS } from '../settings';
import { API_HOST_BRANDS } from '../settings';
import { API_HOST_MENU_OPTIONS } from '../settings';
import { API_HOST_PRODUCT_BRANDS } from '../settings';
import { API_HOST_POS_PRODUCTS, API_HOST_POS_SIZES } from '../settings';

// const API_HOST = process.env.REACT_APP_API_HOST || window.ENV_CONFIG.hosts.api;

export async function marketService(token) {
  try {
    const tokenMemo = await token;

    const response = await fetch(API_HOST_MARKETS, {
      headers: {
        Authorization: `Bearer ${tokenMemo}`,
        'X-CLIENT-TYPE': 'Bespoke',
        'X-APP-LOCALE': 'en-GB',
      },
    });

    return await response.json();
  } catch (e) {
    console.error(e);
  }
}

export async function brandService(token) {
  try {
    const tokenMemo = await token;

    const response = await fetch(API_HOST_BRANDS, {
      headers: {
        Authorization: `Bearer ${tokenMemo}`,
      },
    });

    return await response.json();
  } catch (e) {
    console.error(e);
  }
}

export async function menuSizeService(token) {
  try {
    const tokenMemo = await token;

    const response = await fetch(API_HOST_MENU_OPTIONS, {
      headers: {
        Authorization: `Bearer ${tokenMemo}`,
      },
    });

    return await response.json();
  } catch (e) {
    console.error(e);
  }
}

export async function productBrandsService(token) {
  try {
    const tokenMemo = await token;

    const response = await fetch(API_HOST_PRODUCT_BRANDS, {
      headers: {
        Authorization: `Bearer ${tokenMemo}`,
      },
    });

    return await response.json();
  } catch (e) {
    console.error(e);
  }
}

export async function posProductService(token) {
  try {
    const tokenMemo = await token;

    const response = await fetch(API_HOST_POS_PRODUCTS, {
      headers: {
        Authorization: `Bearer ${tokenMemo}`,
      },
    });

    return await response.json();
  } catch (e) {
    console.error(e);
  }
}

export async function posSizeService(token) {
  try {
    // const token = await getAccessTokenSilently({
    // audience: 'https://mrmservices',
    // });
    const tokenMemo = await token;

    const response = await fetch(API_HOST_POS_SIZES, {
      headers: {
        Authorization: `Bearer ${tokenMemo}`,
      },
    });

    return await response.json();
  } catch (e) {
    console.error(e);
  }
}
