import React from 'react';
import { Heading, Image, Text, Flex } from '@chakra-ui/react';

const ImagePreview = ({ fileName, src, pageNumber, size }) => {
  return (
    <Flex direction={'column'}>
      <Flex p="0.5vw" direction="column" color={'colors.darkestTealGrey'}>
        <Heading fontSize="18px" mb="0.25vh">
          {pageNumber}
        </Heading>
        <Text fontWeight="bold" fontSize="14px" mb="0.25vh">
          {size}
        </Text>
        <Text fontSize="12px" fontStyle="italic">
          <b>File name:</b> {fileName}
        </Text>
      </Flex>

      <Image
        objectFit="scale-down"
        w="100%"
        h={300}
        p="3px"
        bgColor="grey"
        src={src}
        mb="0.5vh"
        minW="19vw"
        bgGradient="radial(ellipse at center, #AFAFAF, #525252)"
      />
    </Flex>
  );
};

export default ImagePreview;
