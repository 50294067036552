import React from 'react';

export default function QuestionMark() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4668 10.5001C20.4668 16.0058 16.0042 20.4668 10.5001 20.4668C4.99606 20.4668 0.533447 16.0058 0.533447 10.5001C0.533447 4.99767 4.99606 0.533447 10.5001 0.533447C16.0042 0.533447 20.4668 4.99767 20.4668 10.5001ZM10.7676 3.82888C8.57743 3.82888 7.18057 4.75148 6.08368 6.39119C5.94157 6.60363 5.98911 6.89013 6.19279 7.04457L7.58728 8.10193C7.79646 8.26055 8.09449 8.22281 8.25701 8.01665C8.97493 7.10606 9.4672 6.57803 10.5599 6.57803C11.3809 6.57803 12.3964 7.10642 12.3964 7.90255C12.3964 8.50441 11.8996 8.8135 11.089 9.26799C10.1436 9.79795 8.89259 10.4576 8.89259 12.1076V12.2684C8.89259 12.5347 9.10852 12.7507 9.37485 12.7507H11.6254C11.8917 12.7507 12.1076 12.5347 12.1076 12.2684V12.2148C12.1076 11.071 15.4507 11.0234 15.4507 7.92807C15.4507 5.59708 13.0328 3.82888 10.7676 3.82888ZM10.5001 13.7955C9.48074 13.7955 8.65146 14.6248 8.65146 15.6442C8.65146 16.6635 9.48074 17.4929 10.5001 17.4929C11.5195 17.4929 12.3488 16.6635 12.3488 15.6442C12.3488 14.6248 11.5195 13.7955 10.5001 13.7955Z"
        fill="currentColor"
      />
    </svg>
  );
}
