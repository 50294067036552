import { calculateAspectRatio } from '../../actions/aspectRatioCalculator';
import { isEqual } from 'lodash';

const createFileAndSizeGroup = (
  pdfs,
  plainFiles,
  menuOptions,
  groupNames,
  aspectRatio
) => {
  return {
    pdfs,
    plainFiles,
    menuOptions,
    groupNames,
    aspectRatio,
  };
};

export const groupFilesWithMenuOptions = sortingState => {
  const groupedFilesAndSizes = [];
  sortingState.groupNames.forEach(name => {
    const pdfs = sortingState.pdfs[name];
    const plainFiles = sortingState.plainFiles[name];
    const menuOptions = sortingState.menuOptions[name];
    let calculatedAspectRatio = null;
    if (menuOptions?.length > 0) {
      calculatedAspectRatio = calculateAspectRatio(menuOptions[0]);
    }
    const newFileAndSizeGroup = createFileAndSizeGroup(
      pdfs,
      plainFiles,
      menuOptions,
      name,
      calculatedAspectRatio
    );

    if (!groupedFilesAndSizes.some(obj => isEqual(obj, newFileAndSizeGroup))) {
      groupedFilesAndSizes.push(newFileAndSizeGroup);
    }
  });
  return groupedFilesAndSizes;
};
