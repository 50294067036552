import React from 'react';
import ContentUploadContainer from './containers/ContentUploadContainer';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { PayloadProvider } from './AppPayloadContext';
import { body, colors } from './theme/theme';

function App() {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();


  const chakraTheme = extendTheme({
    styles: {
      global: {
        body,
      },
    },
    colors: {
      colors,
    },
    components: {
      FormLabel: {
        baseStyle: {
          fontWeight: 'bold',
          fontSize: '20px',
          color: 'colors.tealBlack',
          marginBottom: '12px',
        },
      },
    },
  });

  return (
    <>
      <PayloadProvider>
        <ChakraProvider theme={chakraTheme}>
          <BrowserRouter>
            {!isAuthenticated && !isLoading ? (
              loginWithRedirect()
            ) : (
              <Routes>
                <Route path="/" element={<ContentUploadContainer />} />
              </Routes>
            )}
          </BrowserRouter>
        </ChakraProvider>
      </PayloadProvider>
    </>
  );
}

export default App;
