import { React } from 'react';
import {
  Flex,
  HStack,
  Text,
  Checkbox,
  Image,
  RadioGroup,
  Radio,
  Stack,
} from '@chakra-ui/react';

import { DEFAULT_THEME_DETAILS } from './EditThemeModal';

import { DeleteIcon } from '@chakra-ui/icons';
import {
  usePayloadDispatch,
  usePayloadState,
} from '../../../AppPayloadContext';
import ACTIONS from '../../../actions/ReducerActions';

const MultipleSelectedThemes = ({
  themeDetails,
  selectedMarket,
  selectedBrand,
  handleEnablePWA,
  handleTemplateType,
}) => {
  const dispatch = usePayloadDispatch();
  const themesToEdit = usePayloadState().themesToEdit;

  return (
    <Flex direction="column">
      <Flex
        direction="column"
        maxH="12rem"
        maxW="48rem"
        overflow="auto"
        overflowX="scroll"
        overflowY="scroll"
        p="1rem"
        borderRadius="4px"
        border="1px"
        borderColor="brand.700"
      >
        {themesToEdit.map((theme, index) => {
          return (
            <HStack mt="0.5rem" spacing="1rem" fontSize="xs" key={index}>
              <Flex w="4rem" align="center" justify="space-between">
                <DeleteIcon
                  color="colors.darkestTealGrey"
                  size="md"
                  onClick={() => {
                    dispatch({
                      type: ACTIONS.REMOVE_THEME_TO_EDIT,
                      payload: theme.id,
                    });
                  }}
                />
                <Image
                  objectFit="contain"
                  bgColor="grey"
                  p="1px"
                  w="2rem"
                  h="2rem"
                  src={theme.frontCoverLowResUrl}
                />
              </Flex>
              <Text w="3rem">{theme.id}</Text>
              <Text w="13rem">{theme.name}</Text>
              <Text w="1.5rem">{theme.aspectRatio}</Text>
              <Text w="7rem">{selectedMarket[1]}</Text>
              <Text w="7rem">{selectedBrand[1]}</Text>
            </HStack>
          );
        })}
      </Flex>
      <Checkbox
        mt="0.6rem"
        colorScheme="teal"
        isChecked={
          themeDetails
            ? themeDetails?.pwaEnabled
            : DEFAULT_THEME_DETAILS.pwaEnabled
        }
        onChange={handleEnablePWA}
      >
        Show on hyble
      </Checkbox>

      <RadioGroup
        mt="0.6rem"
        colorScheme="teal"
        value={
          themeDetails?.templateTypeId || DEFAULT_THEME_DETAILS.templateTypeId
        }
        onChange={handleTemplateType}
        defaultChecked
      >
        <Stack spacing={3} direction="row">
          <Radio value={1}>Create Your Own</Radio>
          <Radio value={3}>Customisable</Radio>
          <Radio value={2}>Ready To Order</Radio>
        </Stack>
      </RadioGroup>
    </Flex>
  );
};

export default MultipleSelectedThemes;
