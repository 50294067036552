import React from 'react';
import { Center, Spinner } from '@chakra-ui/react';

const Loader = ({ size = 'xl', pt = '10', pb = '10' }) => {
  return (
    <Center pt={pt} pb={pb}>
      <Spinner
        thickness={size === 'xl' ? '4px' : '2px'}
        speed="0.65s"
        emptyColor="colors.darkTealGrey"
        color="colors.medTeal"
        size={size}
        data-testid={`loading-spinner`}
      />
    </Center>
  );
};

export default Loader;
