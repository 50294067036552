import React from 'react';

export function NoThemesFound() {
  return (
    <svg
      width="160"
      height="120"
      viewBox="0 0 160 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.7"
        d="M54.8074 37.9083C52.6813 37.4752 50.5288 37.1602 48.337 37.0158C38.0867 36.3596 26.2746 38.5252 18.4392 45.9406C6.19396 57.5164 8.79263 75.0903 16.628 88.2805C34.2543 117.942 99.6542 120.738 130.143 95.9059C136.797 90.4854 142.663 83.3456 146.207 75.4053C148.701 69.8142 149.921 63.5013 148.819 57.4902C145.144 37.4621 124.643 31.2016 107.555 36.0184C99.5492 38.2758 91.7794 41.6619 83.5372 42.5938C73.2081 43.7619 64.2703 39.7982 54.7812 37.8821L54.8074 37.9083Z"
        fill="url(#paint0_linear_512_4545)"
      />
      <path
        d="M25.3033 24.2851C25.3033 24.4557 25.1852 24.5607 25.0277 24.6394C24.2796 25.0069 23.5446 25.3744 22.7965 25.7419C22.6522 25.8075 22.5734 25.8994 22.5078 26.0307C22.1403 26.7788 21.7728 27.5137 21.4053 28.2487C21.3266 28.4062 21.2478 28.5374 21.0378 28.5374C20.841 28.5374 20.7622 28.4062 20.6835 28.2487C20.3029 27.5006 19.9354 26.7394 19.5547 25.9913C19.5023 25.8994 19.4104 25.8075 19.3185 25.755C18.5967 25.3875 17.8617 25.0201 17.1398 24.6526C16.9692 24.5738 16.7986 24.4951 16.7986 24.272C16.7986 24.0488 16.9692 23.9701 17.1398 23.8782C17.8748 23.5107 18.6098 23.1432 19.3316 22.7758C19.4235 22.7233 19.5154 22.6445 19.5547 22.5526C19.9354 21.8045 20.316 21.0564 20.6835 20.3083C20.7622 20.1508 20.8541 20.0327 21.051 20.0327C21.2478 20.0327 21.3397 20.1508 21.4184 20.3083C21.7859 21.0433 22.1534 21.7914 22.534 22.5264C22.5865 22.6314 22.6915 22.7364 22.7965 22.7889C23.5315 23.1695 24.2796 23.537 25.0277 23.9176C25.1721 23.9963 25.3033 24.0751 25.3164 24.272L25.3033 24.2851Z"
        stroke="#20C8DA"
        strokeWidth="1.26"
        strokeMiterlimit="10"
      />
      <path
        d="M108.657 94.8428C121.19 94.8428 131.35 84.6831 131.35 72.1504C131.35 59.6177 121.19 49.458 108.657 49.458C96.1248 49.458 85.9651 59.6177 85.9651 72.1504C85.9651 84.6831 96.1248 94.8428 108.657 94.8428Z"
        stroke="#21292D"
        strokeWidth="2.41"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.306 88.7662C103.552 88.4643 99.8904 86.8894 97.0161 84.0413C90.5194 77.6103 90.4538 67.0975 96.8848 60.6008C103.316 54.1041 113.829 54.0385 120.325 60.4696C126.822 66.9006 126.888 77.4134 120.457 83.9101C117.766 86.6269 114.367 88.2149 110.849 88.6743"
        stroke="#20C8DA"
        strokeWidth="2.09"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129.001 94.5409C127.99 95.5646 128.003 97.2052 129.027 98.2026L141.731 110.763C142.913 111.931 144.973 111.787 146.299 110.448L147.336 109.398C148.661 108.059 148.779 105.999 147.598 104.831L134.893 92.2703C133.87 91.2597 132.229 91.2729 131.232 92.2966L129.001 94.554V94.5409Z"
        stroke="#21292D"
        strokeWidth="2.41"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.312 88.6479L129.46 92.7035"
        stroke="#21292D"
        strokeWidth="2.41"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.6205 106.235C78.2804 106.235 79.626 104.889 79.626 103.229C79.626 101.57 78.2804 100.224 76.6205 100.224C74.9606 100.224 73.615 101.57 73.615 103.229C73.615 104.889 74.9606 106.235 76.6205 106.235Z"
        stroke="#21292D"
        strokeWidth="1.66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108.658 24.2982C110.18 24.2982 111.414 23.0642 111.414 21.5421C111.414 20.0199 110.18 18.7859 108.658 18.7859C107.135 18.7859 105.901 20.0199 105.901 21.5421C105.901 23.0642 107.135 24.2982 108.658 24.2982Z"
        stroke="#21292D"
        strokeWidth="1.52"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.396 43.4602C122.819 43.4602 122.36 43.9327 122.36 44.497V46.1376C122.36 46.7151 122.832 47.1745 123.396 47.1745C123.961 47.1745 124.433 46.702 124.433 46.1376V44.497C124.433 43.9196 123.961 43.4602 123.396 43.4602Z"
        fill="#21292D"
      />
      <path
        d="M123.396 41.5834C123.974 41.5834 124.433 41.1109 124.433 40.5466V38.906C124.433 38.3285 123.961 37.8691 123.396 37.8691C122.832 37.8691 122.36 38.3416 122.36 38.906V40.5466C122.36 41.124 122.832 41.5834 123.396 41.5834Z"
        fill="#21292D"
      />
      <path
        d="M127.019 41.4128H125.378C124.801 41.4128 124.342 41.8853 124.342 42.4497C124.342 43.014 124.814 43.4865 125.378 43.4865H127.019C127.596 43.4865 128.056 43.014 128.056 42.4497C128.056 41.8853 127.583 41.4128 127.019 41.4128Z"
        fill="#21292D"
      />
      <path
        d="M121.415 41.4128H119.774C119.197 41.4128 118.737 41.8853 118.737 42.4497C118.737 43.014 119.21 43.4865 119.774 43.4865H121.415C121.992 43.4865 122.452 43.014 122.452 42.4497C122.452 41.8853 121.979 41.4128 121.415 41.4128Z"
        fill="#21292D"
      />
      <path
        d="M51.4738 30.9129L53.5212 34.4697C53.6393 34.6665 53.8493 34.7978 54.0724 34.824L58.1673 35.1521C58.7448 35.2046 59.0204 35.8871 58.6398 36.3334L55.8968 39.3783C55.7393 39.5489 55.6868 39.7851 55.7393 40.0082L56.6974 43.9981C56.8286 44.5625 56.2642 45.0481 55.7261 44.8118L51.9856 43.145C51.7756 43.0532 51.5263 43.0663 51.3294 43.1844L47.8251 45.3237C47.3264 45.6256 46.6964 45.2318 46.762 44.6543L47.1951 40.5726C47.2214 40.3364 47.1295 40.1132 46.9589 39.9689L43.8484 37.3046C43.4021 36.924 43.5859 36.2021 44.1634 36.084L48.1795 35.2309C48.4026 35.1784 48.5995 35.0209 48.6782 34.8109L50.2532 31.0179C50.4763 30.4798 51.2244 30.4273 51.5131 30.9392L51.4738 30.9129Z"
        stroke="#20C8DA"
        strokeWidth="2.09"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.1322 53.356L79.1796 56.9128C79.2978 57.1096 79.5078 57.2409 79.7309 57.2671L83.8258 57.5953C84.4032 57.6478 84.6789 58.3302 84.2982 58.7765L81.5552 61.8214C81.3977 61.992 81.3452 62.2282 81.3977 62.4514L82.3558 66.4412C82.487 67.0056 81.9227 67.4912 81.3846 67.255L77.6441 65.5881C77.4341 65.4963 77.1847 65.5094 76.9878 65.6275L73.4836 67.7668C72.9848 68.0687 72.3549 67.6749 72.4205 67.0975L72.8536 63.0157C72.8798 62.7795 72.788 62.5564 72.6174 62.412L69.5068 59.7477C69.0606 59.3671 69.2443 58.6452 69.8218 58.5271L73.8379 57.674C74.0611 57.6215 74.2579 57.464 74.3367 57.254L75.9116 53.461C76.1347 52.9229 76.8828 52.8704 77.1716 53.3823L77.1322 53.356Z"
        stroke="#20C8DA"
        strokeWidth="2.09"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.3796 89.2387L36.5248 92.4411L31.3538 12.6305L93.7743 8.58813L96.268 44.497"
        stroke="#21292D"
        strokeWidth="2.41"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.8057 34.4435L82.0014 33.3936"
        stroke="#21292D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.3701 43.1845L76.6204 42.5151"
        stroke="#21292D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.9246 60.4695L60.9366 59.4326"
        stroke="#21292D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.4363 68.4231L55.5685 67.7668"
        stroke="#21292D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_512_4545"
          x1="127.268"
          y1="97.9062"
          x2="38.2494"
          y2="42.4467"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20C8DA" />
          <stop offset="1" stopColor="#00DE97" />
        </linearGradient>
      </defs>
    </svg>
  );
}
