import { API_HOST_THEMES } from '../settings';
import { AUTH0_AUDIENCE } from '../settings';

export async function uploadTheme(
  getAccessTokenSilently,
  payload,
  handleUploadAlert,
  title
) {
  try {
    const token = await getAccessTokenSilently({
      audience: AUTH0_AUDIENCE,
    });

    const response = await fetch(API_HOST_THEMES, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'X-CLIENT-TYPE': 'Bespoke',
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      handleUploadAlert(
        'success',
        `Success! ${payload.name} (${title}) has been uploaded.`
      );
    } else {
      handleUploadAlert(
        'error',
        `An error occurred when uploading ${payload.name} (${title}).`
      );
    }

    const json = await response.json();

    return json;
  } catch (e) {
    console.error(e);
  }
}
