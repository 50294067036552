import {
  Td,
  Tr,
  Box,
  Tag,
  Image,
  Popover,
  PopoverTrigger,
  PopoverBody,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Spacer,
  Flex,
  Text,
  textDecoration,
  UnorderedList,
  ListItem,
  PopoverHeader,
  List,
} from '@chakra-ui/react';
import { EditIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import React from 'react';
import {
  usePayloadDispatch,
  usePayloadState,
} from '../../../AppPayloadContext';
import ACTIONS from '../../../actions/ReducerActions';

const ThemeRows = ({
  requestState,
  handleEditSingleTheme,
  selectedBrand,
  selectedMarket,
  canEditMultiple,
}) => {
  const dispatch = usePayloadDispatch();
  const themesToEdit = usePayloadState().themesToEdit;

  const toggleThemeSelect = theme => {
    if (themesToEdit?.some(obj => obj.id === theme.id)) {
      dispatch({
        type: ACTIONS.REMOVE_THEME_TO_EDIT,
        payload: theme.id,
      });
    } else {
      dispatch({
        type: ACTIONS.ADD_THEME_TO_EDIT_MULTIPLE,
        payload: theme,
      });
    }
  };

  const themes = requestState.themes.map((theme, index) => {
    return (
      <Tr
        key={index + theme.name + theme.id}
        bgColor={
          themesToEdit.some(obj => obj.id === theme.id) && 'colors.lightestGrey'
        }
      >
        <Td>
          {canEditMultiple ? (
            <EditIcon
              _hover={{ cursor: 'pointer', color: 'colors.primary' }}
              color={
                themesToEdit.some(obj => obj.id === theme.id)
                  ? 'colors.medDarkTeal'
                  : 'black'
              }
              position="none"
              data-testid="multiSelectCheckbox"
              onClick={() => {
                toggleThemeSelect(theme);
              }}
            />
          ) : (
            <EditIcon
              data-testid="editIconButton"
              _hover={{ cursor: 'pointer' }}
              onClick={() => handleEditSingleTheme(theme)}
            />
          )}
        </Td>
        <Td w={0.5}> {theme.id}</Td>
        <Td>
          <Image
            maxW={10}
            maxH={10}
            src={theme.frontCoverLowResUrl}
            data-testid="preview"
            alt={'preview'}
          />
        </Td>
        <Td maxW="10rem">
          <Text noOfLines={3}>{theme.name}</Text>
        </Td>
        <Td maxW="3rem">{theme.aspectRatio}</Td>
        <Td>
          <Box maxW="10rem" maxH={12}>
            {theme.categories.length > 1 ? (
              <>
                <Flex>
                  <Tag>
                    {theme.categories[0].categoryName} -{' '}
                    {theme.categories[0].name}
                  </Tag>
                  <Popover trigger="hover">
                    <PopoverTrigger>
                      <Tag
                        bgColor="gray.400"
                        px={2}
                        fontWeight="bold"
                        _hover={{ cursor: 'pointer' }}
                      >
                        +{theme.categories.length - 1} more
                      </Tag>
                    </PopoverTrigger>

                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody>
                        {theme.categories.map((cat, index) => {
                          if (index !== 0) {
                            return (
                              <div key={index}>
                                <Tag>
                                  {cat.categoryName} - {cat.name}
                                </Tag>
                                <Spacer h="0.5rem" />
                              </div>
                            );
                          }
                        })}
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Flex>
              </>
            ) : theme.categories[0] ? (
              <Tag>
                {theme.categories[0]?.categoryName}-{' '}
                {theme?.categories[0]?.name}
              </Tag>
            ) : (
              <Tag fontWeight="bold" variant="outline" bgColor="white">
                None Selected!
              </Tag>
            )}
          </Box>
       </Td>
            <Td minW={"12rem"} maxW={"12rem"}>
                <text>{theme.templateType}</text>
            </Td>
            <Td minW={"10rem"} maxW="{15rem}">
                <Popover trigger="hover" placement="right">
                  <PopoverTrigger>
                    <UnorderedList styleType={'none'} pl={"0rem"} ml={"0rem"}>                  
                      <ListItem  _hover={{ cursor: 'pointer' }}>{theme.formats[0]}</ListItem>
                      <ListItem  _hover={{ cursor: 'pointer' }}>{theme.formats[1]}</ListItem>
                      <ListItem  _hover={{ cursor: 'pointer' }}>{theme.formats[2]}</ListItem>
                    </UnorderedList>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverHeader>{theme.name}</PopoverHeader>
                    <PopoverBody>
                      <PopoverBody>
                        <UnorderedList styleType={'none'} >
                        {theme.formats.map((format, index) => {
                            return (
                              <div key={index}>
                                <ListItem  _hover={{ cursor: 'pointer' }}>
                                  {format}
                                </ListItem>
                              </div>
                            );
                        })}
                        </UnorderedList>
                      </PopoverBody>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
                
            </Td>
      <Td maxW={"3rem"}>
        <div>{theme.pwaEnabled && (<CheckIcon color={'green'}/>)}</div>
        {!theme.pwaEnabled && (<CloseIcon color={'red'}/>)}
      </Td>
        <Td maxW="7rem">
          <Text noOfLines={2}>{selectedBrand && selectedBrand[1]}</Text>
        </Td>
        <Td maxW="7rem">
          <Text noOfLines={2}>{selectedMarket && selectedMarket[1]}</Text>
        </Td>
      </Tr>
    );
  });

  return themes;
};

export default ThemeRows;

Td.defaultProps = {
  py: 1,
  m: 0,
};

Tag.defaultProps = {
  variant: 'solid',
  m: 1,
  py: 1,
  px: 1.5,
  width: 'fit-content',
  bgColor: 'gray.500',
  fontWeight: 'semibold',
};
