import React, { useState, useEffect } from 'react';
import { Text, Flex, Box, Button, List } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { CheckCircleIcon } from '@chakra-ui/icons';

export const PosCheckboxes = ({
  handleDisableNextButton,
  productGroupSizes,
  handlePostDesign,
  finalPayloadSent,
}) => {
  const [list, setList] = useState([]);
  const [sendComplete, setSendComplete] = useState([]);
  const [sizeIDs, setSizeIDs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setList(productGroupSizes);
  }, [productGroupSizes]);

  useEffect(() => {
    handleSendComplete();
  }, [finalPayloadSent]);

  const handleSendComplete = () => {
    if (
      finalPayloadSent !== null &&
      sizeIDs.includes(finalPayloadSent.sizeId)
    ) {
      setSendComplete(sendComplete => [
        ...sendComplete,
        finalPayloadSent.sizeId,
      ]);
      setLoading(false);
      handleDisableNextButton();
      return;
    } else return;
  };

  const handleSelectSize = (e, size) => {
    handlePostDesign(e);
    handleDisableNextButton();
    setLoading(true);
    setSizeIDs(sizeIDs => [...sizeIDs, size.id]);
  };

  useEffect(() => {
    setList(productGroupSizes);
  }, [productGroupSizes]);

  const sizeNodes = list
    .sort((a, b) => a.productName.localeCompare(b.productName))
    .map((size, id, index) => {
      return (
        <>
          <Flex key={index} mb={4} align="center" mt="1vh" gap={3}>
            {!sendComplete.includes(size.id) ? (
              <Button
                size="sm"
                bg="colors.darkestTealGrey"
                color="white"
                borderRadius={'999px'}
                p={4}
                disabled={loading}
                onClick={e => handleSelectSize(e, size)}
                isLoading={
                  sizeIDs.includes(size.id) && !sendComplete.includes(size.id)
                    ? true
                    : false
                }
                value={[size.id, size.displayName]}
                productName={size.productName}
              >
                Add
              </Button>
            ) : (
              <CheckCircleIcon
                w={5}
                h={5}
                mt="1.5"
                mb="1.5"
                ml="1.6em"
                mr="1.65em"
                color="brand.100"
              />
            )}
            <Text fontWeight="bold" fontSize="md">
              {size.productName.replace('pos.products.', '')} -{' '}
              {size.displayName.replace('pos.sizes.', '')}
            </Text>
          </Flex>
        </>
      );
    });

  return (
    <Box>
      <Scroll>
        <List>{sizeNodes}</List>
      </Scroll>
    </Box>
  );
};

const Scroll = styled.div`
  max-height: 10em;
  max-width: 20em;
  overflow-y: scroll;
  border: 0.25px ridge, #ddeef0;
`;
