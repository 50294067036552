import { React, useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Flex,
  Text,
  Box,
  Popover,
  PopoverTrigger,
  PopoverBody,
  PopoverContent,
  Portal,
  PopoverArrow,
  PopoverHeader,
  Spinner,
} from '@chakra-ui/react';
import SubCategories from './SubCategories.js';
import toastAlert from '../../../components/alerts/ToastAlert.js';
import FontStyles from './FontStyles.js';
import SingleSelectedTheme from './SingleSelectedTheme.js';
import MultipleSelectedThemes from './MultipleSelectedThemes.js';
import { restructurePayload } from './editThemeActions/restructurePayload.js';
import { updateMenuTheme } from '../../../services/menuThemesService.js';
import { useGetMarketSubcategories } from '../../../api/useGetMarketSubcategories.ts';
import { getThemeDetails } from './editThemeActions/getThemeDetails.js';
import { getMarketMenuThemes } from '../../../services/menuThemesService.js';
import { PrimaryButton } from '../../../_librabry/buttons/PrimaryButton.js';
import { showSuccessMessage } from './editThemeActions/showSuccessMessage.js';
import {
  usePayloadDispatch,
  usePayloadState,
} from '../../../AppPayloadContext.js';
import ACTIONS from '../../../actions/ReducerActions.js';
import { useToast } from '@chakra-ui/react';

const ALL_THEMES_ID = 0;

export const DEFAULT_THEME_DETAILS = {
  categories: [],
  stylesLoaded: false,
  pwaStyles: {},
  templateTypeId: 1,
  pwaEnabled: false,
};

const EditThemeModal = ({
  token,
  isOpen,
  onClose: closeEditModal,
  selectedBrand,
  selectedMarket,
  selectedFilters,
  setRequestState,
  newTheme,
  multipleSelected,
  setMultipleSelected
}) => {
  const dispatch = usePayloadDispatch();

  const themesToEdit = usePayloadState().themesToEdit;
  const toast = useToast();

  const [themeDetails, setThemeDetails] = useState(DEFAULT_THEME_DETAILS);
  
  const selectedMarketId = selectedMarket[0] || null;
  const {
    data: subCategories,
    isLoading: subCategoriesLoading,
    error: subCategoriesError,
  } = useGetMarketSubcategories(token, selectedMarketId);

  useEffect(() => {
    getThemeDetails(token, themesToEdit, multipleSelected)
    .then(setThemeDetails);
  }, [themesToEdit.length, newTheme, selectedMarket, multipleSelected]);

  const handleEnablePWA = () => {
    setThemeDetails(themeDetails => ({
      ...themeDetails,
      pwaEnabled: !themeDetails.pwaEnabled,
    }));
  };

  const handleTemplateType = templateType => {
    setThemeDetails(themeDetails => ({
      ...themeDetails,
      templateTypeId: Number(templateType),
    }));
  };

  const updateTheme = async (id, pwaEnabled, templateTypeId) => {
    const restructuredThemeDetails = restructurePayload(themeDetails);
    const payloadWithUniqueValues = {
      id: id,
      pwaEnabled: pwaEnabled,
      templateTypeId: templateTypeId,
      ...restructuredThemeDetails,
    };

    return await updateMenuTheme(token, payloadWithUniqueValues);
  };

  // This method is used by the app to update the payload of web requests
  const updateRequestState = (themeId = ALL_THEMES_ID) => {
    const updatedRequestState = {
      selectedMarket: [selectedMarket[0]],
      offset: 0,
      timeStamp: new Date().getSeconds(),
      filters: {
        ...selectedFilters,
        id: newTheme ? themeId : selectedFilters.id,
      },
    };
    setRequestState(prevState => ({
      ...prevState,
      ...updatedRequestState,
    }));

    return updatedRequestState;
  };

  const getUpdatedNewTheme = async () => {
    try {
      const updatedRequestState = updateRequestState(themesToEdit[0]?.id);

      const updatedTheme = await getMarketMenuThemes(
        token,
        updatedRequestState
      );

      dispatch({
        type: ACTIONS.ADD_THEME_TO_EDIT_MULTIPLE,
        payload: updatedTheme.themes[0],
      });
    } catch (error) {
      console.error(error);
      toastAlert(toast, 'error', `Error fetching updated theme: ${error}`);
    }
  };

  const triggerGetAllThemes = () => {
    dispatch({ type: ACTIONS.CLEAR_THEMES_TO_EDIT });
    updateRequestState(ALL_THEMES_ID);
  };

  const handleThemeEdit = async () => {
    try {
      setMultipleSelected(false);
      closeEditModal();

      // Send PUT request to update one or multiple themes
      for (const theme of themesToEdit) {
        await updateTheme(
          theme.id,
          themeDetails.pwaEnabled,
          themeDetails.templateTypeId
        );
      }

      // If one new theme has been edited, send a GET request to get the updated version
      if (newTheme && themesToEdit.length === 1) {
        // !!! ADDED && themesToEdit.length === 1
        await getUpdatedNewTheme();
      } else {
        // If one or multiple existing themes have been edited, trigger a refresh of the screen to get ALL themes (including the updated ones)
        triggerGetAllThemes();
      }

      showSuccessMessage(themesToEdit, toast);
    } catch (error) {
      console.error(
        `Error updating ${themesToEdit.length > 1 ? 'themes' : 'theme'}:`,
        error
      );
      toastAlert(
        toast,
        'error',
        `Error updating ${themesToEdit.length > 1 ? 'themes' : 'theme'}:`
      );
    }
  };

  const handleDiscardChanges = () => {
    setMultipleSelected(false);
    closeEditModal();
    setThemeDetails(DEFAULT_THEME_DETAILS);

    // Existing theme/s - Reset form data to null
    if (!newTheme) {
      dispatch({ type: ACTIONS.CLEAR_THEMES_TO_EDIT });
    }
  };

  if (!themesToEdit) return null;

  if (subCategoriesError)
    return toastAlert(
      toast,
      'error',
      'Problem loading modal - Unable to fetch subcategories'
    );

  const showFontStyles =
    themesToEdit.length > 1 ||
    (themesToEdit.length === 1 && themeDetails.templateTypeId === 1);

  return (
    <>
      <Modal isOpen={isOpen} zIndex={1}>
        <ModalOverlay />
        <ModalContent
          maxWidth="840px"
          backgroundColor="charcoal.700"
          height="85%"
          borderRadius="12px"
          p="2rem"
          overflow="auto"
          overflowX="scroll"
          zIndex={2}
        >
          <ModalHeader
            alignSelf="center"
            fontSize="2xl"
            color="brand.100"
            fontWeight="bold"
          >
            {themesToEdit.length > 1 ? 'Edit Multiple Themes' : 'Edit Theme'}
          </ModalHeader>
          <ModalBody>
            <Flex
              mb="2rem"
              justify={themesToEdit.length > 1 ? 'center' : 'space-between'}
              align="center"
            >
              {themesToEdit.length > 1 ? (
                <MultipleSelectedThemes
                  themeDetails={themeDetails}
                  selectedMarket={selectedMarket}
                  selectedBrand={selectedBrand}
                  handleEnablePWA={handleEnablePWA}
                  handleTemplateType={handleTemplateType}
                />
              ) : (
                <SingleSelectedTheme
                  themeDetails={themeDetails}
                  selectedMarket={selectedMarket}
                  selectedBrand={selectedBrand}
                  handleEnablePWA={handleEnablePWA}
                  handleTemplateType={handleTemplateType}
                />
              )}
            </Flex>

            <Flex pb={'1rem'}>
              <Text w="8rem" fontSize="xs" fontWeight="bold" mb={1}>
                Subcategories
              </Text>
              {!subCategories || subCategoriesLoading ? (
                <Spinner />
              ) : (
                <SubCategories
                  themeDetails={themeDetails}
                  setThemeDetails={setThemeDetails}
                  selectedMarketId={selectedMarketId}
                  token={token}
                  availableSubcategories={subCategories}
                />
              )}
            </Flex>
            {!multipleSelected && !newTheme
              && !themeDetails.stylesLoaded ? (
              <Spinner />
            ) : showFontStyles ? (
              <FontStyles
                themeDetails={themeDetails}
                setThemeDetails={setThemeDetails}
                selectedBrand={selectedBrand}
                selectedMarket={selectedMarket}
              />
            ): null}
            
          </ModalBody>

          <ModalFooter>
            <Flex gap={4}>
              <Popover>
                <PopoverTrigger>
                  <Button
                    variant="outline"
                    borderRadius={'999px'}
                    border={'1px'}
                    borderColor={'black'}
                    color={'black'}
                    _hover={{ bg: 'colors.lightestGrey' }}
                  >
                    Discard changes
                  </Button>
                </PopoverTrigger>
                <Portal>
                  <Box
                    sx={{ '& .chakra-popover__popper': { zIndex: 'popover' } }}
                  >
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverHeader>
                        Are you sure? Any unsaved changes will be lost
                      </PopoverHeader>
                      <PopoverBody>
                        <Button
                          bg="red"
                          color="white"
                          _hover={{ bg: '#D9D9D9' }}
                          width="fit-content"
                          onClick={handleDiscardChanges}
                        >
                          Confirm
                        </Button>
                      </PopoverBody>
                    </PopoverContent>
                  </Box>
                </Portal>
              </Popover>
              <PrimaryButton text={'Save Theme'} onClick={handleThemeEdit} />
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditThemeModal;
