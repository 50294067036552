// re-maps payload prior to PUT to meet endpoint requirements
export const restructurePayload = themeDetails => {
  const { productDescription, productPrice, productTitle, ...pwaStylesRest } =
    themeDetails.pwaStyles;

  return {
    categoryIds: themeDetails.categories.map(cat => cat.id),
    pwaStyles: {
      ...pwaStylesRest,
      product: {
        ...(productDescription !== undefined && {
          description: productDescription,
        }),
        ...(productPrice !== undefined && { price: productPrice }),
        ...(productTitle !== undefined && { title: productTitle }),
      },
    },
  };
};
