// Combination of useReducer & useContext. Example 5 from React docs was the basis for this: https://beta.reactjs.org/apis/react/useContext

// We create 2 contexts to provide access to both the payload state and dispatch - PayloadContext & PayloadDispatchContext

import { createContext, useContext, useReducer } from 'react';
import ACTIONS from './actions/ReducerActions';

export const PayloadContext = createContext(null);
export const PayloadDispatchContext = createContext(null);

export function PayloadProvider({ children }) {
  const [state, dispatch] = useReducer(reducerFunction, initialState);

  return (
    <PayloadContext.Provider value={state}>
      <PayloadDispatchContext.Provider value={dispatch}>
        {children}
      </PayloadDispatchContext.Provider>
    </PayloadContext.Provider>
  );
}

export function usePayloadState() {
  return useContext(PayloadContext);
}

export function usePayloadDispatch() {
  return useContext(PayloadDispatchContext);
}

const reducerFunction = (state, action) => {
  switch (action.type) {
    //  MENU ACTIONS ===================

    case ACTIONS.ADD_MARKET_ID:
      return {
        ...state,
        menuPayload: {
          ...state.menuPayload,
          marketId: Number(action.payload),
          enabledMarketIds: [Number(action.payload)],
        },
      };
    case ACTIONS.ADD_THEME_NAME:
      return {
        ...state,
        menuPayload: { ...state.menuPayload, name: action.payload },
      };
    case ACTIONS.ADD_MENU_SIZE_IDS:
      return {
        ...state,
        menuPayload: {
          ...state.menuPayload,
          enabledMenuSizeIds: action.payload,
        },
      };
    case ACTIONS.ADD_ASPECT_RATIO:
      return {
        ...state,
        menuPayload: { ...state.menuPayload, aspectRatio: action.payload },
      };
    case ACTIONS.ADD_TAG:
      return {
        ...state,
        menuPayload: { ...state.menuPayload, tags: [action.payload] },
      };
    case ACTIONS.ADD_THUMBNAIL_REF:
      return {
        ...state,
        menuPayload: {
          ...state.menuPayload,
          thumbnailFileReference: action.payload,
        },
      };
    case ACTIONS.ADD_FRONT_COVER_HIGH_RES_REF:
      return {
        ...state,
        menuPayload: {
          ...state.menuPayload,
          frontCoverHighResFileReference: action.payload,
        },
      };
    case ACTIONS.ADD_FRONT_COVER_LOW_RES_REF:
      return {
        ...state,
        menuPayload: {
          ...state.menuPayload,
          frontCoverLowResFileReference: action.payload,
        },
      };
    case ACTIONS.ADD_BACK_COVER_HIGH_RES_REF:
      return {
        ...state,
        menuPayload: {
          ...state.menuPayload,
          backCoverHighResFileReference: action.payload,
        },
      };
    case ACTIONS.ADD_BACK_COVER_LOW_RES_REF:
      return {
        ...state,
        menuPayload: {
          ...state.menuPayload,
          backCoverLowResFileReference: action.payload,
        },
      };
    case ACTIONS.ADD_CONTENT_PAGE_HIGH_RES_REF:
      return {
        ...state,
        menuPayload: {
          ...state.menuPayload,
          contentPageHighResFileReference: action.payload,
        },
      };
    case ACTIONS.ADD_CONTENT_PAGE_LOW_RES_REF:
      return {
        ...state,
        menuPayload: {
          ...state.menuPayload,
          contentPageLowResFileReference: action.payload,
        },
      };
    case ACTIONS.CLEAR_MENU_REFERENCE_OBJECTS:
      return {
        ...state,
        menuPayload: {
          ...state.menuPayload,
          frontCoverLowResFileReference: null,
          backCoverLowResFileReference: null,
          contentPageLowResFileReference: null,
          contentPageHighResFileReference: null,
          backCoverHighResFileReference: null,
          frontCoverHighResFileReference: null,
        },
      };
    case ACTIONS.ADD_PRODUCT_BRAND_ID:
      return {
        ...state,
        menuPayload: { ...state.menuPayload, productBrandId: action.payload },
      };

    //  POS ACTIONS ===================

    case ACTIONS.ADD_POS_THEME_ID:
      return {
        ...state,
        posPayload: { ...state.posPayload, id: action.payload },

        posSide1Payload: { ...state.posSide1Payload, designId: action.payload },
        posSide2Payload: { ...state.posSide2Payload, designId: action.payload },
        posSide3Payload: { ...state.posSide3Payload, designId: action.payload },
      };
    case ACTIONS.ADD_POS_THEME_NAME:
      return {
        ...state,
        posSkeleton: { ...state.posSkeleton, name: action.payload },
        posPayload: { ...state.posPayload, name: action.payload },
      };
    case ACTIONS.ADD_POS_SIZE_ID:
      return {
        ...state,
        posSkeleton: { ...state.posSkeleton, sizeId: action.payload },
        posPayload: { ...state.posPayload, sizeId: action.payload },
      };
    case ACTIONS.ADD_POS_PREVIEW_URL:
      return {
        ...state,
        posSkeleton: { ...state.posSkeleton, previewUrl: action.payload },
        posPayload: { ...state.posPayload, previewUrl: action.payload },
      };
    case ACTIONS.ADD_POS_SIZE_NAME:
      return {
        ...state,
        posPayload: { ...state.posPayload, sizeName: action.payload },
      };
    case ACTIONS.ADD_POS_PRODUCT_NAME:
      return {
        ...state,
        posPayload: { ...state.posPayload, productName: action.payload },
      };
    case ACTIONS.ADD_POS_MARKET_ID:
      return {
        ...state,
        posSkeleton: { ...state.posSkeleton, marketId: action.payload },
        posPayload: {
          ...state.posPayload,
          marketId: action.payload,
          enabledMarketIds: [action.payload],
        },
      };
    case ACTIONS.ADD_POS_MARKET_NAME:
      return {
        ...state,
        posPayload: { ...state.posPayload, marketName: action.payload },
      };
    case ACTIONS.ADD_POS_BRAND_ID:
      return {
        ...state,
        posSkeleton: { ...state.posSkeleton, brandId: action.payload },
      };
    case ACTIONS.ADD_POS_BRAND_NAME:
      return {
        ...state,
        posPayload: { ...state.posPayload, brandName: action.payload },
      };

    case ACTIONS.ADD_POS_PRODUCT_BRAND_ID:
      return {
        ...state,
        posSkeleton: { ...state.posSkeleton, productBrandId: action.payload },
        posPayload: { ...state.posPayload, productBrandId: action.payload },
      };
    case ACTIONS.ADD_POS_TAG:
      return {
        ...state,
        posSkeleton: { ...state.posSkeleton, tags: action.payload },
        posPayload: { ...state.posPayload, tags: action.payload },
      };
    case ACTIONS.ADD_POS_MARGIN:
      return {
        ...state,
        posSkeleton: {
          ...state.posSkeleton,
          marginTop: action.payload,
          marginBottom: action.payload,
          marginLeft: action.payload,
          marginRight: action.payload,
        },
        posPayload: {
          ...state.posPayload,
          marginTop: action.payload,
          marginBottom: action.payload,
          marginLeft: action.payload,
          marginRight: action.payload,
        },
      };

    // POS Sides  ===================

    case ACTIONS.ADD_POS_SIDE_1_PREVIEW_URL:
      return {
        ...state,
        posSide1Payload: {
          ...state.posSide1Payload,
          previewUrl: action.payload,
        },
      };
    case ACTIONS.ADD_POS_SIDE_1_PRINT_URL:
      return {
        ...state,
        posSide1Payload: { ...state.posSide1Payload, printUrl: action.payload },
      };
    case ACTIONS.ADD_POS_SIDE_2_PREVIEW_URL:
      return {
        ...state,
        posSide2Payload: {
          ...state.posSide2Payload,
          previewUrl: action.payload,
        },
      };
    case ACTIONS.ADD_POS_SIDE_2_PRINT_URL:
      return {
        ...state,
        posSide2Payload: { ...state.posSide2Payload, printUrl: action.payload },
      };
    case ACTIONS.ADD_POS_SIDE_3_PREVIEW_URL:
      return {
        ...state,
        posSide3Payload: {
          ...state.posSide3Payload,
          previewUrl: action.payload,
        },
      };
    case ACTIONS.ADD_POS_SIDE_3_PRINT_URL:
      return {
        ...state,
        posSide3Payload: { ...state.posSide3Payload, printUrl: action.payload },
      };
    case ACTIONS.ADD_POS_SIDE_TO_FINAL_PAYLOAD:
      return {
        ...state,
        posPayload: {
          ...state.posPayload,
          sides: [...state.posPayload.sides, action.payload],
        },
      };

    case ACTIONS.CLEAR_POS_VALUES:
      return {
        ...state,
        posSkeleton: {
          ...state.posSkeleton,
          sizeId: '',
        },

        posPayload: {
          ...state.posPayload,
          id: null,
          sizeName: '',
          sizeId: null,
          sides: [],
        },

        posSide1Payload: {
          ...state.posSide1Payload,
          designId: null,
        },
        posSide2Payload: {
          ...state.posSide2Payload,
          designId: null,
        },
        posSide3Payload: {
          ...state.posSide3Payload,
          designId: null,
        },
      };
    case ACTIONS.CLEAR_POS_VALUES_AND_ARTWORK:
      return {
        ...state,
        posSkeleton: {
          ...state.posSkeleton,
          sizeId: '',
        },

        posPayload: {
          ...state.posPayload,
          id: null,
          sizeName: '',
          sizeId: null,
          sides: [],
        },

        posSide1Payload: {
          ...state.posSide1Payload,
          designId: null,
          previewUrl: null,
          printUrl: null,
        },
        posSide2Payload: {
          ...state.posSide2Payload,
          designId: null,
          previewUrl: null,
          printUrl: null,
        },
        posSide3Payload: {
          ...state.posSide3Payload,
          designId: null,
          previewUrl: null,
          printUrl: null,
        },
      };

    // EDIT THEMES ACTIONS ===================
    case ACTIONS.ADD_THEME_TO_EDIT_MULTIPLE:
      return {
        ...state,
        themesToEdit: [...state.themesToEdit, action.payload],
      };

    case ACTIONS.ADD_THEME_TO_EDIT_SINGLE:
      return {
        ...state,
        themesToEdit: [action.payload],
      };

    case ACTIONS.REMOVE_THEME_TO_EDIT:
      return {
        ...state,
        themesToEdit: state.themesToEdit.filter(
          theme => theme.id !== action.payload
        ),
      };

    case ACTIONS.CLEAR_THEMES_TO_EDIT:
      return {
        ...state,
        themesToEdit: [],
      };

    default:
      return state;
  }
};

const initialState = {
  // MODIFIED
  menuPayload: {
    name: '',
    tags: [],
    marketId: '',
    // fixed (for now)
    defaultStyle: '{}',
    // MODIFIED
    enabledMenuSizeIds: [],
    aspectRatio: null,
    // fixed
    isBespokeTemplate: false,
    enabled: true,
    bespokeTemplateDiscountPercentage: '0',
    // MODIFIED
    enabledMarketIds: [],
    // fixed
    quickListName: '',
    deleteBackImages: false,
    deleteLeftImages: false,
    deleteRightImages: false,

    // MODIFIED
    thumbnailFileReference: null,
    frontCoverLowResFileReference: null,
    backCoverLowResFileReference: null,
    contentPageLowResFileReference: null,
    contentPageHighResFileReference: null,
    backCoverHighResFileReference: null,
    frontCoverHighResFileReference: null,

    // OPTIONAL
    productBrandId: null,
  },

  themesToEdit: [],

  posSkeleton: {
    name: '',
    sizeId: '',
    previewUrl: '',
    sides: [],
    disabled: false,
    deleted: false,
    copyPosSideEnabled: false,
    marketId: null,
    brandId: null,
    productBrandId: '',
    marginTop: NaN,
    marginBottom: NaN,
    marginLeft: NaN,
    marginRight: NaN,
    tags: ' ',
  },

  posPayload: {
    id: null,
    name: '',
    previewUrl: '',
    deleted: false,
    disabled: true,
    sizeName: '',
    productName: '',
    productBrandId: '',
    brandName: '',
    marketName: '',
    marketId: null,
    sizeId: null,
    sides: [],
    marginTop: null,
    marginBottom: null,
    marginLeft: null,
    marginRight: null,
    enabledMarketIds: [],
    tags: '',
    copyPosSideEnabled: false,
  },

  posSide1Payload: {
    previewUrl: null,
    printUrl: null,
    sideNumber: 1,
    designId: null,
    textElements: [],
  },

  posSide2Payload: {
    previewUrl: null,
    printUrl: null,
    sideNumber: 2,
    designId: null,
    textElements: [],
  },

  posSide3Payload: {
    previewUrl: null,
    printUrl: null,
    sideNumber: 3,
    designId: null,
    textElements: [],
  },
};
