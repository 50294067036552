import { React, useState, useEffect, useMemo } from 'react';
import {
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  keyframes,
  usePrefersReducedMotion,
  useDisclosure,
  Heading,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import { useAuth0 } from '@auth0/auth0-react';

import { marketService, brandService } from '../services/InitialServices';
import { AUTH0_AUDIENCE } from '../settings';
import UploadType from '../components/UploadType';
import DisplayThemes from './DisplayThemes';
import CreateNewPOSTheme from '../containers/CreateNewPOSTheme';
import CreateNewMenuTheme from '../containers/CreateNewMenuTheme';
import HeaderBar from '../components/header/HeaderBar';
import { PrimaryButton } from '../_librabry/buttons/PrimaryButton';

function ContentUploadContainer() {
  //* STATES =================================================

  // --- Select Menu Theme or POS Design Upload
  const [uploadType, setUploadType] = useState(null);

  // --- Fetches
  const [markets, setMarkets] = useState([]);
  const [brands, setBrands] = useState([]);

  // --- Alerts
  const [toggleAlert, setToggleAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const prefersReducedMotion = usePrefersReducedMotion();

  // --- Rendering Components
  const [showUploadType, setShowUploadType] = useState(false);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [showDisplaythemes, setShowDisplaythemes] = useState(true);

  // --- Existing themes to edit
  const { isOpen, onOpen, onClose } = useDisclosure();

  // * INITIAL FETCHES, useCONTEXT, AUTH0 TOKEN ==========================
  const { getAccessTokenSilently } = useAuth0();

  const token = Promise.resolve(
    useMemo(async () => {
      return await getAccessTokenSilently({ audience: AUTH0_AUDIENCE });
    }, [getAccessTokenSilently])
  );

  useEffect(() => {
    marketService(token).then(res => setMarkets(res));
    brandService(token).then(res => setBrands(res));
  }, [token]);

  const [subCategories, setSubcategories] = useState([]);

  const [requestState, setRequestState] = useState({
    themes: [],
    isLoading: false,
    editThemeModal: false,
    offset: 0,
    filters: {
      id: 0,
      name: '',
      aspectRatio: 0,
    },
    selectedBrand: [],
    selectedMarket: null,
    totalNumberOfThemes: 0,
    timeStamp: null,
  });

  // TODO: Refactor alert message to be its own component
  const handleToggleOnAlert = () => setToggleAlert(true);

  const handleToggleOffAlert = () => {
    setToggleAlert(false);
  };

  const handleUploadAlert = (alertStatus, alertMessage) => {
    setAlertStatus(alertStatus);
    setAlertMessage(alertMessage);
    handleToggleOnAlert();
  };

  const fadeIn = keyframes`
        from { opacity: 0; }
        to { opacity: 1; }
        `;

  const animation = prefersReducedMotion ? undefined : `${fadeIn} 0.1s linear`;

  const alertTheme = {
    w: '25vw',
    h: 'auto',
    borderRadius: '5px',
    color: '#FFFFFF',
    bgGradient:
      alertStatus === 'success'
        ? ['linear(to-r, #4AA543, #56BB4E, #56BB4E)']
        : ['linear(to-r, #BA2121, #C52C2C, #C52C2C, #BA2121)'],
    boxShadow: 'lg',
    textShadow:
      alertStatus === 'success' ? '1px 1px #3E8D37' : '1px 1px #9F3737',
    zIndex: '99',
    ml: '70vw',
    position: 'fixed',
    cursor: 'pointer',
  };

  const displayAlert = () => {
    if (toggleAlert) {
      return (
        <Alert
          status={alertStatus}
          onClick={handleToggleOffAlert}
          w={alertTheme.w}
          h={alertTheme.h}
          borderRadius={alertTheme.borderRadius}
          bgGradient={alertTheme.bgGradient}
          boxShadow={alertTheme.boxShadow}
          zIndex={alertTheme.zIndex}
          ml={alertTheme.ml}
          position={alertTheme.position}
          cursor={alertTheme.cursor}
          animation={animation}
        >
          <AlertIcon color={alertTheme.color} />
          <AlertTitle
            color={alertTheme.color}
            textShadow={alertTheme.textShadow}
          >
            {alertMessage}
          </AlertTitle>
        </Alert>
      );
    }
  };

  // ---------- Hides and shows components

  const hideUploadType = () => {
    setShowUploadType(false);
    setShowFileUpload(true);
  };

  const handleShowDisplayThemes = () => {
    setShowDisplaythemes(false);
    setShowUploadType(true);
  };

  const renderHeading = () => {
    if (showDisplaythemes) {
      return 'Manage Themes';
    }
    if (showUploadType) {
      return 'Design Type';
    }

    if (uploadType === 'pos') {
      return 'Upload POS Design';
    }
    if (uploadType === 'menu') {
      return 'Upload Menu Theme';
    } else return 'Manage Themes';
  };

  return (
    <Flex direction={'column'} h={'100vh'} overflow={'hidden'}>
      <HeaderBar />

      <Flex
        direction="column"
        p={20}
        alignItems="flex-start"
        zIndex="1"
        minH={'calc(100vh - 60px)'}
        bg={'white'}
        overflow={'scroll'}
        gap={10}
      >
        <Flex justify={'space-between'} w="100%" mb={0}>
          <Heading color={'colors.darkestTealGrey'}>{renderHeading()}</Heading>
          {showDisplaythemes && (
            <PrimaryButton
              text={'Add New Theme'}
              onClick={handleShowDisplayThemes}
              icon={AddIcon}
            />
          )}
        </Flex>
        {displayAlert()}

        {showDisplaythemes && (
          <DisplayThemes
            handleShowDisplayThemes={handleShowDisplayThemes}
            markets={markets}
            brands={brands}
            token={token}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            requestState={requestState}
            setRequestState={setRequestState}
          />
        )}
        {showUploadType && (
          <UploadType
            hideUploadType={hideUploadType}
            setUploadType={setUploadType}
            uploadType={uploadType}
          />
        )}
        {uploadType === 'pos' && (
          <CreateNewPOSTheme
            showFileUpload={showFileUpload}
            setShowFileUpload={setShowFileUpload}
            handleUploadAlert={handleUploadAlert}
            handleToggleOffAlert={handleToggleOffAlert}
            token={token}
            markets={markets}
            brands={brands}
          />
        )}
        {uploadType === 'menu' && (
          <CreateNewMenuTheme
            setShowFileUpload={setShowFileUpload}
            showFileUpload={showFileUpload}
            handleUploadAlert={handleUploadAlert}
            handleToggleOffAlert={handleToggleOffAlert}
            token={token}
            markets={markets}
            brands={brands}
            subCategories={subCategories}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            setSubcategories={setSubcategories}
            setRequestState={setRequestState}
          />
        )}
      </Flex>
    </Flex>
  );
}

export default ContentUploadContainer;
