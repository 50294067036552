import { Flex, Text } from '@chakra-ui/react';
import { PosCheckboxes } from './PosCheckboxes';

const PosCheckboxesContainer = ({
  pdfsLength,
  productGroupName,
  handleDisableNextButton,
  finalPayloadSent,
  handlePostSkeleton,
  handleSubmittedDesignsCount,
  clickToSubmit,
  productGroupSizes,
}) => {
  return (
    <Flex
      direction="column"
      mt="1vh"
      ml="0.5vw"
      h={'45vh'}
      justify={'space-between'}
    >
      <Flex p={8} direction="column" gap={4}>
        <Text fontWeight="bold" fontSize={'18px'}>
          {pdfsLength * 2} Artwork Files for {productGroupName}
        </Text>
        <Text mb={4} fontSize={'16px'}>
          {' '}
          {pdfsLength} PDFs + {pdfsLength} JPEGs
        </Text>
        <PosCheckboxes
          handleDisableNextButton={handleDisableNextButton}
          finalPayloadSent={finalPayloadSent}
          handlePostDesign={handlePostSkeleton}
          handleSubmittedDesignsCount={handleSubmittedDesignsCount}
          clickToSubmit={clickToSubmit}
          productGroupSizes={productGroupSizes}
        />
      </Flex>
    </Flex>
  );
};

export default PosCheckboxesContainer;
