// API V1
export const API_HOST =
  process.env.REACT_APP_API_HOST_DEV || window.ENV_CONFIG?.hosts?.api;
export const API_HOST_POS =
  process.env.REACT_APP_API_POS_HOST_DEV || window.ENV_CONFIG?.hosts?.pos_api;

//  API V3
export const API_HOST_V3 =
  process.env.REACT_APP_API_HOST_DEV_V3 || window.ENV_CONFIG?.hosts?.api_v3;

// Menu themes endpoints
export const API_HOST_MARKETS = `${API_HOST}/markets`;
export const API_HOST_MARKETS_V3 = `${API_HOST_V3}/market`;
export const API_HOST_THEMES_MARKET = `${API_HOST_V3}/ThemesForContentUpload`;
export const API_HOST_THEME_STYLES = `${API_HOST_V3}/GetThemeStyles`;
export const API_HOST_BRANDS = `${API_HOST}/brands`;
export const API_HOST_MENU_OPTIONS = `${API_HOST}/menuoptions`;
export const API_HOST_PRODUCT_BRANDS = `${API_HOST}/productbrands`;
export const API_HOST_THEMES = `${API_HOST}/themes`;
export const API_HOST_THEMES_UPLOAD = `${API_HOST_THEMES}/upload`;

// POS endpoints
export const API_HOST_POS_PRODUCTS = `${API_HOST_POS}/ecommerce/products`;
export const API_HOST_POS_SIZES = `${API_HOST_POS}/ecommerce/sizes`;
export const API_HOST_POS_DESIGNS = `${API_HOST_POS}/ecommerce/designs`;
export const API_HOST_POS_IMAGES = `${API_HOST_POS}/images`;

// External links
export const PDS_SITE_LINK = API_HOST?.includes('mrm-orders-service-dev')
  ? 'https://dev.menumaker.co/#/Admin'
  : 'https://www.menumaker.co/#/Admin';
export const ADMIN_SITE_LINK = API_HOST_POS?.includes('dev')
  ? 'https://admin-dev.mrmbrand-apis.net'
  : 'https://admin.mrmbrand-apis.net';

export const MRM_TOOLKIT_URL = API_HOST_POS?.includes('dev')
  ? 'https://toolkit-dev.hyble.app'
  : 'https://toolkit.hyble.app';

// Auth0
export const AUTH0_AUDIENCE =
  process.env.REACT_APP_AUTH0_AUDIENCE_DEV ||
  window.ENV_CONFIG?.auth0?.audience;
export const AUTH0_DOMAIN =
  process.env.REACT_APP_AUTH0_DOMAIN_DEV || window.ENV_CONFIG?.auth0?.domain;
export const AUTH0_CLIENT_ID =
  process.env.REACT_APP_AUTH0_CLIENT_ID_DEV ||
  window.ENV_CONFIG?.auth0?.client_id;
