let fileNames = [];
let themeName = '';

// detects the theme name from the file name 
export const detectThemeName = (filesContent) => {
    const regexTheme = /^[aA-zZ: 0-9:_ &]*/g;
    
    filesContent.forEach(file => {
        fileNames.push(file.name);
        const fileNamesAsString = fileNames.toString();
        themeName = fileNamesAsString.match(regexTheme);
      });

      return themeName.toString()
}