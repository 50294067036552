const PDFJS = require('pdfjs-dist/webpack');

export async function convertPdfToImages(objectArray) {
  const imageObjects = [];
  const imageBlobs = [];

  // Calculates approx PDF size based on string length. Base64 encodes 3 bytes of binary data on 4 characters.
  const calculatePdfSizeMb = object => {
    let size = (Math.ceil(object.content.length / 4) * 3) / 1000000;
    return size;
  };

  //   Progressively reduces encoder output value so Jpeg size doesn't exceed 1mb.
  const setEncoderQualityValue = size => {
    let value = 1;

    if (size <= 3) {
      value = 1;
    } else if (size <= 50) {
      value = 0.95;
    } else if (size <= 100) {
      value = 0.9;
    } else if (size <= 200) {
      value = 0.8;
    } else if (size <= 300) {
      value = 0.7;
    } else if (size <= 500) {
      value = 0.6;
    }
    return value;
  };

  const getViewPortScale = page => {
    let defaultScale = 4;
    while (
      (defaultScale > 1 &&
        page.getViewport({ scale: defaultScale }).height >= 1500) ||
      page.getViewport({ scale: defaultScale }).width >= 1500
    ) {
      let newScale = (defaultScale -= 0.1);
      defaultScale = parseFloat(newScale.toFixed(2));
    }
    return page.getViewport({ scale: defaultScale });
  };

  if (objectArray) {
    for (let object of objectArray) {
      let encoderValue = setEncoderQualityValue(calculatePdfSizeMb(object));

      const pdf = await PDFJS.getDocument(object.content).promise;
      const canvas = document.createElement('canvas');

      for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const viewport = await getViewPortScale(page);
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render({ canvasContext: context, viewport: viewport })
          .promise;
        imageObjects.push({
          content: canvas.toDataURL('image/jpeg', encoderValue),
          lastModified: object.lastModified,
          name: object.name.replace('.pdf', '.jpg'),
        });
      }
      canvas.remove();
    }
  }

  function sorter(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  return imageObjects.sort(sorter);
}

export async function convertThumbnailPdfToImage(objectArray) {
  const imageObjects = [];

  // Calculates approx PDF size based on string length. Base64 encodes 3 bytes of binary data on 4 characters.
  const calculatePdfSizeMb = object => {
    let size = (Math.ceil(object.content.length / 4) * 3) / 1000000;
    return size;
  };

  //   Progressively reduces encoder output value so Jpeg size doesn't exceed 1mb.
  const setEncoderQualityValueThumbnail = size => {
    let value = 1;

    if (size >= 20) {
      value = 0.99;
    }
    if (size >= 50) {
      value = 0.8;
    }
    if (size >= 100) {
      value = 0.6;
    }
    return value;
  };

  const getThumbnailViewPortScale = page => {
    let defaultScale = 2;
    while (
      defaultScale > 0.1 &&
      page.getViewport({ scale: defaultScale }).height >= 200
    ) {
      let newScale = (defaultScale -= 0.1);
      defaultScale = parseFloat(newScale.toFixed(2));
    }
    return page.getViewport({ scale: defaultScale });
  };

  if (objectArray) {
    for (let object of objectArray) {
      let encoderValue = setEncoderQualityValueThumbnail(
        calculatePdfSizeMb(object)
      );

      const pdf = await PDFJS.getDocument(object.content).promise;
      const canvas = document.createElement('canvas');

      for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const viewport = await getThumbnailViewPortScale(page);
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render({ canvasContext: context, viewport: viewport })
          .promise;
        imageObjects.push({
          content: canvas.toDataURL('image/jpeg', encoderValue),
          lastModified: object.lastModified,
          name: object.name.replace('.pdf', '.jpg'),
        });
      }
      canvas.remove();
    }
  }

  return imageObjects;
}
